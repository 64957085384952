import { useEffect, useState } from 'react';
import {
  DKIcon,
  DKIcons,
  DKButton,
  DKLabel,
  BarGraph,
  COLORS,
  DKSpinner
} from 'deskera-ui-library';
import DKDropdown from './DKDropdown';
import { useAppDispatch, useAppSelector } from '../../Redux/Hooks';
import {
  setPnlFilterConfig,
  selectPnlFilterConfig
} from '../../Redux/Slices/DashboardSlice';
import Utility from '../../Utility/Utility';
import { LongMonth } from '../../SharedComponents/Calender/CalendarConstants';
import { DATE_FORMAT, ERROR } from '../../Constants/Constant';
import { useTranslation } from 'react-i18next';
import DashboardService from '../../Services/Dashboard';
import { GranularPermissionsHelper } from '../Settings/GranularPermissions/GranularPermissionsHelper';
import { PERMISSIONS_BY_MODULE } from '../../Constants/Permission';
// import DKDropdown from './DKDropdown';

function ProfitAndLoss(props: any) {
  const [pnlData, setPnlData] = useState<any>();
  const pnlFilter = useAppSelector(selectPnlFilterConfig);
  const [profitAndLossChartData, setProfitAndLossChartData] = useState(
    pnlData || [{ title: 'Jan', value: 10, color: 'rgb(28, 115, 232)' }]
  );
  const [noData, setNoData] = useState(true);
  const [noDataFound, setNoDatFound] = useState(false);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const filters = [
    {
      name: t('DASHBOARD.CHART.BUY_SELL_FILTER_OPTIONS.CURRENT_YEAR'),
      value: 'CURRENT_YEAR'
    },
    {
      name: t('DASHBOARD.CHART.CASH_FLOW_PNL_FILTER_OPTIONS.LAST_SIX_MONTHS'),
      value: 'LAST_SIX_MONTH'
    },
    {
      name: t(
        'DASHBOARD.CHART.CASH_FLOW_PNL_FILTER_OPTIONS.LAST_TWELVE_MONTHS'
      ),
      value: 'LAST_TWELVE_MONTH'
    },
    {
      name: t('DASHBOARD.CHART.BUY_SELL_FILTER_OPTIONS.LAST_YEAR'),
      value: 'LAST_YEAR'
    }
  ];
  const existingIndex = filters.findIndex(
    (filter: any) => filter.value === pnlFilter?.config?.buySellFilterOption
  );

  const [selectedFilterIndex, setSelectedFilterIndex]: any = useState(
    (Utility.isEmpty(pnlFilter?.config) ? 0 : existingIndex) || 0
  );

  const profitAndLossReportParser = (data: any) => {
    let parsedData = data.map((data: any) => {
      var date = new Date(data.monthStartDate);
      return {
        title: LongMonth[date.getMonth()]
          ? LongMonth[date.getMonth()].substring(0, 3)
          : '',
        value: data.accountPNLInfo.netProfitOrLoss,

        color: COLORS.chart.blue
      };
    });
    console.log(parsedData, 'pnl Data');
    if (Utility.isEmpty(parsedData)) {
      setNoData(true);
    } else {
      setNoData(false);
    }
    return parsedData;
  };
  useEffect(() => {
    if (
      pnlData === ERROR ||
      !GranularPermissionsHelper.hasPermissionFor(
        PERMISSIONS_BY_MODULE.REPORTS.VIEW
      )
    ) {
      setNoData(false);
      setNoDatFound(true);
      setProfitAndLossChartData([
        { title: 'Jan', value: 10, color: 'rgb(28, 115, 232)' }
      ]);
      return;
    }

    if (!Utility.isEmpty(pnlData)) {
      const parsedData = profitAndLossReportParser(pnlData);
      localStorage.setItem('pnlData', JSON.stringify(parsedData));
      setProfitAndLossChartData(parsedData);
    }
  }, [pnlData]);

  useEffect(() => {
    const storedData = localStorage.getItem('pnlData');
    if (storedData) {
      const parsedData = JSON.parse(storedData);
      setNoData(false);
      setProfitAndLossChartData(parsedData);
    } else {
      getPnl();
    }
  }, []);

  const getPnl = () => {
    DashboardService.getPNL()
      .then((res: any) => {
        if (!Utility.isEmpty(res)) {
          setPnlData(res);
        }
      })
      .catch((err: any) => {
        console.error('Error loading pnl charts: ', err);
      });
  };

  const getPnlData = (filterOption: any) => {
    var year = new Date().getFullYear();
    var fromDate = new Date(year, 0, 1);
    var toDate = new Date(year, 11, 31);
    if (!Utility.isEmpty(filterOption)) {
      switch (filterOption.value) {
        case 'CURRENT_YEAR':
          year = new Date().getFullYear();
          fromDate = new Date(year, 0, 1);
          toDate = new Date(year, 11, 31);
          break;
        case 'LAST_SIX_MONTH':
          toDate = new Date();
          var d = new Date(toDate);
          d.setMonth(d.getMonth() - 6);
          fromDate = d;
          break;
        case 'LAST_TWELVE_MONTH':
          toDate = new Date();
          d = new Date(toDate);
          d.setMonth(d.getMonth() - 12);
          fromDate = d;
          break;
        case 'LAST_YEAR':
          year = new Date().getFullYear() - 1;
          fromDate = new Date(year, 0, 1);
          toDate = new Date(year, 11, 31);
          break;

        default:
          break;
      }
    }

    try {
      var config = {
        toDate: Utility.formatDate(toDate, DATE_FORMAT.DD_MM_YYYY),
        fromDate: Utility.formatDate(fromDate, DATE_FORMAT.DD_MM_YYYY),
        buySellFilterOption: filterOption?.value
      };
      DashboardService.apiConfig = config;
      dispatch(setPnlFilterConfig(config));
      getPnl();
    } catch (err) {
      console.error('Error loading products: ', err);
    }
  };

  useEffect(() => {
    if (
      GranularPermissionsHelper.hasPermissionFor(
        PERMISSIONS_BY_MODULE.REPORTS.VIEW
      )
    ) {
      if (
        Utility.isEmpty(pnlFilter?.config) ||
        pnlFilter?.config?.buySellFilterOption !==
          filters[selectedFilterIndex]?.value
      ) {
        getPnlData(filters[selectedFilterIndex]);
      }
    }
  }, [selectedFilterIndex]);

  return (
    <div
      className="border-radius-m shadow-s border-ms p-xl bg-white border-box mr-l db-grid-width-50 db-grid-min-width db-mb"
      style={{ height: 265 }}
    >
      <div className="row justify-content-between">
        <DKLabel text={'Profit & Loss'} className="fw-m fs-m" />
        <div style={{ display: 'flex', alignContent: 'center' }}>
          <DKButton
            title=""
            style={{ position: 'relative', zIndex: 1 }}
            icon={DKIcons.ic_repeat}
            onClick={() => {
              getPnlData(filters[selectedFilterIndex]);
            }}
          />
          <DKDropdown
            title={filters[selectedFilterIndex].name}
            data={filters.map((item: any) => {
              return item.name;
            })}
            onSelect={(data: any) => {
              setSelectedFilterIndex(data);
              props.updateFilter(filters[data].value);
            }}
          />
        </div>
      </div>
      <div
        className="column position-relative parent-width"
        style={{ height: 210 }}
      >
        {noData && (
          <div
            className="column position-absolute justify-content-center align-items-center parent-size"
            style={{
              left: 0,
              top: 0,
              zIndex: 1,
              backgroundColor: 'rgba(255, 255, 255, 0.9)'
            }}
          >
            <div className="bg-white rounded px-2 py-2 shadow">
              <DKSpinner iconClassName="ic-s" />
            </div>
          </div>
        )}
        {noDataFound && (
          <div
            className="column position-absolute justify-content-center align-items-center parent-size"
            style={{
              left: 0,
              top: 0,
              zIndex: 1,
              backgroundColor: 'rgba(255, 255, 255, 0.9)'
            }}
          >
            <div className="bg-white rounded px-2 py-2 shadow">No Data</div>
          </div>
        )}
        <div className="row justify-content-between mt-l p-h-s position-relative">
          {profitAndLossChartData !== ERROR &&
            profitAndLossChartData &&
            profitAndLossChartData.length > 0 && (
              <BarGraph
                data={profitAndLossChartData}
                height={190}
                tooltipValueFormatter={(value: any) => {
                  return (
                    Utility.getTenantCurrencySymbol() +
                    Utility.shortFormatNumberWithSuffix(value)
                  );
                }}
                displayValueFormatter={(value: any) => {
                  return (
                    Utility.getTenantCurrencySymbol() +
                    Utility.shortFormatNumberWithSuffix(value)
                  );
                }}
              />
            )}
        </div>
      </div>
    </div>
  );
}

export default ProfitAndLoss;
