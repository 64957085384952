import { showAlert } from 'deskera-ui-library';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import {
  APPROVAL_STATUS,
  BOOKS_DATE_FORMAT,
  BUY_TYPE,
  DOCUMENT_MODE,
  DOC_PATH_WITH_ID_REGEX,
  DOC_TYPE,
  LABELS,
  PAYMENT_STATUS,
  POPUP_CALLBACKS_TYPE,
  RECORD_SAVED_EVENT_DOC_TYPE,
  TAX_SYSTEM
} from '../../Constants/Constant';
import { COMPLIANCE_SPECIFIC_FIELD_NAME } from '../../Constants/Enum';
import { PERMISSIONS_BY_MODULE } from '../../Constants/Permission';
import RouteManager, { PAGE_ROUTES } from '../../Managers/RouteManager';
import { Bill, BillProps, billInitialState } from '../../Models/Bill';
import { Document } from '../../Models/Document';
import { DraftTypes } from '../../Models/Drafts';
import { useAppDispatch, useAppSelector } from '../../Redux/Hooks';
import { activeTenantInfo } from '../../Redux/Slices/AuthSlice';
import { fetchapprovalConditionList } from '../../Redux/Slices/AutomationSlice';
import { fetchBills } from '../../Redux/Slices/BillsSlice';
import { documentUpdated } from '../../Redux/Slices/CommonDataSlice';
import {
  deleteDrafts,
  draftTableId,
  draftTypeColumnId,
  fetchDrafts,
  isSaveColumnId,
  removeDraft,
  setDraftActionAvailibility,
  setDraftValidationDisplayStatus,
  updatePopulateFormData
} from '../../Redux/Slices/DraftsSlice';
import { fetchOrders } from '../../Redux/Slices/PurchaseOrdersSlice';
import { fetchWorkOuts } from '../../Redux/Slices/WorkOutSlice';
import AuthService from '../../Services/Auth';
import BillService from '../../Services/Bill';
import DateFormatService from '../../Services/DateFormat';
import { ADDITIONAL_CHARGE_METHODS } from '../../SharedComponents/AdditionalCharges/AdditionalCharges';
import NewDocument2 from '../../SharedComponents/DocumentForm/NewDocument2';
import {
  cascadingDiscountsInvalidMessage,
  checkGSTINPresentForSelectedContact,
  convertExpectedDeliveryDateInString,
  checkIfTotalDiscountInvalid,
  customFieldsContainsErrors,
  getAccountsAndCFForBudgetValidation,
  getDocumentAlert,
  getTenantTaxSystem,
  inactiveContactMessage,
  isDocContactInactive,
  rebuildCascadingDiscountsForSaving,
  removeUnwantedPayloadKeysForDocument,
  showAlertOnDocAPIError,
  updateAddressAsPerLocationCF,
  validateBudgetForAccounts
} from '../../SharedComponents/DocumentForm/NewDocumentHelper';
import Utility, { deepClone } from '../../Utility/Utility';
import { setItemsFromPurchaseInvoiceItems } from './BillHelper';
import {
  COMMON_EVENTS,
  commonCustomEvent
} from '../../Services/event/commonEvents';
import {
  getBuyDashboard,
  selectBuyFilterConfig
} from '../../Redux/Slices/DashboardSlice';
import DashboardService from '../../Services/Dashboard';

const NewBill: React.FC<BillProps> = (props) => {
  const [isCenterAlign, setIsCenterAlign] = useState<boolean>(
    props.isCenterAlign
  );
  const [bill, setBill] = useState<Bill>(
    !Utility.isEmpty(props.populateFormData)
      ? setItemsFromPurchaseInvoiceItems(props.populateFormData)
      : billInitialState
  );
  const [updatedBill, setUpdatedBill] = useState<Bill>({ ...bill });
  const draftsTableId = useAppSelector(draftTableId);
  const isSavedColumnId = useAppSelector(isSaveColumnId);
  const draftTypeColId = useAppSelector(draftTypeColumnId);
  const tenantInfo = useAppSelector(activeTenantInfo);
  const SellDashboardConfig = useAppSelector(selectBuyFilterConfig);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const skipTDS = useRef<boolean>(false);
  const [landedCost, setLandedCost] = useState<boolean>(
    props.draftData?.data?.landedCost
  );
  const [landedCostProducts, setLandedCostProducts] = useState<any>(
    props.draftData?.data?.landedProducts
  );
  const [zeroAmountDocument, setZeroAmountDocument] = useState(
    !Utility.isEmpty(props.populateFormData)
      ? setItemsFromPurchaseInvoiceItems(props.populateFormData).totalAmount ===
          0
      : false
  );
  let history = useHistory();

  const registerInteractions = () => {
    /*
     * register parents calls to child methods
     */

    if (props.passingInteraction)
      props.passingInteraction({
        type: POPUP_CALLBACKS_TYPE.SAVE_AS_DRAFT,
        data: onSaveAsDraft
      });

    if (props.passingInteraction)
      props.passingInteraction({
        type: POPUP_CALLBACKS_TYPE.CLOSE_DRAFT_POPUP,
        data: () => {
          closeDraftPopup();
        }
      });

    if (props.passingInteraction) {
      props.passingInteraction({
        type: POPUP_CALLBACKS_TYPE.UPDATE_BILL,
        data: () => {
          updateBill();
        }
      });
    }

    if (props.passingInteraction) {
      props.passingInteraction({
        type: POPUP_CALLBACKS_TYPE.CREATE_BILL,
        data: (closeDoc: boolean) => {
          createBill(closeDoc);
        }
      });
    }
  };

  useEffect(() => {
    setIsCenterAlign(props.isCenterAlign);

    // setInvoice(setItemsFromSalesInvoiceItems(invoice));
    loadApprovalConditionList();
    if (landedCost) {
      setBill({
        ...bill,
        landedCost: landedCost,
        landedProducts: landedCostProducts
      });
    }
    return () => {
      console.log('cleanup..');
    };
  }, []);

  useEffect(() => {
    setIsCenterAlign(props.isCenterAlign);
  }, [props]);

  useEffect(() => {
    registerInteractions();
  });

  useEffect(() => {
    if (!Utility.isEmpty(props.populateFormData)) {
      setBill({ ...props.populateFormData });
    }
  }, [props.populateFormData]);

  const loadApprovalConditionList = () => {
    dispatch(fetchapprovalConditionList());
  };

  const onSaveAsDraft = () => {
    let payload = { ...updatedBill };

    payload['approvalStatus'] = payload['approvalStatus']
      ? payload['approvalStatus']
      : APPROVAL_STATUS['NOT_REQUIRED'];
    payload['createdUserName'] = AuthService.getUserName();
    payload['dueAmount'] = 0;
    let parsedItems = payload?.items;
    if (Utility.isMRPWithURLCheck()) {
      parsedItems = parsedItems?.map((billItem: any) => {
        return {
          ...billItem,
          expectedDeliveryDt: DateFormatService.getDateStrFromDate(
            billItem.expectedDeliveryDt,
            BOOKS_DATE_FORMAT['DD-MM-YYYY']
          )
        };
      });
    }
    payload.items = parsedItems;
    payload.purchaseInvoiceProducts = parsedItems;
    setButtonStatus(true);

    // here we explicitly remove few keys, which is not needed to be saved in component list feature
    payload = removeUnwantedPayloadKeysForDocument(payload);

    props.formData(payload);
    dispatch(removeDraft(props.draftData.id));
    replaceURLToModuleURL();
  };

  // Change URL to base module URL
  const replaceURLToModuleURL = () => {
    if (
      DOC_PATH_WITH_ID_REGEX.test(history.location?.pathname) &&
      history.location?.pathname?.includes(PAGE_ROUTES.BILLS)
    ) {
      history.replace(PAGE_ROUTES.BILLS);
    }
  };

  const closeDraftPopup = () => {
    dispatch(removeDraft(props.draftData.id));
    replaceURLToModuleURL();
  };

  const isDocValid = (docToValidate: any, closeDoc: boolean) => {
    const taxSystem = getTenantTaxSystem();

    // Validate Manual Document Sequence Code
    if (
      Utility.isEmpty(docToValidate.documentSequenceCode) &&
      Utility.isEmpty(docToValidate.sequenceFormat) &&
      docToValidate.manualMode
    ) {
      return false;
    }

    if (Utility.isEmpty(docToValidate.contact)) {
      return false;
    }

    if (Utility.isEmpty(docToValidate.purchaseInvoiceProducts)) {
      return false;
    }

    if (!Utility.isEmpty(docToValidate.documentDate)) {
      const docDate = DateFormatService.getDateFromStr(
        docToValidate.documentDate,
        BOOKS_DATE_FORMAT['DD-MM-YYYY']
      );
      if (
        !Utility.checkActiveDateRangeValidation(
          docDate,
          tenantInfo,
          'Bill date',
          docToValidate.documentType
        )
      ) {
        return false;
      }
      if (!Utility.checkClosingDate(docDate, 'Bill date')) {
        return false;
      }
    }

    // Custom fields validation
    const customFieldHasErrors = customFieldsContainsErrors(
      docToValidate.customField
    );
    if (customFieldHasErrors) {
      return false;
    }
    // Custom fields validation ends

    let lineItemsHasErrors = false;
    for (let i = 0; i < docToValidate.purchaseInvoiceProducts.length; i++) {
      const item = docToValidate.purchaseInvoiceProducts[i];
      if (item.hasError || item.invalidFields?.length) {
        lineItemsHasErrors = true;
        break;
      }
    }

    if (lineItemsHasErrors) {
      return false;
    }
    // Line item errors ends

    if (
      taxSystem === TAX_SYSTEM.INDIA_GST &&
      (!docToValidate.shipTo?.state || !docToValidate.shipFrom?.state)
    ) {
      return false;
    }

    // Contact GSTIN check
    if (!checkGSTINPresentForSelectedContact(docToValidate)) {
      return false;
    }
    // Contact GSTIN check ends

    // Check for -ve total amount
    const totalBeforeTax = docToValidate?.purchaseInvoiceProducts?.length
      ? docToValidate?.purchaseInvoiceProducts?.reduce(
          (total: number, item: any) => {
            return total + item?.totalWithDiscount;
          },
          0
        )
      : 0;
    if (totalBeforeTax < 0) {
      showAlert('Invalid amount!', 'Bill amount can not be less than 0.');
      return false;
    }
    // Check for -ve total amount ends

    // Cascading discounts validation
    const cascadingDiscountSettings =
      tenantInfo.additionalSettings?.CASCADING_DISCOUNTS;
    if (cascadingDiscountSettings?.enable) {
      const isTotalDiscountInvalid = checkIfTotalDiscountInvalid(
        docToValidate,
        'purchaseInvoiceProducts'
      );
      if (isTotalDiscountInvalid) {
        showAlert('Error!', cascadingDiscountsInvalidMessage);
        return false;
      }
    }
    // Cascading discounts validation ends

    // Additional charges check
    let additionalChargesHasErrors = false;
    const additionalChargesDetails =
      docToValidate.additionalCharges?.additionalChargesDetails;
    if (!Utility.isEmpty(additionalChargesDetails)) {
      for (let i = 0; i < additionalChargesDetails.length; i++) {
        const item = additionalChargesDetails[i];
        if (item.hasError) {
          additionalChargesHasErrors = true;
          break;
        }
      }
    }
    if (additionalChargesHasErrors) {
      return false;
    }

    let manualApportionCharges = additionalChargesDetails.filter(
      (item: any) =>
        item.apportionValue &&
        item.apportionValue === ADDITIONAL_CHARGE_METHODS.APPORTION_MANUAL
    );
    let manualApportionError = false;
    for (let charge of manualApportionCharges) {
      let manualApportionChargesInItems: any[] = [];

      docToValidate.purchaseInvoiceProducts.forEach((item: any) => {
        const additionalCharge =
          item.additionalCharges.additionalChargesDetails.find(
            (c: any) => c.additionalCharge === charge.additionalCharge
          );
        if (!Utility.isEmpty(additionalCharge)) {
          manualApportionChargesInItems.push(additionalCharge);
        }
      });
      if (manualApportionChargesInItems.length) {
        const manualApportionChargesTotal =
          manualApportionChargesInItems.reduce(
            (total: number, detail: any) =>
              total + Number(detail?.chargeAmount || 0),
            0
          );
        if (manualApportionChargesTotal !== +charge.chargeAmount) {
          manualApportionError = true;
          break;
        }
      }
    }
    if (manualApportionError) {
      showAlert(
        'Oops!',
        `One or more line items contains incorrect allocation for additional charges`
      );
      return false;
    }

    // Additional charges check ends

    // Discount check
    const discountHasErrors =
      docToValidate.additionalCharges?.globalDiscount?.hasError;
    if (discountHasErrors) {
      return false;
    }
    // Discount check ends

    // Check tds not deducted
    if (taxSystem === TAX_SYSTEM.INDIA_GST && !skipTDS.current) {
      let tdsNotDeductedArray: any[] = [];
      docToValidate.purchaseInvoiceProducts.forEach((ele: any) => {
        if (!ele.tdsInfoIndia && ele.product.tdsApplicableIndia) {
          tdsNotDeductedArray.push(ele.product.name);
        }
      });
      if (
        tdsNotDeductedArray.length > 0 &&
        (docToValidate.contact.tdsApplicableIndia ||
          (docToValidate.contact.tdsInfoIndia &&
            docToValidate.contact.tdsInfoIndia.deducteeType))
      ) {
        openTDSDialog(
          tdsNotDeductedArray
            .map((str: string) => {
              return `<li>${str}</li>`;
            })
            .join(''),
          closeDoc
        );
        return false;
      }
    }
    // Check tds not deducted ends

    // Check if Landed cost true but not allocated
    if (docToValidate.landedCost === true) {
      let productNames: string[] = [];
      docToValidate?.purchaseInvoiceProducts?.forEach((item: any) => {
        if (Utility.isEmpty(item.landedCostDetails)) {
          productNames.push(item.productName);
        }
      });
      if (productNames.length !== 0) {
        let productNamesStr = productNames
          .map((name) => `<li>${name}</li>`)
          .join('');
        showAlert(
          '',
          `<div>Landed cost details are not saved yet for below products. Please check context menu for details.<div><br/><ul>${productNamesStr}</ul>`
        );
        return false;
      }
    }

    const filterAmortizationTemplateWithEmptyDates =
      docToValidate?.purchaseInvoiceProducts?.filter(
        (item: any) =>
          !Utility.isEmpty(item?.amortizationTemplate) &&
          (Utility.isEmpty(item?.amortizationStartDate) ||
            Utility.isEmpty(item?.amortizationEndDate))
      );
    if (filterAmortizationTemplateWithEmptyDates?.length > 0) {
      showAlert('Alert', 'Amortization Start or End  Date should not be empty');
      return false;
    }
    return true;
  };

  const openTDSDialog = (productNames = '', closeDoc: boolean) => {
    let buttons = [
      {
        title: 'Cancel',
        className: 'bg-gray2 border-m ',
        onClick: () => {
          skipTDS.current = false;
        }
      },
      {
        title: t(`CONFIRMATION_POPUP.YES`),
        className: 'bg-button text-white ml-r',
        onClick: () => {
          skipTDS.current = true;
          if (props.documentMode === DOCUMENT_MODE.EDIT) {
            updateBill(closeDoc);
          }
          if (
            props.documentMode === DOCUMENT_MODE.NEW ||
            props.documentMode === DOCUMENT_MODE.COPY
          ) {
            createBill(closeDoc);
          }
        }
      }
    ];
    showAlert(
      '',
      `<ul style="list-style: disc; margin-left: 14px;">${productNames}</ul><div class="mt-r">TDS is applicable on the above items. Do you wish to skip deducting TDS?</div>`,
      buttons
    );
  };

  const setButtonStatus = (status: boolean) => {
    dispatch(
      setDraftActionAvailibility({ id: props.draftData.id, status: status })
    );
  };

  const setValidationDisplayStatus = (status: boolean) => {
    dispatch(
      setDraftValidationDisplayStatus({
        id: props.draftData.id,
        status: status
      })
    );
  };

  const updateForm = (
    docResp: any,
    payload: any,
    isUpdate = false,
    closeOnUpdate = true
  ) => {
    BillService.getBillDetailsByCode(docResp.purchaseInvoiceCode).then(
      (doc: any) => {
        const formData = {
          ...doc,
          documentType: DOC_TYPE.BILL,
          items: [...doc.purchaseInvoiceProducts],
          documentDate: payload.documentDate,
          fulfillmentDate: payload.fulfillmentDate,
          validTillDate: payload.validTillDate
        };
        if (!closeOnUpdate) {
          dispatch(
            updatePopulateFormData({
              id: props.draftData?.id,
              formdata: formData,
              draftType: DraftTypes.UPDATE,
              actionFromDocument: true
            })
          );
        } else {
          dispatch(removeDraft(props.draftData.id));
          dispatch(fetchWorkOuts());
          if (!isUpdate) {
            if (payload.showBillCustomAlert) {
              showAlert('Bill created!', 'Bill has been created successfully.');
              // dispatch(updateRefreshCallback(new Date()));
              return;
            }
            getDocumentAlert(
              'Bill created!',
              'Bill has been created successfully.',
              formData,
              props.draftData,
              DOCUMENT_MODE.EDIT,
              PAGE_ROUTES.BILLS
            );
            setButtonStatus(false);
          }
        }
      },
      (err) => {
        console.error('Error loading updated doc: ', err);
        setButtonStatus(false);
      }
    );
  };

  // Create Bill
  const createBill = async (closeDoc: boolean) => {
    setButtonStatus(true);
    setValidationDisplayStatus(true);

    let payload: any = deepClone(updatedBill);
    payload = updateAddressAsPerLocationCF(payload);
    if (tenantInfo.additionalSettings?.CASCADING_DISCOUNTS?.enable) {
      payload = {
        ...payload,
        purchaseInvoiceProducts: rebuildCascadingDiscountsForSaving(payload)
      };
    }
    if (
      Utility.isMRP() &&
      (payload.documentType === DOC_TYPE.BILL ||
        payload.documentType === DOC_TYPE.ORDER)
    ) {
      payload = {
        ...payload,
        purchaseInvoiceProducts: convertExpectedDeliveryDateInString(payload)
      };
    }
    delete payload?.items;
    if (
      (props.documentMode === DOCUMENT_MODE.COPY ||
        props.documentMode === DOCUMENT_MODE.NEW) &&
      (payload?.duplicate || payload?.isConverting)
    ) {
      const isContactInactive = isDocContactInactive(payload?.contact);
      if (isContactInactive) {
        showAlert('Error', inactiveContactMessage);
        setButtonStatus(false);
        return;
      }
    }

    /**
     * @todo
     * BOOK-8234 | Need to handle similar flows/conditions from BE
     * i.e. while receiving & converting a doc at the same time,
     * need to set receiptDate properly..
     */
    if (payload.documentType === DOC_TYPE.BILL && payload.receivedComplete) {
      payload.receiptDate = payload.receiveByDate;
    }

    if (!isDocValid(payload, closeDoc)) {
      setButtonStatus(false);
      return;
    }

    payload['approvalStatus'] = APPROVAL_STATUS['NOT_REQUIRED'];

    let linkedWorkorder = payload.linkedDocuments?.find(
      (ele: any) => ele.documentType === 'WORK_ORDER'
    );
    if (!Utility.isEmpty(linkedWorkorder?.lineItemDetails)) {
      let linkedWOIndex = payload.linkedDocuments?.findIndex(
        (ele: any) => ele.documentType === 'WORK_ORDER'
      );
      let linkedWoArray = Object.keys(linkedWorkorder?.lineItemDetails)?.map(
        (key) => {
          return linkedWorkorder?.lineItemDetails[key];
        }
      );
      let linkedWoItems: any = {};
      payload.purchaseInvoiceProducts.forEach((item: any, index: any) =>
        linkedWoArray.forEach((ele: any) => {
          if (item.productCode === ele.productCode) {
            linkedWoItems[index + 1] = { ...ele };
          }
        })
      );
      payload['linkedDocuments'][linkedWOIndex]['lineItemDetails'] =
        linkedWoItems;
    }

    if (props.draftData?.draftType === DraftTypes.DRAFT) {
      payload[
        'draftReferenceId'
      ] = `${props.draftData?.data?.tableId}/record/${props.draftData?.data?.id}`;
    }

    if (bill.landedCost) {
      let showMsg = payload?.purchaseInvoiceProducts?.every((item: any) => {
        return !Utility.isEmpty(item.landedCostDetails);
      });
      if (!showMsg) {
        setButtonStatus(false);
        showAlert(
          '',
          'Landed cost details are not saved yet. Please check context menu for details.'
        );
        return;
      } else {
        validateAccountsBudget(payload, false, closeDoc);
      }
    } else {
      validateAccountsBudget(payload, false, closeDoc);
    }
  };

  const makeCreateAPICall = (payload: any, closeDoc: any) => {
    BillService.createBill(
      payload,
      Utility.getTenantSpecificApiCode(COMPLIANCE_SPECIFIC_FIELD_NAME.BILL)
    ).then(
      (response: any) => {
        if (props.draftData) {
          if (response.draft) {
            // Remove draft popup
            dispatch(removeDraft(props.draftData.id));
            setButtonStatus(false);

            const buttons = [
              {
                title: 'Ok',
                className: 'bg-button, border-m',
                onClick: () => {}
              },
              {
                title: 'Goto Bills',
                className: ' bg-blue text-white ml-r',
                onClick: () => {
                  RouteManager.navigateToPage(PAGE_ROUTES.BILLS);
                }
              }
            ];
            showAlert(
              'Bill sent for approval!',
              'Document has been created successfully.',
              buttons
            );
          } else {
            updateForm(response, payload, false);
          }
        } else {
          setButtonStatus(false);
        }
        if (
          payload &&
          payload.linkedDocuments &&
          payload.linkedDocuments.length > 0
        ) {
          let linkData = payload.linkedDocuments.filter(
            (doc: any) => doc.documentType === DOC_TYPE.JOB_WORK_OUT_ORDER
          );
          if (linkData && linkData.length <= 0) {
            dispatch(
              fetchDrafts({
                tableId: draftsTableId,
                isSaveColumnId: isSavedColumnId,
                draftTypeColId: draftTypeColId,
                draftTypeColValue: LABELS.BILLS
              })
            );
          }
        } else {
          dispatch(
            fetchDrafts({
              tableId: draftsTableId,
              isSaveColumnId: isSavedColumnId,
              draftTypeColId: draftTypeColId,
              draftTypeColValue: LABELS.BILLS
            })
          );
        }

        commonCustomEvent.dispatch(COMMON_EVENTS.RECORD_SAVED, {
          id: null,
          type: RECORD_SAVED_EVENT_DOC_TYPE.CREATE_BILL,
          linkedDocId: response?.linkedDocuments?.[0]?.documentCode,
          linkedDocType: RECORD_SAVED_EVENT_DOC_TYPE.JOB_WORK_OUT,
          isEdit: true
        });

        dispatch(fetchBills());
        if (payload.isPartialBill) {
          dispatch(fetchOrders());
        }
        replaceURLToModuleURL();
        DashboardService.apiConfig = SellDashboardConfig.config;
        dispatch(getBuyDashboard());
      },
      (err) => {
        console.error('Error while creating Bill: ', err);
        setButtonStatus(false);
        showAlertOnDocAPIError(err);
        replaceURLToModuleURL();
      }
    );
  };

  const updateBill = (closeOnUpdate = true) => {
    setButtonStatus(true);
    setValidationDisplayStatus(true);

    let payload: any = deepClone(updatedBill);
    payload = { ...payload, contact: payload.contactDto };
    payload = updateAddressAsPerLocationCF(payload);
    if (tenantInfo.additionalSettings?.CASCADING_DISCOUNTS?.enable) {
      payload = {
        ...payload,
        purchaseInvoiceProducts: rebuildCascadingDiscountsForSaving(payload)
      };
    }
    if (
      payload.documentType === DOC_TYPE.BILL ||
      payload.documentType === DOC_TYPE.ORDER
    ) {
      payload = {
        ...payload,
        purchaseInvoiceProducts: convertExpectedDeliveryDateInString(payload)
      };
    }
    delete payload?.items;
    delete payload?.contactDto;

    if (payload.attachmentIds?.length) {
      payload.attachments = payload.attachmentIds.map(
        (attachmentId: any) => `${attachmentId}`
      );
    }

    if (zeroAmountDocument) {
      payload.dueAmount = payload.totalAmount;
      payload.totalAmountInBaseCurrency = payload.totalAmount;
      payload.paymentStatus = PAYMENT_STATUS.PENDING;
    }
    if (!isDocValid(payload, closeOnUpdate)) {
      setButtonStatus(false);
      return;
    }

    validateAccountsBudget(payload, true, closeOnUpdate);
  };

  const validateAccountsBudget = (
    doc: any,
    isUpdate: boolean,
    closeDoc: boolean
  ) => {
    const accountsAndCFInfo = getAccountsAndCFForBudgetValidation(
      [...doc.purchaseInvoiceProducts],
      doc.customField,
      'accountCode',
      'tax',
      doc.exchangeRate ? doc.exchangeRate : 1,
      true
    );
    const allAccountsData = accountsAndCFInfo?.accountsInfo;
    const customField = accountsAndCFInfo?.customField;

    if (allAccountsData.length) {
      validateBudgetForAccounts(
        doc.documentDate,
        allAccountsData,
        customField,
        doc.journalEntryCode ? doc.journalEntryCode : null
      ).then(
        (budgetResp: { data: any[]; limitCrossed: boolean }) => {
          if (budgetResp.limitCrossed) {
            let buttons = [
              {
                title: 'Cancel',
                className: 'bg-gray2 border-m ',
                onClick: () => {
                  setButtonStatus(false);
                }
              },
              {
                title: 'Proceed',
                className: 'bg-button text-white ml-r',
                onClick: () => {
                  isUpdate
                    ? makeUpdateAPICall(doc, closeDoc)
                    : makeCreateAPICall(doc, closeDoc);
                }
              }
            ];
            let message =
              '<ul class="text-align-left" style="list-style-type: disc; margin-left: 5px;">';
            budgetResp.data?.forEach((alertData: any) => {
              message += `<li>${alertData.message}</li>`;
            });
            message +=
              '<div class="text-align-center mt-l">Do you wish to continue?</div>';
            message += '</ul>';
            showAlert('Warning!', message, buttons);
          } else {
            isUpdate
              ? makeUpdateAPICall(doc, closeDoc)
              : makeCreateAPICall(doc, closeDoc);
          }
        },
        (err) => {
          console.error('Error validating accounts budget: ', err);
        }
      );
    }
  };

  const makeUpdateAPICall = (payload: any, closeOnUpdate: boolean) => {
    BillService.updateBill(
      payload,
      Utility.getTenantSpecificApiCode(COMPLIANCE_SPECIFIC_FIELD_NAME.BILL)
    ).then(
      (res) => {
        if (props.draftData) {
          if (closeOnUpdate) {
            dispatch(removeDraft(props.draftData.id));
          }
          setButtonStatus(false);
          updateForm(res, payload, true, closeOnUpdate);
        }
        dispatch(
          fetchDrafts({
            tableId: draftsTableId,
            isSaveColumnId: isSavedColumnId,
            draftTypeColId: draftTypeColId,
            draftTypeColValue: LABELS.BILLS
          })
        );
        dispatch(fetchBills());
        setButtonStatus(false);
        replaceURLToModuleURL();
        if (payload?.paymentMilestoneFlag) {
          dispatch(
            documentUpdated({
              newData: payload,
              oldData: props.populateFormData
            })
          );
        }
      },
      (err) => {
        console.error('Error while updating Bill: ', err);
        setButtonStatus(false);
        showAlertOnDocAPIError(err);
        replaceURLToModuleURL();
      }
    );
  };

  const handleBillUpdate = (doc: Document) => {
    if (tenantInfo.additionalSettings?.CASCADING_DISCOUNTS?.enable) {
      doc = {
        ...doc,
        items: rebuildCascadingDiscountsForSaving(doc)
      };
    }
    delete doc.customerOrderNumber;
    let billWithUpdate: Bill = {
      ...bill,
      ...doc,
      purchaseInvoiceProducts: doc.items,
      purchaseInvoiceDueDate: doc.validTillDate,
      billDue: doc.validTillDate,
      purchaseInvoiceDate: doc.documentDate,
      receiveByDate: doc.fulfillmentDate,
      currency: doc.currency,
      totalAmount: doc.totalAmount,
      placeOfSupply: doc?.shipFrom?.placeOfSupply
        ? doc?.shipFrom?.placeOfSupply
        : ''
    };

    let editBillWithExtraKeys = {
      purchaseInvoiceType: BUY_TYPE.INVENTORY,
      openingInvoice: false,
      documentCode: bill.purchaseInvoiceCode,
      documentType: DOC_TYPE.BILL,
      currency: doc.currency,
      priceListId: doc?.priceListId,
      priceListName: doc?.priceListName,
      purchaseInvoiceProducts: billWithUpdate.purchaseInvoiceProducts?.map(
        (billDoc) => {
          return {
            ...billDoc,
            documentItemCode: billDoc.purchaseInvoiceItemCode
          };
        }
      )
    };

    if (props.documentMode === DOCUMENT_MODE.EDIT) {
      billWithUpdate = { ...billWithUpdate, ...editBillWithExtraKeys };
    }

    setUpdatedBill(billWithUpdate);
  };

  return (
    <NewDocument2
      booksDocument={bill}
      permissionKeys={PERMISSIONS_BY_MODULE.BILL}
      documentMode={props.documentMode ? props.documentMode : DOCUMENT_MODE.NEW}
      draftData={props.draftData}
      draftType={props.draftData.draftType}
      canValidate={props.draftData.canValidate}
      onDocumentUpdate={(x: Document) => handleBillUpdate(x)}
      isCenterAlign={isCenterAlign}
      updateDocWithoutClosing={() => updateBill(false)}
      auditLogView={props.auditLogView}
    />
  );
};

export default NewBill;
