import {
  DKIcon,
  DKIcons,
  showAlert,
  showLoader,
  removeLoader
} from 'deskera-ui-library';
import {
  ReactNode,
  useCallback,
  useEffect,
  useReducer,
  useRef,
  useState
} from 'react';

//services
import {
  COMMON_EVENTS,
  commonCustomEvent,
  IRecordSavedEventData
} from '../../../Services/event/commonEvents';
import WorkOrderService, {
  addBulkWorkOrder,
  getDefaultWhForCompleteWO,
  IWorkOrder,
  IWorkOrderItems,
  IWorkOrderOperation,
  setDefaultWhForCompleteWO
} from '../../../Services/MRP/WorkOrder';
import CommonQualityCheckService from '../../../Services/QualityCheck';
import {
  ISalesOrder,
  SalesOrderItemsEntity
} from '../../../Services/SalesOrder';

//static imports
import {
  DOC_TYPE,
  GOOGLE_NO_TRANSLATE_CLASS,
  PRODUCE_PRODUCT_TYPE,
  PRODUCT_TYPE,
  RECORD_SAVED_EVENT_DOC_TYPE,
  TRACKING_TYPE,
  WIP_CONSUMPTION_TYPES
} from '../../../Constants/Constant';
import { ADVANCE_TRACKING } from '../../../Constants/Enum';
import useQCConfirm from '../../../Hooks/useQCConfirm';
import RouteManager, { PAGE_ROUTES } from '../../../Managers/RouteManager';
import { useAppDispatch, useAppSelector } from '../../../Redux/Hooks';
import Utility, {
  deepClone,
  getRandomAlphaNumericString,
  getRandomNumber
} from '../../../Utility/Utility';
import {
  BOM_EXPLOSION_COLUMN_KEYS,
  WO_ADDITIONAL_CHARGES_KEYS,
  WORK_ORDER_STATUS
} from '../Constants/MRPColumnConfigs';
import { REQUIRED_ITEM_TABLE } from '../Constants/TableConstant';
import {
  broadcastOnWorkOrderSaved,
  calculateCostPerUnit,
  createLinkedWorkOrderDataForNewPoPr,
  createLinkedWorkOrderDataForNewWorkOrder,
  createPayloadForWorkOrder,
  generateSequenceNumberForWorkOrderItem,
  getActionButtonTitle,
  getAdhocRawMaterialRow,
  getProductShortInfoRequestDataFromWorkOrders,
  hasReservedQuantityChanged,
  isTrackingInfoAvailableForCompleteWO,
  isWasteManagementGridVisible,
  mapDataFromNoneTrackPopup,
  mergeAndGetSelectedSubstitutesForRawMaterialRow,
  populateInnerBomConfigurationInWoItemsFromBomExplosionConfig,
  populateProductDetailWithSelectedOrDefaultBom,
  validateScrapByProductDetails,
  WorkOrderHelper
} from './WorkOrderHelper';

//slices
import { fetchAdvancedTrackingData } from '../../../Redux/Slices/AdvancedTrackingDataSlice';
import { activeTenantInfo } from '../../../Redux/Slices/AuthSlice';
import {
  fetchWorkOrderList,
  selectWorkOrderAssociatedPOPR,
  selectWorkOrderBomExplosionAssociation,
  updatePOPRAssociation,
  updateWOBomexplosionAssociation
} from '../../../Redux/Slices/MRP/WorkOrderSlice';

// components
import { Invoice } from '../../../Models/Invoice';
import { fetchInvoices } from '../../../Redux/Slices/InvoicesSlice';
import { selectIsWOAdhocEnable } from '../../../Redux/Slices/MRP/SettingsSlice';
import { fetchSalesOrders } from '../../../Redux/Slices/SalesOrderSlice';
import { isCustomFieldsValid } from '../../../Services/CustomField';
import { DynamicPopupWrapper } from '../../../SharedComponents/PopupWrapper';
import DKTabPanel from '../../Common/DkTabPanel';
import useAddWorkOrderReducer, {
  ADD_WORK_ORDER_INITIAL_STATE,
  fetchAndStoreBOMDetailsByMaterialCodes,
  fetchConsumptionDetailsByWorkOrder,
  fetchJobCardsByWorkOrder,
  fetchJWODetailsByWorkOrder,
  initializeWorkOrderData,
  onBomSelection,
  woSliceStateKeys
} from './AddWorkOrderSlice';
import GetReceivePopupForTargetWarehouseWO from './InnerComponents/BomMaterialComponent/GetReceivePopupForTargetWarehouseWO';
import RawMaterialList from './InnerComponents/BomMaterialComponent/RawMaterialList';
import CompleteWorkOrderPopup from './InnerComponents/CompleteWorkOrder/CompleteWorkOrderPopup';
import WIPConsumeProduceList from './InnerComponents/ConsumptionProduction/WIPConsumeProduceList';
import CostingHeader from './InnerComponents/CostingHeader/CostingHeader';
import WasteManagementList from './InnerComponents/ScrapByProductDetails/WasteManagementList';
import {
  additionalBOMAdditionalCostCalculate,
  additionalChargesCalculate,
  totalCostCalculation
} from './InnerComponents/WOAdditionalCharges/WorkOrderAdditionalChargeHelper';
import WorkOrderAdditionalCharges from './InnerComponents/WOAdditionalCharges/WorkOrderAdditionalCharges';
import WOAttachments from './InnerComponents/WOAttachments/WOAttachments';
import WorkOrderBomSelectionView from './InnerComponents/WOBomSelectionView/WorkOrderBomSelectionView';
import WOCustomField from './InnerComponents/WOCustomFields/WOCustomFields';
import BomExplosionPopup from './InnerComponents/BomMaterialComponent/BomExplosionPopup';
import JobCardList from './InnerComponents/WOJobCardList/JobCardList';
import JobWorkoutWOList from './InnerComponents/WOJobWorkoutList/JobWorkoutWOList';
import OperationsList from './InnerComponents/WOOperationList/OperationsList';
import WorkOrderDetails from './InnerComponents/WorkOrderDetails/WorkOrderDetails';
import WorkOrderHeader from './InnerComponents/WorkOrderHeader/WorkOrderHeader';
import { PERMISSIONS_BY_MODULE } from '../../../Constants/Permission';
import { checkUserPermission } from '../../Settings/GranularPermissions/GranularPermissionsHelper';

export interface IAddNewWorkOrder {
  salesOrder?: ISalesOrder | ISalesOrder[] | null;
  salesInvoice?: Invoice;
  convertWOData?: any;
  workOrder?: any;
  onClose?: (needRefresh?: any) => void;
  onSave?: any;
  /** @deprecated To replace this prop for materialMaster with materialList prop  */
  preSelectedBOM?: any;
  materialList?: any[];
  onGotoClick?: any;
  continueInEditMode?: (workOrders: IWorkOrder[], tabIndex: number) => void;
  normalActionBtnNeeded?: boolean;
  isReadOnly?: boolean;
  isFlowFromBomExplosion?: boolean;
  workOrderSourceDetails?: any; // this props will be only required in case of BOM Explosion, because new form gets opened here and component doesnt have this 'workOrderSourceDetails' props ready in it
  hideContinueButtonAfterSave?: any;
  targetWarehouse?: any;
  isCopyMode?: boolean; 
}

const AddNewWorkOrder = (props: IAddNewWorkOrder) => {
  const { reducer, actions } = useAddWorkOrderReducer;
  let [state, woDispatch] = useReducer(reducer, ADD_WORK_ORDER_INITIAL_STATE);
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const workOrderInstanceKey = useRef(getRandomAlphaNumericString(16));

  /** States to replace with store data / Derive from store instead */
  let {
    workOrders,
    salesOrder,
    salesInvoice,
    jwoDetails,
    bomProductDetailsList,
    operationIdToJobMapping: operationIdToJobMappingData,
    jobCardListResponse,
    operationListResponse,
    needBomSelection,
    loading,
    focusedField,
    invalidPlannedStartDate,
    invalidPlannedEndDate,
    showCompleteWorkOrderPopup,
    showWarehouseInventoryPopup,
    showWOLinkedRecordsPopup,
    detailsPopupData,
    oldPlannedQtyInState,
    pristineWorkOrderData,
    allProductWarehouseStocks,
    selectedOperatorsByProductId,
    consumptionSummaryResponse,
    workOrderToEdit,
    componentProductShortInfoList,
    isInnerDocumentRelatedToCurrentWOSaved
  } = state;

  let activeTabWorkOrder: any = workOrders?.[activeTabIndex];

  const productImageDetails = activeTabWorkOrder?.images || [];

  const [updating, setUpdating] = useState(false);
  const [canValidate, setCanValidate] = useState(false);
  const [isCompleteWorkOrderClicked, setIsCompleteWorkOrderClicked] =
    useState(false);
  const isEditMode = !Utility.isEmpty(props.workOrder) && !props.isCopyMode;
  const isCopyMode = props.isCopyMode;
  const isReadOnlyMode = Boolean(
    props.isReadOnly ??
      (activeTabWorkOrder &&
        activeTabWorkOrder.status === WORK_ORDER_STATUS.COMPLETED)
  );

  /// Other states..
  const [showBOMListPicker, setShowBOMListPicker] = useState(false);
  const [showBOMExplosionPopup, setShowBOMExplosionPopup] = useState(false);
  const [invalidActualEndDate, setInvalidActualEndDate] = useState(false);
  const hasConsumptionEntries = Utility.isNotEmpty(consumptionSummaryResponse);
  const hasProductionEntry = Boolean(
    consumptionSummaryResponse?.some(
      (item) => item.wipProcessTypes === WIP_CONSUMPTION_TYPES.PRODUCTION
    )
  );
  const [isRequisitionPending, setIsRequisitionPending] = useState(false);
  const [isInventoryDataChanged, setIsInventoryDataChanged] = useState(false);
  const [defaultWarehouseForCompleteWo, setDefaultWarehouseForCompleteWo] =
    useState<any>();

  //refs
  const workOrderProducts = useRef<any>([]);
  const isWorkOrderUnsaveRef = useRef<any>(null);

  /** selectors goes here */
  const dispatch: any = useAppDispatch();
  const { qcConfirm } = useQCConfirm();
  const tenantInfo = useAppSelector(activeTenantInfo);
  const associatedPOPR = useAppSelector(selectWorkOrderAssociatedPOPR);
  const associatedBOMExplosionObj = useAppSelector(
    selectWorkOrderBomExplosionAssociation
  );
  const isAdhocEnabled = useAppSelector(selectIsWOAdhocEnable);

  const noLinkedSalesOrder = !Array.isArray(salesOrder)
    ? Utility.isEmpty(salesOrder?.salesOrderCode)
    : Utility.isEmpty(salesOrder?.[0]?.salesOrderCode);
  const noLinkedSalesInvoice = Utility.isEmpty(salesInvoice?.salesInvoiceCode);

  /**
   * Update main state using partial object
   * @param partialState Object containing one or multiple key-value pairs from IAddWorkOrderSliceState interface
   */
  const updateCommonState = useCallback(
    (partialState: { [key: string]: any }) => {
      woDispatch(actions.setState({ state: partialState }));
    },
    [actions]
  );

  const updateActiveWorkOrderDataKey = useCallback(
    (key: string, value: any) => {
      woDispatch(
        actions.setWorkOrderByCode({
          activeIndex: activeTabIndex,
          key,
          value
        })
      );
    },
    [actions, activeTabIndex]
  );

  /**
   * @todo
   * Change this to update central state once only..
   */
  const updateActiveWorkOrderDataKeys = (data: { [key: string]: any }) => {
    Object.keys(data)?.forEach((key) =>
      woDispatch(
        actions.setWorkOrderByCode({
          activeIndex: activeTabIndex,
          key,
          value: data[key]
        })
      )
    );
  };
  /** ****** EFFECTS & REFRESH UTILS ******
   * **************************************
   * **************************************
   */
  const isInitializationTriggeredRef = useRef(false);
  useEffect(() => {
    if (isInitializationTriggeredRef.current) return;
    isInitializationTriggeredRef.current = true;
    // let salesOrderCopy = { ...props.salesOrder };
    initializeWorkOrderData({
      data: {
        salesOrder: props.salesOrder,
        salesInvoice: props.salesInvoice,
        workOrder: props.workOrder,
        bomMaterialList: props.materialList,
        preSelectedTargetWarehouse: props.targetWarehouse
      },
      dispatch: woDispatch
    });
    if (Utility.isRRBTaggingEnabled()) {
      getDefaultWarehouseForWO();
    }
  }, [
    props.salesInvoice,
    props.salesOrder,
    props.convertWOData,
    props.workOrder,
    props.materialList,
    workOrders
  ]);

  const workOrderDocSeqCode =
    props.workOrder?.documentSequenceCode ||
    activeTabWorkOrder?.documentSequenceCode;
  const fetchWorkOrderToUpdate = useCallback(() => {
    if (workOrderDocSeqCode) {
      WorkOrderService.getWorkOrderByCode(workOrderDocSeqCode)
        .then((response: any) => {
          if (!Utility.isEmpty(response?.content)) {
            let woObject = response?.content?.find(
              (item: any) => item.documentSequenceCode === workOrderDocSeqCode
            );
            woObject &&
              initializeWorkOrderData({
                data: {
                  workOrder: woObject
                },
                dispatch: woDispatch
              });
          }
        })
        .catch((err: any) => {
          console.error('error in Work order fetch', err);
        });
    }
  }, [workOrderDocSeqCode]);

  const refreshBOMDetails = useCallback(
    (
      materialCodes: string[] = [],
      salesOrder = state.salesOrder,
      salesInvoice = state.salesInvoice,
      revertSalesOrderSelectionToOld?: boolean,
      revertSalesInvoiceSelectionToOld?: boolean
    ) => {
      fetchAndStoreBOMDetailsByMaterialCodes({
        data: {
          workOrderBomMetaCode: props.workOrder?.bomMetaCode,
          materialCodes: materialCodes,
          salesOrder: salesOrder,
          salesInvoice: salesInvoice,
          revertSalesOrderSelectionToOld,
          revertSalesInvoiceSelectionToOld
        },
        state,
        dispatch: woDispatch
      });
    },
    [props.workOrder?.bomMetaCode, state]
  );

  const refreshProductShortInfoForWorkOrders = useCallback(() => {
    onBomSelection({
      state,
      newBomProductList: bomProductDetailsList,
      dispatch: woDispatch
    });
  }, [bomProductDetailsList, state]);

  const currentShortFallSetting =
    tenantInfo?.additionalSettings?.WO_STOCK_SHORTFALL_SETTING;
  useEffect(() => {
    if (!Utility.isEmpty(associatedPOPR)) {
      let linkedDocumentsToUpdate = [
        ...(activeTabWorkOrder?.linkedDocuments ?? [])
      ].concat(
        createLinkedWorkOrderDataForNewPoPr(
          associatedPOPR,
          currentShortFallSetting
        )
      );

      updateActiveWorkOrderDataKey('linkedDocuments', linkedDocumentsToUpdate);
      dispatch(updatePOPRAssociation(null));
    }
  }, [
    associatedPOPR,
    activeTabWorkOrder,
    dispatch,
    currentShortFallSetting,
    updateActiveWorkOrderDataKey
  ]);

  const refreshJobCardDetails = useCallback(() => {
    updateActiveWorkOrderDataKey('workOrderJobCardListFetchInProgress', true);
    fetchJobCardsByWorkOrder({
      workOrderCode: props.workOrder?.workOrderCode,
      dispatch: woDispatch
    }).then(() => {
      updateActiveWorkOrderDataKey(
        'workOrderJobCardListFetchInProgress',
        false
      );
    });
  }, [props.workOrder, updateActiveWorkOrderDataKey]);

  const editWorkOrderCode = workOrderToEdit?.workOrderCode;
  const refreshJWODetailsForCurrentWorkOrder = useCallback(() => {
    editWorkOrderCode &&
      fetchJWODetailsByWorkOrder({
        workOrderCode: editWorkOrderCode,
        dispatch: woDispatch
      });
  }, [editWorkOrderCode]);

  const refreshConsumptionSummaryForCurrentWorkOrder = useCallback(() => {
    editWorkOrderCode &&
      fetchConsumptionDetailsByWorkOrder({
        workOrderCode: editWorkOrderCode,
        dispatch: woDispatch
      });
  }, [editWorkOrderCode]);

  useEffect(() => {
    if (
      !associatedBOMExplosionObj ||
      Utility.isEmpty(associatedBOMExplosionObj)
    )
      return;

    const bomExplosionProductId = associatedBOMExplosionObj[0]?.parentProductId;
    const currentWorkOrderProductId = activeTabWorkOrder?.productCode;
    if (isEditMode && bomExplosionProductId === currentWorkOrderProductId) {
      setShowBOMExplosionPopup(true);
    }
  }, [associatedBOMExplosionObj, activeTabWorkOrder, isEditMode]);

  const activeWorkOrderCode = activeTabWorkOrder?.workOrderCode;
  const activeWorkOrderLinkedDocs = activeTabWorkOrder?.linkedDocuments;
  useEffect(() => {
    const onLinkedRecordUpdated = (data: { detail: IRecordSavedEventData }) => {
      if (!data?.detail) return;
      if (isEditMode && data.detail.isEdit) {
        switch (data.detail.type) {
          case RECORD_SAVED_EVENT_DOC_TYPE.DISPATCHED_GOODS:
          case RECORD_SAVED_EVENT_DOC_TYPE.CREATE_BILL:
          case RECORD_SAVED_EVENT_DOC_TYPE.JOB_WORK_OUT:
            refreshJWODetailsForCurrentWorkOrder();
            break;
          case RECORD_SAVED_EVENT_DOC_TYPE.RECEIVED_GOODS:
            if (data.detail.linkedDocType === DOC_TYPE.JOB_WORK_OUT_ORDER) {
              refreshJWODetailsForCurrentWorkOrder();
            }
            fetchWorkOrderToUpdate();
            break;
          case RECORD_SAVED_EVENT_DOC_TYPE.WORK_ORDER:
            if (
              data.detail.linkedDocType === DOC_TYPE.WORK_ORDER &&
              data.detail.linkedDocId === activeWorkOrderCode
            ) {
              fetchWorkOrderToUpdate();
            }
            break;
          case RECORD_SAVED_EVENT_DOC_TYPE.PURCHASE_ORDER:
            if (
              data.detail.linkedDocType === DOC_TYPE.WORK_ORDER &&
              data.detail.linkedDocId === activeWorkOrderCode
            ) {
              fetchWorkOrderToUpdate();
            }
            break;
          default:
            refreshProductShortInfoForWorkOrders();
        }
      }

      if (isEditMode) {
        const isJwoCreated =
          !data.detail.isEdit &&
          data.detail.type === RECORD_SAVED_EVENT_DOC_TYPE.JOB_WORK_OUT &&
          data.detail.linkedDocType === DOC_TYPE.WORK_ORDER;
        isJwoCreated && refreshJWODetailsForCurrentWorkOrder();
      }
    };

    commonCustomEvent.on(
      COMMON_EVENTS.RECORD_SAVED,
      onLinkedRecordUpdated,
      true
    );
    return () =>
      commonCustomEvent.remove(
        COMMON_EVENTS.RECORD_SAVED,
        onLinkedRecordUpdated
      );
  }, [
    isEditMode,
    activeWorkOrderCode,
    activeWorkOrderLinkedDocs,
    fetchWorkOrderToUpdate,
    updateActiveWorkOrderDataKey,
    refreshJWODetailsForCurrentWorkOrder,
    refreshProductShortInfoForWorkOrders
  ]);

  useEffect(() => {
    let status = false;
    if (isWorkOrderUnsaveRef.current === null) {
      status = !isEditMode;
    } else {
      status = isWorkOrderUnsaveRef.current.data === workOrders;
    }
    isWorkOrderUnsaveRef.current = { status, data: workOrders };
  }, [workOrders, isEditMode]);

  useEffect(() => {
    updateCommonState({
      oldPlannedQtyInState:
        pristineWorkOrderData[activeTabIndex]?.manufactureQuantity
    });
  }, [activeTabIndex, pristineWorkOrderData, updateCommonState]);

  const getPayloadForWorkOrder = (workOrders: any[]) =>
    createPayloadForWorkOrder({
      isAdhocEnabled,
      workOrdersArray: workOrders,
      workOrderSourceDetailsFromProps: props.workOrderSourceDetails,
      pristineWorkOrderData: pristineWorkOrderData?.[activeTabIndex],
      isRequisitionPending,
      isFlowFromBomExplosion: props.isFlowFromBomExplosion,
      workOrderFromProps: props.workOrder,
      jwoDetails,
      isCopyMode
    });

  const loadBatchTrackingData = (product: any) => {
    if (product && product.advancedTracking === ADVANCE_TRACKING.BATCH) {
      try {
        dispatch(fetchAdvancedTrackingData(product.productId));
      } catch (err) {
        console.error('Error fetching Advanced Tracking Products: ', err);
      }
    }
  };

  const woApiCall = async (
    editFormData: any,
    alertMessage: any,
    isQcEnabled: boolean = false
  ) => {
    let payload = getPayloadForWorkOrder([editFormData]);

    if (isQcEnabled) {
      payload = payload?.map((obj) => {
        obj.isQcEnabled = true;
        obj.qcStatus = 'PENDING';
        return obj;
      });
    } else {
      payload = payload?.map((obj) => {
        obj.isQcEnabled = isQcEnabled;
        return obj;
      });
    }

    setUpdating(true);
    const [workOrder] = payload;
    try {
      const response: any = await WorkOrderService.updateWorkOrder(
        workOrder,
        props.workOrder?.id
      );
      if (response) {
        broadcastOnWorkOrderSaved(
          response.workOrderCode,
          isEditMode,
          response.parentWorkOrderCode ?? props.workOrder?.parentWorkOrderCode
        );
      }
      await dispatch(fetchWorkOrderList()).finally(() => {
        /* Suppressed work order refresh from parent */
        if (response) props.onSave?.(response, false);
      });
    } catch (err) {
      props.onClose?.();
    } finally {
      setUpdating(false);
    }
  };

  /******************UTILS*****************
   * **************************************
   * **************************************
   */
  const isFormConfirm = () => {
    let isValid = true;
    if (!Utility.isEmpty(activeTabWorkOrder)) {
      try {
        const actualStartDate = new Date(
          activeTabWorkOrder.actualStartDate
        )?.setHours(0, 0, 0, 0);
        const actualEndDate = new Date(
          activeTabWorkOrder.actualEndDate
        )?.setHours(0, 0, 0, 0);

        if (actualStartDate > actualEndDate) {
          setInvalidActualEndDate(true);
          isValid = false;
        }
      } catch (err) {
        setInvalidActualEndDate(true);
        isValid = false;
      }
    }
    return isValid;
  };

  function validateItemsWasteOperationAdditionalCosts(
    workOrders: any,
    isValid: boolean
  ) {
    for (let i = 0; i < workOrders.length; i++) {
      const currentWO = workOrders[i];

      //Validation for additional Cost.
      const additionalChargesDetails: any[] =
        currentWO.additionalCharges?.additionalChargesDetails;
      if (!Utility.isEmpty(additionalChargesDetails)) {
        const isAnyRowInvalid = additionalChargesDetails.some(
          (c: any) => c.invalidRow
        );
        if (isAnyRowInvalid) {
          isValid = false;
          setActiveTabIndex(i);
          break;
        }
      }
      if (!isCustomFieldsValid(currentWO?.customField)) {
        isValid = false;
        setActiveTabIndex(i);
        break;
      }

      let rawMaterials = currentWO.workOrderItems;
      if (Utility.isEmpty(rawMaterials)) {
        isValid = false;
        showAlert(
          `Invalid BOM material details!`,
          `Please add at least one BOM material.`
        );
        setActiveTabIndex(i);
      } else {
        let productNotSelected = rawMaterials?.find((item: any) =>
          Utility.isEmpty(item?.itemName)
        );
        let productRequiredQtyMissing = rawMaterials?.find(
          (item: any) =>
            item &&
            Utility.isEmptyValue(item.requiredQty) &&
            ![
              PRODUCE_PRODUCT_TYPE.SCRAP,
              PRODUCE_PRODUCT_TYPE.CO_PRODUCT
            ].includes(item.produceProductType)
        );
        if (productNotSelected || productRequiredQtyMissing) {
          showAlert(
            'Invalid material found!',
            productNotSelected
              ? `Looks like product is not selected for a ${
                  productNotSelected.produceProductType ===
                  PRODUCE_PRODUCT_TYPE.NONE
                    ? 'BOM material'
                    : 'Scrap/By-Product material'
                }.`
              : `Looks like required quantity is not added for ${
                  productRequiredQtyMissing?.itemName?.name
                    ? productRequiredQtyMissing.itemName.name
                    : productNotSelected.produceProductType ===
                      PRODUCE_PRODUCT_TYPE.NONE
                    ? 'BOM material'
                    : 'Scrap/By-Product material'
                }.`
          );
          isValid = false;
          setActiveTabIndex(i);
          break;
        }
      }

      let operations = currentWO.workOrderOperations;
      if (!Utility.isEmpty(operations)) {
        let operationNotSelected = operations?.some((item: any) =>
          Utility.isEmpty(item?.operationName?.name)
        );
        if (operationNotSelected) {
          showAlert(
            'Invalid operation found!',
            'Looks like empty operation is added, please assign from existing operations.'
          );
          isValid = false;
          setActiveTabIndex(i);
          break;
        }
      }

      let bomAdditionalCharges = currentWO?.bomAddCostConfiguration;
      if (!Utility.isEmpty(bomAdditionalCharges) && isAdhocEnabled) {
        let productNotSelected = bomAdditionalCharges?.some((item: any) =>
          Utility.isEmpty(item?.label)
        );
        if (productNotSelected) {
          showAlert(
            'Invalid additional charge found!',
            'Looks like empty additional charge is added, please assign a charge.'
          );
          isValid = false;
          setActiveTabIndex(i);
          break;
        }
        // let chargeValueZero = bomAdditionalCharges?.some(
        //   (item: any) => item?.price === 0 || isNaN(item?.price)
        // );
        // if (chargeValueZero) {
        //   showAlert(
        //     'Invalid additional charge found!',
        //     'Additional charge value cannot be zero.'
        //   );
        //   isValid = false;
        //   setActiveTabIndex(i);
        //   break;
        // }
      }
    }
    return isValid;
  }

  const isFormValid = (checkForReservedQuantity: boolean = false) => {
    let isValid = true;
    if (Utility.isEmpty(activeTabWorkOrder?.targetWarehouse)) {
      isValid = false;
    }

    if (!Utility.isEmpty(activeTabWorkOrder)) {
      let plannedStartDate = activeTabWorkOrder?.plannedStartDate;
      if (Utility.isIsoDate(plannedStartDate)) {
        plannedStartDate = new Date(plannedStartDate);
        plannedStartDate.setHours(0, 0, 0, 0);
      }

      let plannedEndDate = activeTabWorkOrder?.plannedEndDate;
      if (Utility.isIsoDate(plannedEndDate)) {
        plannedEndDate = new Date(plannedEndDate);
        plannedEndDate.setHours(0, 0, 0, 0);
      }

      const tenantFinancialStartDate = new Date(
        tenantInfo?.financialStartDate
      )?.setHours(0, 0, 0, 0);

      if (plannedStartDate > plannedEndDate) {
        updateCommonState({ invalidPlannedEndDate: true });
        isValid = false;
      }

      if (plannedStartDate < tenantFinancialStartDate) {
        updateCommonState({ invalidPlannedStartDate: true });
        isValid = false;
      }
    }

    if (workOrders?.length === 0) {
      showAlert(
        'Unable to save work order',
        'There are no WIP or FG type materials available for this selected sales order.'
      );
      isValid = false;
    }

    isValid = validateItemsWasteOperationAdditionalCosts(workOrders, isValid);
    if (focusedField === 'plannedQty') {
      isValid = false;
    }

    if (
      isValid &&
      checkForReservedQuantity &&
      hasReservedQuantityChanged(
        workOrders,
        pristineWorkOrderData,
        activeTabIndex
      )
    ) {
      showAlert(
        'Reserve Stock',
        'Assigned stock will be reserved for this work order.',
        [
          {
            title: 'Cancel',
            className: 'border-m bg-gray1',
            onClick: () => {}
          },
          {
            title: 'Ok',
            className: 'bg-button text-white ml-2',
            onClick: () => {
              woSaveAPICall([...workOrders]);
            }
          }
        ]
      );
      return;
    }

    return isValid;
  };

  /**************EVENT HANDLERS************
   * **************************************
   * **************************************
   */

  const onChangeSalesOrder = (
    salesOrder: ISalesOrder | ISalesOrder[] | null,
    revertSalesOrderSelectionToOld?: boolean
  ) => {
    const materialCodes: string[] = [];

    if (!Array.isArray(salesOrder)) {
      salesOrder?.salesOrderItems?.forEach((item) =>
        materialCodes.push(item.productCode as string)
      );
    } else {
      salesOrder?.forEach((order: ISalesOrder) => {
        order?.salesOrderItems?.forEach((orderItem: SalesOrderItemsEntity) =>
          materialCodes.push(orderItem.productCode as string)
        );
      });
    }

    refreshBOMDetails(
      materialCodes,
      salesOrder,
      null,
      revertSalesOrderSelectionToOld,
      false
    );
  };

  const onChangeSalesInvoice = (
    salesInvoice: Invoice | null,
    revertSalesInvoiceSelectionToOld?: boolean
  ) => {
    const materialCodes: string[] = [];
    salesInvoice?.salesInvoiceItems?.forEach((item) =>
      materialCodes.push(item.productCode as string)
    );
    refreshBOMDetails(
      materialCodes,
      null,
      salesInvoice,
      false,
      revertSalesInvoiceSelectionToOld
    );
  };

  const setAdditionalCharges = (updatedCharges: any[]) => {
    let allNewCharges = additionalChargesCalculate(updatedCharges, tenantInfo);
    updateActiveWorkOrderDataKey('additionalCharges', allNewCharges);
  };

  const onStatusUpdateClick = (editFormData = activeTabWorkOrder) => {
    let alertMessage = '';
    // in case of start or complete there will only one workorder in workorders array hence passing it as it is.
    let isBomConfigValid = validateItemsWasteOperationAdditionalCosts(
      workOrders,
      true
    );
    if (!isBomConfigValid) {
      return;
    }

    editFormData = { ...editFormData };
    if (editFormData?.status === WORK_ORDER_STATUS.OPEN) {
      if (editFormData?.allJobCardsCompleted) {
        editFormData.status = WORK_ORDER_STATUS.COMPLETED;
        if (
          showCompleteWorkOrderPopup &&
          Utility.isRRBTaggingEnabled() &&
          Utility.isNotEmpty(defaultWarehouseForCompleteWo) &&
          !isInventoryDataChanged
        ) {
          editFormData = processDefaultWhData(editFormData);
        }
        alertMessage = `Work order ${editFormData.documentSequenceCode} Completed.`;
      } else {
        editFormData.status = WORK_ORDER_STATUS.IN_PROGRESS;
        alertMessage = `Work order ${editFormData.documentSequenceCode} started.`;
      }
    } else if (editFormData?.status === WORK_ORDER_STATUS.IN_PROGRESS) {
      if (editFormData?.allJobCardsCompleted) {
        editFormData.status = WORK_ORDER_STATUS.COMPLETED;
        if (
          showCompleteWorkOrderPopup &&
          Utility.isRRBTaggingEnabled() &&
          Utility.isNotEmpty(defaultWarehouseForCompleteWo) &&
          !isInventoryDataChanged
        ) {
          editFormData = processDefaultWhData(editFormData);
        }
        alertMessage = `Work order ${editFormData.documentSequenceCode} Completed.`;
      } else {
        editFormData.status = WORK_ORDER_STATUS.ON_HOLD;
        alertMessage = `Work order ${editFormData.documentSequenceCode} has been put on hold.`;
      }
    } else if (editFormData?.allJobCardsCompleted) {
      editFormData.status = WORK_ORDER_STATUS.COMPLETED;
      if (
        showCompleteWorkOrderPopup &&
        Utility.isRRBTaggingEnabled() &&
        Utility.isNotEmpty(defaultWarehouseForCompleteWo) &&
        !isInventoryDataChanged
      ) {
        editFormData = processDefaultWhData(editFormData);
      }
      alertMessage = `Work order ${editFormData.documentSequenceCode} Completed.`;
    } else if (editFormData?.status === WORK_ORDER_STATUS.ON_HOLD) {
      editFormData.status = WORK_ORDER_STATUS.IN_PROGRESS;
      alertMessage = `Work order ${editFormData.documentSequenceCode} Started.`;
    }
    setCanValidate(true);
    let validated = true; // as per existing flow

    if (
      !isTrackingInfoAvailableForCompleteWO(activeTabWorkOrder) &&
      editFormData.status === WORK_ORDER_STATUS.COMPLETED
    ) {
      validated = false;
      showAlert(
        'Tracking details not found!',
        'Please update allocation/tracking details to complete work order.',
        [
          {
            title: 'Cancel',
            className: 'border-m bg-gray1',
            onClick: () => {}
          },
          {
            title: 'Provide details',
            className: 'bg-button text-white border-m ml-s',
            onClick: () => {
              updateCommonState({
                showWarehouseInventoryPopup: !showWarehouseInventoryPopup
              });
            }
          }
        ]
      );
      return;
    }

    if (
      !isFormConfirm() &&
      editFormData.status === WORK_ORDER_STATUS.COMPLETED
    ) {
      return;
    }
    setInvalidActualEndDate(false);

    if (
      validateScrapByProductDetails(activeTabWorkOrder.workOrderItems) &&
      editFormData?.allJobCardsCompleted
    ) {
      validated = false;
      return;
    }

    if (validated) {
      updateCommonState({ showCompleteWorkOrderPopup: false });

      if (
        getActionButtonTitle(activeTabWorkOrder) ===
        WorkOrderHelper.WORK_ORDER_BTN_STATUS.COMPLETE_ORDER
      ) {
        // return;
        completeWOWithQCFlowCheck(editFormData, alertMessage);
      } else {
        // return;
        woApiCall(editFormData, alertMessage);
      }
    }
  };

  const completeWOWithQCFlowCheck = (formData: any, alertMessage: any) => {
    const productCodes = [formData]?.map((item: any) => {
      return item.productCode;
    });
    setUpdating(true);
    CommonQualityCheckService.getTemplateUsageByProduct(productCodes)
      .then(async (res: any) => {
        setUpdating(false);
        const isFound = res?.some((element: any) => {
          if (element.used) {
            return true;
          }
          return false;
        });

        if (isFound && Utility.isMRP()) {
          const isConfirmed = await qcConfirm(
            `Would you like to perform a quanlity check on the WO Product?`,
            `QC Flow`
          );

          if (isConfirmed === null) {
            return;
          }
          if (isConfirmed) {
            woApiCall(formData, alertMessage, true);
          } else {
            woApiCall(formData, alertMessage);
          }
        } else {
          woApiCall(formData, alertMessage);
        }
      })
      .catch((err: any) => {
        setUpdating(false);
      });
  };

  const handleWOSaveOrUpdateApiFailure = (err: any) => {
    if (isEditMode) {
      showAlert('Unable to save work order', 'Please try again later.');
    } else {
      const errorMsg = [
        'invalid product added in work order items with code',
        'product not available in workorder items with code'
      ];
      if (
        errorMsg?.includes(err?.message?.split?.(` {{`)?.[0]?.toLowerCase())
      ) {
        showAlert(
          'Unable to save work order',
          'It seems like you have updated your material, please reselect your material to avoid descripency.',
          [
            {
              title: 'Ok',
              className: 'bg-button text-white',
              onClick: () => {
                workOrderInstanceKey.current = getRandomAlphaNumericString(16);
                updateCommonState({
                  ...deepClone(ADD_WORK_ORDER_INITIAL_STATE),
                  loading: false
                });
              }
            }
          ]
        );

        return;
      } else if (err?.message?.includes('is already tagged')) {
        showAlert('Unable to save work order', err?.message);
        return;
      }
      showAlert('Unable to save work order', 'Please try again later.');
    }
  };

  const woSaveAPICall = (
    workOrdersArray: any[],
    partialSave: boolean = false,
    ignoreStatus: boolean = false,
    isLinkedRecord: boolean = false
  ) => {
    setUpdating(true);
    let payload: any[] = getPayloadForWorkOrder(workOrdersArray);
    if (ignoreStatus) {
      payload = payload.map((wo: any) => {
        delete wo.status;
        return wo;
      });
    }
    if (isEditMode && !isCopyMode) {
      const [workOrder] = payload;
      WorkOrderService.updateWorkOrder(workOrder, props.workOrder?.id)
        .then((response: any) => {
          response &&
            broadcastOnWorkOrderSaved(
              response.workOrderCode,
              isEditMode,
              response.parentWorkOrderCode ??
                props.workOrder?.parentWorkOrderCode
            );

          dispatch(fetchWorkOrderList());
          if (partialSave) {
            fetchWorkOrderToUpdate();
          } else {
            showConfirmationPopup(response, isEditMode, '');
          }
          if (isLinkedRecord) {
            updateCommonState({ showWOLinkedRecordsPopup: true });
          }
        })
        .catch((error) => {
          handleWOSaveOrUpdateApiFailure(error);
        })
        .finally(() => {
          setUpdating(false);
        });
    } else {
      if(isCopyMode) {
        payload = payload.map((wo: any) => {
          if (Utility.isEmpty(wo.documentSequenceCode)) {
            delete wo.documentSequenceCode;
          }
          return wo;
        });
      }
      addBulkWorkOrder(payload)
        .then((res: any) => {
          /* Suppressed work order refresh from parent, if closed from confirmation popup
            Refer props.onSave callback in showConfirmationPopup
          */
          dispatch(fetchWorkOrderList());
          dispatch(fetchSalesOrders());
          dispatch(fetchInvoices());
          let docCodes = res
            ?.map((wo: any) => wo.documentSequenceCode)
            ?.join(', ');
          setUpdating(true);
          let buttons: any = [];
          if (
            props.salesOrder ||
            props.salesInvoice ||
            props.normalActionBtnNeeded
          ) {
            buttons.push({
              title: 'Ok',
              className: 'bg-button text-white mr-r',
              onClick: () => {
                props.onSave?.(res);
              }
            });

            if (!props.isFlowFromBomExplosion) {
              buttons.push({
                title: 'Go to work orders list',
                className: 'bg-button text-white mr-r',
                onClick: () => {
                  props.onGotoClick();
                }
              });
            }
            showAlert(
              'Work order created',
              `Work order ${docCodes} created successfully.`,
              buttons
            );
            if (props.salesOrder) {
              dispatch(fetchSalesOrders());
            }
            if (props.salesInvoice) {
              dispatch(fetchInvoices());
            }
          } else {
            showConfirmationPopup(res, isEditMode, docCodes);
          }
        })
        .catch((err: any) => {
          handleWOSaveOrUpdateApiFailure(err);
        })
        .finally(() => {
          setUpdating(false);
        });
    }
  };

  const onSave = (
    workOrdersArray: any[],
    partialSave: boolean = false,
    ignoreStatus: boolean = false,
    isLinkedRecord: boolean = false,
    checkForReservedQuantity: boolean = false
  ) => {
    setCanValidate(true);
    updateCommonState({
      invalidPlannedEndDate: false,
      invalidPlannedStartDate: false
    });
    // if (!isFormValid()) {
    //   return;
    // }
    if (isFormValid(checkForReservedQuantity)) {
      woSaveAPICall(workOrdersArray, partialSave, ignoreStatus, isLinkedRecord);
    } else {
      // showAlert('Please fill all the mandatory fields');
    }
  };

  /*************MATERIAL GRID EVENTS******
   * *************************************
   * *************************************
   */
  const checkIfProductConsumed = (productCode: string) => {
    if (!productCode) return false;

    let flag = false;

    /* If process manufacturing is enabled & job cards are present */
    if (
      tenantInfo?.additionalSettings?.LINK_INVENTORY_JOB_CARDS &&
      Utility.isNotEmpty(jobCardListResponse)
    ) {
      flag = jobCardListResponse.some((jobCard) =>
        jobCard?.jobCardLinkDetails?.some(
          (linkedItem: any) => linkedItem.productCode === productCode
        )
      );
    }
    /* If consumption entry present for same product,
    check not required currently as consumption only happens after allocation of product */
    /* else if (Utility.isNotEmpty(consumptionSummaryResponse)) {
      flag = consumptionSummaryResponse.some(
        (entry) =>
          entry.wipProcessTypes === WIP_CONSUMPTION_TYPES.CONSUMPTION &&
          entry.productCode === productCode
      );
    } */

    return flag;
  };

  const onBatchOrSerialSave = (data: any, selectedProductID: any) => {
    const woItemsToUpdate = [...(activeTabWorkOrder?.workOrderItems || [])];
    let selectedProductIndex = woItemsToUpdate.findIndex(
      (woItemItem: any) => woItemItem.itemName.pid === selectedProductID
    );
    if (selectedProductIndex === -1) return;

    const editItem = { ...woItemsToUpdate[selectedProductIndex] };
    editItem.warehouseInventoryData = data?.map((item: any) => {
      return { ...item, quantity: item?.qtyToFulfil ?? 0 };
    });
    if (editItem?.produceProductType === PRODUCE_PRODUCT_TYPE.NONE) {
      if (editItem?.itemName?.advancedTracking === ADVANCE_TRACKING.NONE) {
        editItem.costPerUnit = calculateCostPerUnit(editItem);
      }
    }
    woItemsToUpdate[selectedProductIndex] = editItem;
    updateActiveWorkOrderDataKey('workOrderItems', woItemsToUpdate);
  };

  const onAddNewLinkedDocumentFromBomExplosion = (docDetails: any) => {
    const updatedLinkedDocuments = [
      ...(activeTabWorkOrder.linkedDocuments || []),
      { ...docDetails }
    ];
    updateActiveWorkOrderDataKey('linkedDocuments', updatedLinkedDocuments);
  };

  const onCloseBomExplosionView = (needRefresh?: any) => {
    if (needRefresh) {
      updateCommonState({ isInnerDocumentRelatedToCurrentWOSaved: false });
    }
    setShowBOMExplosionPopup(false);
  };

  const onSaveBomDetailsFromBomExplosion = async (bomExplosionData: any) => {
    let componentProductsFromBomExplosion: any = [
      ...(bomExplosionData?.[BOM_EXPLOSION_COLUMN_KEYS.COMPONENT_PRODUCTS] ||
        [])
    ];

    let updatedWorkOrderItems: IWorkOrderItems[] = [];

    if (isAdhocEnabled) {
      componentProductsFromBomExplosion.forEach(
        (componentProductExplosionData: any, materialIndex: number) => {
          const existingRawMaterialItem =
            activeTabWorkOrder.workOrderItems?.find(
              (rawMaterial: IWorkOrderItems) =>
                (rawMaterial.itemName?.pid ===
                  componentProductExplosionData.pid ||
                  rawMaterial.itemName?.productId ===
                    componentProductExplosionData.pid) &&
                rawMaterial?.produceProductType === PRODUCE_PRODUCT_TYPE.NONE
            );

          const newMaterialRow = getAdhocRawMaterialRow({
            bomExplosionData: componentProductExplosionData,
            workOrderData: activeTabWorkOrder,
            produceProductType: PRODUCE_PRODUCT_TYPE.NONE,
            existingMaterialData: existingRawMaterialItem
          });
          updatedWorkOrderItems.push({
            ...newMaterialRow,
            sequenceNumber: materialIndex
          });
        }
      );

      activeTabWorkOrder.workOrderItems?.forEach(
        (workOrderItem: IWorkOrderItems, itemIndex: number) => {
          if (workOrderItem.produceProductType !== PRODUCE_PRODUCT_TYPE.NONE) {
            updatedWorkOrderItems.push({
              ...workOrderItem,
              sequenceNumber: generateSequenceNumberForWorkOrderItem(
                updatedWorkOrderItems
              )
            });
          }
        }
      );

      if (Utility.isEmpty(updatedWorkOrderItems)) {
        showAlert('Invalid Bom!', 'No component products found.');
        return;
      }

      const hasNewRows = updatedWorkOrderItems.some((item) => item.isNewRow);
      if (hasNewRows) {
        showLoader('Fetching component product prices..');
        try {
          const requestPayloadForProductShortInfo =
            getProductShortInfoRequestDataFromWorkOrders([
              {
                ...activeTabWorkOrder,
                workOrderItems: updatedWorkOrderItems.map((item) => ({
                  ...item,
                  itemId: item.itemName?.productId,
                  productId: item.itemName?.productId
                }))
              }
            ]);

          const componentProductShortInfoList =
            await WorkOrderHelper.getProductShortInfoFor(
              requestPayloadForProductShortInfo
            );
          const shortInfoDataByProductId: { [key: string]: any } = {};
          componentProductShortInfoList.forEach((productShortInfo: any) => {
            shortInfoDataByProductId[productShortInfo?.pid] = productShortInfo;
          });

          const processWorkOrderComponentProductWithProductShortInfo = (
            workOrderItemOrSubstitute: any,
            isSubstitute?: boolean
          ) => {
            const productId = isSubstitute
              ? workOrderItemOrSubstitute?.productId
              : workOrderItemOrSubstitute.itemName?.productId;
            const associatedProductInfo = shortInfoDataByProductId[productId];

            if (!associatedProductInfo) return;

            workOrderItemOrSubstitute.itemName = associatedProductInfo;
            if (associatedProductInfo?.type !== PRODUCT_TYPE.NON_TRACKED)
              workOrderItemOrSubstitute.costPerUnit =
                associatedProductInfo?.inventoryPrice ?? 0;
          };

          updatedWorkOrderItems.forEach((workOrderItem: any) => {
            processWorkOrderComponentProductWithProductShortInfo(workOrderItem);

            workOrderItem[
              REQUIRED_ITEM_TABLE.BOM_PRODUCT_SUBSTITUTE_DETAILS
            ]?.forEach((substitute: any) => {
              processWorkOrderComponentProductWithProductShortInfo(
                substitute,
                true
              );
            });
          });
        } catch (err) {}

        removeLoader();
      }
    } else {
      activeTabWorkOrder.workOrderItems?.forEach((workOrderItem: any) => {
        componentProductsFromBomExplosion.forEach((explosion: any) => {
          if (
            workOrderItem?.itemName?.pid === explosion.pid &&
            explosion?.produceProductType === PRODUCE_PRODUCT_TYPE.NONE
          ) {
            const selectedSubstitutes =
              mergeAndGetSelectedSubstitutesForRawMaterialRow(
                explosion,
                workOrderItem
              );
            workOrderItem = {
              ...workOrderItem,
              warehouseInventoryData: explosion?.warehouseInventoryData || [],
              bomProductSubstitutesDetails: selectedSubstitutes,
              addToRequisition: explosion?.addToRequisition ?? false,
              bomExplosionData: explosion
            };
          }
        });
        updatedWorkOrderItems.push(workOrderItem);
      });
    }
    updateCommonState({ isInnerDocumentRelatedToCurrentWOSaved: true });

    updatedWorkOrderItems = updatedWorkOrderItems.map(
      populateInnerBomConfigurationInWoItemsFromBomExplosionConfig
    );

    updateActiveWorkOrderDataKey('workOrderItems', updatedWorkOrderItems);
    onCloseBomExplosionView();
  };

  const handleWorkOrderCloseFromBomExplosion = () => {
    dispatch(fetchWorkOrderList());
    if (props.onClose) {
      props.onClose();
    }
  };

  const handleChildWorkOrderCreationInNewMode = (
    res: any,
    objForWorkOrder: any
  ) => {
    dispatch(updateWOBomexplosionAssociation(objForWorkOrder));
    props.continueInEditMode?.(res as IWorkOrder[], activeTabIndex);
  };

  const addNewItemInRawMaterial = async (rowData: any) => {
    let productShortInfo: any = await WorkOrderHelper.getProductShortInfoFor([
      {
        productCode: rowData.itemName?.pid ?? rowData.productCode,
        quantity: rowData[REQUIRED_ITEM_TABLE.REQUIRED_QTY],
        uomQuantity: Utility.getUomQuantityWithoutRoundOff(
          rowData[REQUIRED_ITEM_TABLE.REQUIRED_QTY],
          rowData?.documentUOMSchemaDefinition
        ),
        documentUOMSchemaDefinition: rowData?.documentUOMSchemaDefinition
      }
    ]);
    let copyRawMaterialList = [...activeTabWorkOrder.workOrderItems];
    let workorderItemIndex = copyRawMaterialList?.findIndex(
      (item: any) => rowData?.sequenceNumber === item?.sequenceNumber
    );
    if (
      copyRawMaterialList[workorderItemIndex]?.requiredQty !==
      rowData[REQUIRED_ITEM_TABLE.REQUIRED_QTY]
    ) {
      let workorderItemToUpdate = {
        ...copyRawMaterialList?.[workorderItemIndex]
      };
      workorderItemToUpdate.warehouseInventoryData =
        rowData.warehouseInventoryData;
      let numberRequiredQty = Number(rowData[REQUIRED_ITEM_TABLE.REQUIRED_QTY]);
      workorderItemToUpdate[REQUIRED_ITEM_TABLE.REQUIRED_QTY] =
        numberRequiredQty;
      workorderItemToUpdate[REQUIRED_ITEM_TABLE.ACTUAL_REQUIRED_QTY] =
        numberRequiredQty;
      workorderItemToUpdate[REQUIRED_ITEM_TABLE.PLANNED_QTY] =
        numberRequiredQty;
      workorderItemToUpdate[REQUIRED_ITEM_TABLE.PRODUCED_QUANTITY] =
        numberRequiredQty;
      let unitRequiredQty =
        numberRequiredQty / Number(activeTabWorkOrder?.manufactureQuantity);
      workorderItemToUpdate[REQUIRED_ITEM_TABLE.COMPONENT_UNIT_QTY] =
        unitRequiredQty;
      let item: any = WorkOrderHelper.getRawMaterialRow(
        activeTabWorkOrder,
        workorderItemToUpdate,
        productShortInfo?.[0],
        productShortInfo,
        workorderItemIndex,
        isEditMode
      );
      item.callingProductShortInfo = false;
      /* resetting pre-created bom explosion data, whenever anything changes for a material row */
      item.bomExplosionData = null;

      copyRawMaterialList[workorderItemIndex] = item;
      updateActiveWorkOrderDataKey('workOrderItems', copyRawMaterialList);
    }
  };

  const onMaterialOrWasteRowUpdated = ({
    columnKey,
    rowData,
    rowIndex
  }: any) => {
    let workOrderItemsToUpdate = [...(activeTabWorkOrder.workOrderItems || [])];

    const bomProductCodes = activeTabWorkOrder?.product?.bomMetaDetailsList
      ?.find((bom: any) => bom.code === activeTabWorkOrder.adhocBomMetaCode)
      ?.bomProductsConfiguration?.map((item: any) => item.productCode);

    workOrderItemsToUpdate = workOrderItemsToUpdate?.map(
      (itemData: any, index: number) => {
        let item = { ...itemData };
        if (itemData?.sequenceNumber === rowData?.sequenceNumber) {
          const isReqdQuantityChanged =
            columnKey === REQUIRED_ITEM_TABLE.REQUIRED_QTY &&
            Number(itemData[columnKey]) !== Number(rowData[columnKey]);
          item[columnKey] = rowData[columnKey];
          let cInvalidFields = [];

          if (isReqdQuantityChanged) {
            const reqQty = Number(rowData[columnKey]) || 0;
            rowData[columnKey] = reqQty;
            item[columnKey] = reqQty;
            item[REQUIRED_ITEM_TABLE.PLANNED_QTY] = reqQty;
            item.callingProductShortInfo = true;
            item.warehouseInventoryData = []; // reset assignment on quantity change
            addNewItemInRawMaterial(rowData);
          }
          if (
            columnKey === REQUIRED_ITEM_TABLE.SOURCE_WAREHOUSE &&
            rowData[REQUIRED_ITEM_TABLE.SOURCE_WAREHOUSE]
          ) {
            const sourceWareHouse = rowData[columnKey];
            const wareHouseStock = allProductWarehouseStocks?.find(
              (item: any) => item.code === sourceWareHouse.code
            );
            const selectedProductStock =
              wareHouseStock?.productAvailableQuantity?.[
                rowData[REQUIRED_ITEM_TABLE.ITEM_NAME]?.pid
              ];
            if (selectedProductStock) {
              item[REQUIRED_ITEM_TABLE.AVAILABLE_QTY] = selectedProductStock;
            } else {
              item[REQUIRED_ITEM_TABLE.AVAILABLE_QTY] = 0;
            }
          }
          if (columnKey === REQUIRED_ITEM_TABLE.ITEM_NAME) {
            item[REQUIRED_ITEM_TABLE.AVAILABLE_QTY] =
              rowData?.[REQUIRED_ITEM_TABLE.ITEM_NAME]?.inventory
                ?.availableQuantity -
                rowData?.[REQUIRED_ITEM_TABLE.ITEM_NAME]?.inventory
                  ?.reservedQuantity ?? 0;
            item[REQUIRED_ITEM_TABLE.COMPONENT_UNIT_QTY] = 1;
            const reqdQty =
              rowData?.[REQUIRED_ITEM_TABLE.ITEM_NAME]?.type ===
              PRODUCT_TYPE.NON_TRACKED
                ? 1 * activeTabWorkOrder.manufactureQuantity
                : 0;
            item[REQUIRED_ITEM_TABLE.REQUIRED_QTY] = reqdQty;
            item[REQUIRED_ITEM_TABLE.PLANNED_QTY] = reqdQty;
            item[REQUIRED_ITEM_TABLE.STOCK_UOM] =
              rowData?.[REQUIRED_ITEM_TABLE.ITEM_NAME]?.[
                REQUIRED_ITEM_TABLE.STOCK_UOM
              ];

            const newUnitCost =
              rowData?.[REQUIRED_ITEM_TABLE.ITEM_NAME]?.type ===
              PRODUCT_TYPE.NON_TRACKED
                ? rowData?.[REQUIRED_ITEM_TABLE.ITEM_NAME]?.purchasePrice || 0
                : 0;
            item[REQUIRED_ITEM_TABLE.PURCHASE_PRICE] = newUnitCost;
            item[REQUIRED_ITEM_TABLE.UNIT_COST] = newUnitCost;
            item[REQUIRED_ITEM_TABLE.BOM_PRODUCT_SUBSTITUTE_DETAILS] = [];

            const selectedProductWithBom =
              populateProductDetailWithSelectedOrDefaultBom(
                rowData?.[REQUIRED_ITEM_TABLE.ITEM_NAME]
              );
            item.productCode = selectedProductWithBom?.productId;
            item.productDetails = selectedProductWithBom;
            item.bomMetaCode =
              selectedProductWithBom?.selectedBom?.code ?? null;
            item.isNewRow = true;
            item.documentUOMSchemaDefinition = null;

            // check if product not present in bom configuration
            item.isAdHocItem = !bomProductCodes?.includes(item.productCode);

            cInvalidFields.push(REQUIRED_ITEM_TABLE.REQUIRED_QTY);
          }
          if (Utility.isEmpty(rowData[REQUIRED_ITEM_TABLE.ITEM_NAME])) {
            cInvalidFields.push(REQUIRED_ITEM_TABLE.ITEM_NAME);
          }
          if (
            !item?.isNewRow &&
            item[REQUIRED_ITEM_TABLE.AVAILABLE_QTY] <
              rowData[REQUIRED_ITEM_TABLE.REQUIRED_QTY]
          ) {
            cInvalidFields.push(REQUIRED_ITEM_TABLE.AVAILABLE_QTY);
          }
          if (item?.isNewRow && item[REQUIRED_ITEM_TABLE.REQUIRED_QTY] === 0) {
            cInvalidFields.push(REQUIRED_ITEM_TABLE.REQUIRED_QTY);
          }

          if (columnKey === REQUIRED_ITEM_TABLE.PURCHASE_PRICE) {
            const price = rowData[REQUIRED_ITEM_TABLE.PURCHASE_PRICE];
            item.itemName = item.itemName
              ? { ...item.itemName, inventoryPrice: price }
              : item.itemName;
            item.costPerUnit = price;
            item.purchasePrice = price;
          }

          /* resetting pre-created bom explosion data, whenever anything changes for a material row */
          item.bomExplosionData = null;

          item.invalidFields = cInvalidFields;
        }

        return item;
      }
    );

    if (
      activeTabWorkOrder.actualQuantity &&
      activeTabWorkOrder?.actualQuantity > 0 &&
      activeTabWorkOrder.manufactureQuantity &&
      activeTabWorkOrder?.manufactureQuantity > 0
    ) {
      updateActiveWorkOrderDataKey(
        'actualYield',
        (activeTabWorkOrder.actualQuantity /
          activeTabWorkOrder.manufactureQuantity) *
          100
      );
    }
    updateActiveWorkOrderDataKey('workOrderItems', workOrderItemsToUpdate);
  };

  const onWorkOrderCreatedFromRawMaterial = (
    woListResponse: IWorkOrder[],
    substituteObj: any = null
  ) => {
    const newWoDataToLink = createLinkedWorkOrderDataForNewWorkOrder(
      woListResponse,
      substituteObj
    );
    let childWOListWithNew = (
      activeTabWorkOrder.workOrderChildDetails || []
    ).concat(newWoDataToLink);
    updateActiveWorkOrderDataKey('workOrderChildDetails', childWOListWithNew);
    updateCommonState({
      isInnerDocumentRelatedToCurrentWOSaved: false
    });
  };

  const onRawMaterialSubstituteSave = (data: any, sequenceNumber: number) => {
    let workOrderItemsToUpdate = deepClone(
      activeTabWorkOrder.workOrderItems || []
    );
    const workOrderItemToUpdate = workOrderItemsToUpdate.find(
      (woItem: any) => woItem.sequenceNumber === sequenceNumber
    );
    if (workOrderItemToUpdate)
      workOrderItemToUpdate.bomProductSubstitutesDetails = data;
    updateActiveWorkOrderDataKey('workOrderItems', workOrderItemsToUpdate);
  };

  const onNormalProductSaveForWorkOrderItem = (
    data: any,
    selectedItemIndex: number
  ) => {
    if (typeof selectedItemIndex !== 'number' || selectedItemIndex === -1)
      return;

    let workOrderItemsToUpdate = [...(activeTabWorkOrder.workOrderItems || [])];
    let itemToUpdate = {
      ...workOrderItemsToUpdate[selectedItemIndex]
    };
    let warehouseInventoryData = data.map(mapDataFromNoneTrackPopup);
    itemToUpdate.warehouseInventoryData = warehouseInventoryData;
    workOrderItemsToUpdate[selectedItemIndex] = itemToUpdate;
    updateActiveWorkOrderDataKey('workOrderItems', workOrderItemsToUpdate);
  };

  const onRawMaterialNormalProductSave = (
    data: any,
    selectedProductID: number
  ) => {
    let selectedProductIndex = activeTabWorkOrder.workOrderItems.findIndex(
      (woItemItem: any) => woItemItem.itemName.pid === selectedProductID
    );
    onNormalProductSaveForWorkOrderItem(data, selectedProductIndex);
  };

  const getWorkOrderItemIndexByWasteProductData = (wasteProductData: any) => {
    return activeTabWorkOrder?.workOrderItems?.findIndex(
      (woItemItem: any) =>
        woItemItem.itemName.pid === wasteProductData?.itemName?.pid &&
        woItemItem?.produceProductType === wasteProductData?.produceProductType
    );
  };

  const onWasteManagementNormalProductSave = (
    data: any,
    selectedProductData: any
  ) => {
    let selectedProductIndex =
      getWorkOrderItemIndexByWasteProductData(selectedProductData);
    onNormalProductSaveForWorkOrderItem(data, selectedProductIndex);
  };

  const onUpdateWasteManagementGridRowForCompletion = (data: any) => {
    let workOrderItemsToUpdate = [...(activeTabWorkOrder.workOrderItems || [])];
    let itemIndexToUpdate = getWorkOrderItemIndexByWasteProductData(data);
    if (itemIndexToUpdate !== -1) {
      workOrderItemsToUpdate[itemIndexToUpdate] = {
        ...workOrderItemsToUpdate[itemIndexToUpdate],
        actualRequiredQty: data?.actualRequiredQty,
        costPerUnit: data?.costPerUnit
      };
      updateActiveWorkOrderDataKey('workOrderItems', workOrderItemsToUpdate);
    }
  };

  /*************RENDER HELPERS************
   * *************************************
   * *************************************
   */

  const showProductRemoveWarning = (workOrderToRemove: any) => {
    showAlert('Are you sure you want to remove this product?', null, [
      {
        title: 'Cancel',
        className: 'bg-white border-m',
        onClick: () => {}
      },
      {
        title: 'Remove',
        className: 'bg-red text-white',
        onClick: () => {
          setActiveTabIndex(0);
          woDispatch(actions.removeBomProduct(workOrderToRemove));
        }
      }
    ]);
  };

  const showConfirmationPopup = (
    response: any,
    isEditFlow: any,
    docCodes?: any
  ) => {
    if (props.workOrder?.isWOLinkedRecord) {
      props.onSave?.(response);
      return;
    }

    let buttons: any = [
      {
        title: 'Close',
        className: 'bg-gray2 border-m',
        onClick: () => {
          props.onSave?.(response, false);
        }
      }
    ];

    if (
      !props.hideContinueButtonAfterSave &&
      props.continueInEditMode &&
      checkUserPermission(PERMISSIONS_BY_MODULE.WORK_ORDER.EDIT)
    ) {
      buttons.push({
        title: isEditFlow ? 'Continue without closing' : 'Continue',
        className: 'bg-button text-white ml-r',
        onClick: () => {
          if (typeof props.continueInEditMode === 'function') {
            props.continueInEditMode(response, activeTabIndex);
          }
        }
      });
    }

    if (
      !isEditFlow &&
      !props.isFlowFromBomExplosion &&
      checkUserPermission(PERMISSIONS_BY_MODULE.JOB_CARD.VIEW)
    ) {
      buttons.push({
        title: 'View job cards',
        className: 'bg-button text-white ml-r',
        onClick: () => {
          RouteManager.navigateToPage(
            PAGE_ROUTES.MRP_JOB_CARD_BY_WORK_ORDER_ID.replace(
              ':workOrderId',
              encodeURIComponent(docCodes)
            )
          );
        }
      });
    }

    showAlert(
      isEditFlow ? `WO Saved!` : `Work order created`,
      isEditFlow
        ? `Do you wish to close this WO?`
        : `Work order ${docCodes} created successfully.`,
      buttons
    );
  };

  const setDefaultWarehouseForWO = (value: any) => {
    let payLoad: any = {};
    if (value) {
      if (
        activeTabWorkOrder?.product?.advancedTracking === TRACKING_TYPE.NONE
      ) {
        payLoad['defaultTaggedBinTargetWarehouse'] = {
          name: activeTabWorkOrder['warehouseInventoryData']?.[0].binName,
          code: activeTabWorkOrder['warehouseInventoryData']?.[0].binCode,
          rowCode: activeTabWorkOrder['warehouseInventoryData']?.[0].rowCode,
          rowName: activeTabWorkOrder['warehouseInventoryData']?.[0].rowName,
          rackCode: activeTabWorkOrder['warehouseInventoryData']?.[0].rackCode,
          rackName: activeTabWorkOrder['warehouseInventoryData']?.[0].rackName,
          warehouseCode:
            activeTabWorkOrder['warehouseInventoryData']?.[0].warehouseCode,
          warehouseName:
            activeTabWorkOrder['warehouseInventoryData']?.[0].warehouseName
        };
      } else {
        payLoad['defaultTaggedBinTargetWarehouse'] = {
          name: activeTabWorkOrder['advancedTrackingData']?.[0].bin?.name,
          code: activeTabWorkOrder['advancedTrackingData']?.[0].bin?.code,
          rowCode: activeTabWorkOrder['advancedTrackingData']?.[0].row?.code,
          rowName: activeTabWorkOrder['advancedTrackingData']?.[0].row?.name,
          rackCode: activeTabWorkOrder['advancedTrackingData']?.[0].rack?.code,
          rackName: activeTabWorkOrder['advancedTrackingData']?.[0].rack?.name,
          warehouseCode:
            activeTabWorkOrder['advancedTrackingData']?.[0].warehouseCode,
          warehouseName:
            activeTabWorkOrder['advancedTrackingData']?.[0].warehouseName
        };
      }
    } else {
      payLoad['defaultTaggedBinTargetWarehouse'] = null;
    }

    setDefaultWhForCompleteWO(payLoad, tenantInfo.id)
      .then((response: any) => {
        if (Utility.isNotEmpty(response)) {
          setDefaultWarehouseForCompleteWo({
            ...response.defaultTaggedBinTargetWarehouse,
            binCode: response.defaultTaggedBinTargetWarehouse.code,
            binName: response.defaultTaggedBinTargetWarehouse.name
          });
        }
      })
      .catch((err: any) => {
        console.error('error while saving default wo', err);
      });
  };

  const getDefaultWarehouseForWO = () => {
    getDefaultWhForCompleteWO(tenantInfo.id)
      .then((response: any) => {
        if (Utility.isNotEmpty(response)) {
          setDefaultWarehouseForCompleteWo({
            ...response.defaultTaggedBinTargetWarehouse,
            binCode: response.defaultTaggedBinTargetWarehouse.code,
            binName: response.defaultTaggedBinTargetWarehouse.name
          });
        }

        console.log('settings', response);
      })
      .catch((err: any) => {
        console.error('error while getting default wo', err);
      });
  };

  const processDefaultWhData = (currentWO: any) => {
    let totalQuantity = currentWO?.warehouseInventoryData?.reduce(
      (prev: number, current: any) => prev + Number(current?.quantity),
      0
    );
    let totalUOMQuantity = currentWO?.warehouseInventoryData?.reduce(
      (prev: number, current: any) => prev + Number(current?.quantity),
      0
    );
    if (currentWO.product.advancedTracking === ADVANCE_TRACKING.NONE) {
      let invData = currentWO.warehouseInventoryData.map((ele: any) => ({
        ...ele,
        ...defaultWarehouseForCompleteWo
      }));
      currentWO = {
        ...currentWO,
        warehouseInventoryData: invData
      };
    }

    if (
      currentWO.product.advancedTracking === ADVANCE_TRACKING.BATCH ||
      currentWO.product.advancedTracking === ADVANCE_TRACKING.SERIAL
    ) {
      let advData = currentWO.advancedTrackingData?.map((ele: any) => ({
        ...ele,
        ...defaultWarehouseForCompleteWo,
        row: {
          name: defaultWarehouseForCompleteWo?.rowName,
          code: defaultWarehouseForCompleteWo?.rowCode
        },
        rack: {
          name: defaultWarehouseForCompleteWo?.rackName,
          code: defaultWarehouseForCompleteWo?.racCode
        },
        bin: {
          name: defaultWarehouseForCompleteWo?.binName,
          code: defaultWarehouseForCompleteWo?.binCode
        }
      }));
      let invData = currentWO.warehouseInventoryData.map((ele: any) => ({
        ...ele,
        ...defaultWarehouseForCompleteWo,
        row: {
          name: defaultWarehouseForCompleteWo?.rowName,
          code: defaultWarehouseForCompleteWo?.rowCode
        },
        rack: {
          name: defaultWarehouseForCompleteWo?.rackName,
          code: defaultWarehouseForCompleteWo?.racCode
        },
        bin: {
          name: defaultWarehouseForCompleteWo?.binName,
          code: defaultWarehouseForCompleteWo?.binCode
        }
      }));
      currentWO = {
        ...currentWO,
        advancedTrackingData: advData,
        warehouseInventoryData: invData
      };
    }
    return currentWO;
  };

  /*************POPUP RENDERERS************
   * **************************************
   * **************************************
   */
  const getWorkOrderCompletePopup = () => {
    return (
      <CompleteWorkOrderPopup
        workOrder={activeTabWorkOrder}
        isReadOnlyMode={isReadOnlyMode}
        canValidate={canValidate}
        showWarehouseInventoryPopup={showWarehouseInventoryPopup}
        invalidActualEndDate={invalidActualEndDate}
        onStatusUpdateClick={() => onStatusUpdateClick()}
        onUpdateActiveWOKey={(key: string, value: any) =>
          updateActiveWorkOrderDataKey(key, value)
        }
        onCommonStateUpdate={(key: woSliceStateKeys, value: any) => {
          updateCommonState({ [key]: value });
        }}
        onChangeDefaultWarehouse={(value: any) => {
          setDefaultWarehouseForWO(value);
        }}
        onClose={() => updateCommonState({ showCompleteWorkOrderPopup: false })}
        wasteManagementGrid={
          isWasteManagementGridVisible(activeTabWorkOrder?.workOrderItems) &&
          !hasConsumptionEntries
            ? getWasteManagementGrid(true)
            : null
        }
      />
    );
  };

  const getExplosionPopupView = () => {
    return (
      <BomExplosionPopup
        activeWorkOrder={activeTabWorkOrder}
        jwoList={jwoDetails}
        existingRows={pristineWorkOrderData?.[activeTabIndex]?.workOrderItems}
        isEditMode={isEditMode}
        isReadOnlyMode={isReadOnlyMode}
        payloadForCurrentWO={getPayloadForWorkOrder([...workOrders])}
        hasProductionEntry={hasProductionEntry}
        handleDetailOpenerOnLinkedRecordTap={(detailOpenerData: any) => {
          /**
           * @todo To move details opener component inside BomExplosion only..,
           * so this callback & state: detailsPopupData props will get removed..
           */
          updateCommonState({
            detailsPopupData: detailOpenerData
          });
        }}
        checkIfProductConsumed={checkIfProductConsumed}
        onBOMPOPRExists={(value: boolean) => setIsRequisitionPending(value)}
        updateLinkedDocs={onAddNewLinkedDocumentFromBomExplosion}
        woCreateCallback={onWorkOrderCreatedFromRawMaterial}
        handleWOCreationInNewMode={handleChildWorkOrderCreationInNewMode}
        onSave={onSaveBomDetailsFromBomExplosion}
        onClose={(needRefresh: any) => onCloseBomExplosionView(needRefresh)}
        forcefullyCloseWO={handleWorkOrderCloseFromBomExplosion}
        closeAndOpenInEditMode={(response: any) => {
          props.continueInEditMode?.(response, activeTabIndex);
        }}
      />
    );
  };

  const targetWarehouseTrackingDetailsUpdated = (
    updatedAdvancedTrackingData: any,
    advancedTrackingCompleted: boolean,
    type: ADVANCE_TRACKING
  ) => {
    if (type === ADVANCE_TRACKING.NONE) {
      updateActiveWorkOrderDataKeys({
        warehouseInventoryData: updatedAdvancedTrackingData,
        advancedTrackingCompleted: advancedTrackingCompleted
      });
    } else {
      updateActiveWorkOrderDataKeys({
        advancedTrackingData: updatedAdvancedTrackingData,
        advancedTrackingCompleted: advancedTrackingCompleted
      });
    }
    updateCommonState({
      showWarehouseInventoryPopup: false
    });
  };

  const getWarehouseInventoryPopup = () => {
    let currentWO = activeTabWorkOrder;
    if (
      showCompleteWorkOrderPopup &&
      Utility.isRRBTaggingEnabled() &&
      Utility.isNotEmpty(defaultWarehouseForCompleteWo) &&
      !isInventoryDataChanged
    ) {
      currentWO = processDefaultWhData(currentWO);
    }
    return (
      <>
        {showWarehouseInventoryPopup && (
          <GetReceivePopupForTargetWarehouseWO
            workOrderObject={{ ...currentWO }}
            onClose={() => {
              updateCommonState({
                showWarehouseInventoryPopup: false
              });
            }}
            onNoneSave={(updatedWarehouseInventoryData: any) => {
              setIsInventoryDataChanged(true);
              targetWarehouseTrackingDetailsUpdated(
                updatedWarehouseInventoryData,
                true,
                ADVANCE_TRACKING.NONE
              );
            }}
            onSerialSave={(updatedAdvancedTrackingData: any) => {
              setIsInventoryDataChanged(true);
              targetWarehouseTrackingDetailsUpdated(
                updatedAdvancedTrackingData,
                true,
                ADVANCE_TRACKING.SERIAL
              );
            }}
            onBatchSave={(advanceTrackingData: any) => {
              setIsInventoryDataChanged(true);
              targetWarehouseTrackingDetailsUpdated(
                advanceTrackingData,
                true,
                ADVANCE_TRACKING.BATCH
              );
            }}
            showCompleteWorkOrderPopup={showCompleteWorkOrderPopup}
            defaultWarehouseForCompleteWo={defaultWarehouseForCompleteWo}
          />
        )}
      </>
    );
  };

  /****************RENDERERS***************
   * **************************************
   * **************************************
   */

  const getHeader = () => {
    return (
      <WorkOrderHeader
        activeTabIndex={activeTabIndex}
        workOrders={[...workOrders]}
        pristineWorkOrderData={[...pristineWorkOrderData]}
        workOrderObject={{ ...activeTabWorkOrder }}
        jwoDetails={jwoDetails}
        jobCardList={jobCardListResponse}
        detailsOpenerData={detailsPopupData}
        hasConsumptionEntries={hasConsumptionEntries}
        isEditMode={isEditMode}
        isReadOnlyMode={isReadOnlyMode}
        isCopyMode={isCopyMode}
        updating={updating}
        loading={loading}
        workOrderFromParentProps={props.workOrder}
        isReadOnlyFromParentProps={props.isReadOnly}
        showWOLinkedRecordsPopup={showWOLinkedRecordsPopup}
        onSetCanValidate={(validate: boolean) => {
          setCanValidate(validate);
          setIsCompleteWorkOrderClicked(true);
        }}
        onStatusUpdateClick={onStatusUpdateClick}
        onCommonStateUpdate={(key: woSliceStateKeys, value: any) => {
          updateCommonState({ [key]: value });
        }}
        onUpdateActiveWOKey={(key: string, value: any) =>
          updateActiveWorkOrderDataKey(key, value)
        }
        updateActiveWOKeyAndSaveWO={(key: string, value: any) => {
          updateActiveWorkOrderDataKey(key, value);
          onStatusUpdateClick({ ...activeTabWorkOrder, workOrderItems: value });
        }}
        onCloseDetailsOpener={() => {
          updateCommonState({ detailsPopupData: null });
        }}
        onClose={() =>
          props.onClose?.(isInnerDocumentRelatedToCurrentWOSaved ? false : true)
        }
        onSave={(
          partialSave?: boolean | undefined,
          ignoreStatus?: boolean | undefined,
          isLinkedRecord?: boolean | undefined,
          validateReserveQuantity?: boolean | undefined
        ) => {
          onSave(workOrders, partialSave, ignoreStatus, isLinkedRecord, true);
        }}
        onWOStatusChange={(res: any) => props.onSave?.(res)}
      />
    );
  };

  const getWorkOrderDetails = () => {
    return (
      <WorkOrderDetails
        workOrder={activeTabWorkOrder}
        activeTabIndex={activeTabIndex}
        isEditMode={isEditMode}
        isCopyMode={isCopyMode}
        canValidate={canValidate}
        focusedField={focusedField}
        invalidPlannedEndDate={invalidPlannedEndDate}
        invalidPlannedStartDate={invalidPlannedStartDate}
        isReadOnlyMode={isReadOnlyMode || loading}
        oldPlannedQtyInState={oldPlannedQtyInState}
        showCompleteWorkOrderPopup={showCompleteWorkOrderPopup}
        showWarehouseInventoryPopup={showWarehouseInventoryPopup}
        salesOrder={salesOrder}
        salesInvoice={salesInvoice}
        workOrderProducts={workOrderProducts.current}
        productImageDetails={productImageDetails}
        jobCardList={jobCardListResponse}
        onCommonStateUpdate={(key: woSliceStateKeys, value: any) => {
          updateCommonState({ [key]: value });
        }}
        onForceUpdateJobCardList={() => refreshJobCardDetails()}
        onSetActiveTabIndex={(updatedIndex: number) =>
          setActiveTabIndex(updatedIndex)
        }
        onSalesOrderUpdate={onChangeSalesOrder}
        onSalesInvoiceUpdate={onChangeSalesInvoice}
        onUpdateWorkOrderKeys={(updatedKeys: any) => {
          updateActiveWorkOrderDataKeys(updatedKeys);
        }}
      />
    );
  };

  const getJobCardListSection = () => {
    return (
      <JobCardList
        jobCards={jobCardListResponse}
        workOrders={workOrders}
        activeTabIndex={activeTabIndex}
        componentProductShortInfoList={componentProductShortInfoList}
        operationDetails={operationListResponse || []}
        addNewJCCallBack={(workOrderArrUpdatedWithNewJC: any) => {
          let payload: any[] = getPayloadForWorkOrder(
            workOrderArrUpdatedWithNewJC
          );
          const [workOrder] = payload;
          WorkOrderService.updateWorkOrder(workOrder, props.workOrder?.id)
            ?.then((res: any) => {
              props.continueInEditMode?.(res as IWorkOrder[], activeTabIndex);
            })
            .catch((err: any) => {});
        }}
        onWOSaveFromJCList={() => {
          onSave([...workOrders]);
          refreshJobCardDetails();
        }}
        onRowChange={() => {
          onSave([...workOrders], true, true);
          refreshJobCardDetails();
        }}
        workOrderData={activeTabWorkOrder}
        isReadOnly={
          props.isReadOnly ||
          !checkUserPermission(PERMISSIONS_BY_MODULE.JOB_CARD.EDIT)
        }
        isEditMode={isEditMode}
      />
    );
  };

  const getOperationsGrid = () => {
    return (
      <OperationsList
        isEditMode={isEditMode}
        workOrderData={activeTabWorkOrder}
        operationIdToJobMappingData={operationIdToJobMappingData}
        selectedOperatorsByProductId={selectedOperatorsByProductId}
        isReadOnlyMode={isReadOnlyMode || !hasWOCreateEditPermission}
        onOperationsChange={(updatedOperations: IWorkOrderOperation[]) =>
          updateActiveWorkOrderDataKey('workOrderOperations', updatedOperations)
        }
        updateSelectedOperators={(selectedOperators: {
          [key: string]: any[];
        }) =>
          updateCommonState({
            selectedOperatorsByProductId: selectedOperators
          })
        }
      />
    );
  };

  const getJobWorkoutGrid = () => {
    return (
      <JobWorkoutWOList
        isEditMode={isEditMode}
        rows={jwoDetails}
        isReadOnlyMode={
          !checkUserPermission(PERMISSIONS_BY_MODULE.JOB_WORKOUTS.EDIT)
        }
      />
    );
  };

  const addNewScrapProduct = () => {
    let updatedWorkOrderItems = [...(activeTabWorkOrder?.workOrderItems || [])];
    let newRow = getAdhocRawMaterialRow({
      workOrderData: activeTabWorkOrder,
      produceProductType: PRODUCE_PRODUCT_TYPE.SCRAP
    });
    updatedWorkOrderItems.push(newRow);
    updateActiveWorkOrderDataKey('workOrderItems', updatedWorkOrderItems);
  };

  const onDeleteScrapProduct = (sequenceNumber: number) => {
    let woItemList = [...activeTabWorkOrder?.workOrderItems];
    let indexToRemove = woItemList?.findIndex(
      (item: any) => item?.sequenceNumber === sequenceNumber
    );
    woItemList.splice(indexToRemove, 1);
    updateActiveWorkOrderDataKey('workOrderItems', woItemList);
  };

  const getWasteManagementGrid = (isConfirmFlow = false) => {
    return (
      <WasteManagementList
        workOrderData={activeTabWorkOrder}
        isEditMode={isEditMode}
        isReadOnlyMode={isReadOnlyMode}
        isConfirmFlow={isConfirmFlow}
        onBatchSave={onBatchOrSerialSave}
        onSerialSave={onBatchOrSerialSave}
        onRowChange={onMaterialOrWasteRowUpdated}
        onNormalProductSave={onWasteManagementNormalProductSave}
        updateRowForCompleteOrder={onUpdateWasteManagementGridRowForCompletion}
        onClickNewScrapProduct={addNewScrapProduct}
        onDeleteScrapProductRow={onDeleteScrapProduct}
        onChangeCostInclusionFlag={() =>
          updateActiveWorkOrderDataKey(
            'includeByProductCost',
            !activeTabWorkOrder.includeByProductCost
          )
        }
      />
    );
  };

  const addNewRowForRawMaterial = () => {
    let updatedWorkOrderItems = [...(activeTabWorkOrder?.workOrderItems || [])];
    let newRow = getAdhocRawMaterialRow({
      workOrderData: activeTabWorkOrder,
      produceProductType: PRODUCE_PRODUCT_TYPE.NONE
    });
    updatedWorkOrderItems.push(newRow);
    updateActiveWorkOrderDataKey('workOrderItems', updatedWorkOrderItems);
  };

  const onDeleteMaterialRow = (sequenceNumber: number) => {
    let woItemList = [...activeTabWorkOrder?.workOrderItems];
    let indexToRemove = woItemList?.findIndex(
      (item: any) => item?.sequenceNumber === sequenceNumber
    );
    woItemList.splice(indexToRemove, 1);
    updateActiveWorkOrderDataKey('workOrderItems', woItemList);
  };

  let hasWOCreateEditPermission = isEditMode
    ? checkUserPermission(PERMISSIONS_BY_MODULE.WORK_ORDER.EDIT)
    : checkUserPermission(PERMISSIONS_BY_MODULE.WORK_ORDER.CREATE);

  const getRawMaterialGrid = () => {
    return (
      <RawMaterialList
        isEditMode={isEditMode}
        isCompleteWorkOrderClicked={isCompleteWorkOrderClicked}
        hasProductionEntry={hasProductionEntry}
        checkIfProductConsumed={checkIfProductConsumed}
        workOrderData={activeTabWorkOrder}
        existingRows={pristineWorkOrderData?.[activeTabIndex]?.workOrderItems}
        isReadOnlyMode={isReadOnlyMode || loading || !hasWOCreateEditPermission}
        onRowChange={onMaterialOrWasteRowUpdated}
        onSerialSave={onBatchOrSerialSave}
        onBatchSave={onBatchOrSerialSave}
        onNormalProductSave={onRawMaterialNormalProductSave}
        onSubstituteSave={onRawMaterialSubstituteSave}
        onStockTransfer={() => refreshProductShortInfoForWorkOrders()}
        woCreateCallback={onWorkOrderCreatedFromRawMaterial}
        onRequestBomExplosionView={() =>
          setShowBOMExplosionPopup(!showBOMExplosionPopup)
        }
        onClickNewMaterial={() => addNewRowForRawMaterial()}
        onDeleteMaterialRow={(rowIndex: number) =>
          onDeleteMaterialRow(rowIndex)
        }
      />
    );
  };

  const getConsumptionProductionGrid = () => {
    return (
      <WIPConsumeProduceList
        isEditMode={isEditMode}
        isReadOnlyMode={isReadOnlyMode}
        workOrderData={activeTabWorkOrder}
        consumptionSummaryResponse={consumptionSummaryResponse}
        refreshConsumptionSummary={refreshConsumptionSummaryForCurrentWorkOrder}
      />
    );
  };

  const onBOMDeleteRow = (rowData: any) => {
    let woBOMCostList = [...activeTabWorkOrder?.bomAddCostConfiguration];
    let listIndex = woBOMCostList.findIndex(
      (woBomData: any) =>
        woBomData.rowSequenceNumber === rowData.rowSequenceNumber
    );
    if (listIndex !== -1) {
      woBOMCostList.splice(listIndex, 1);
      updateActiveWorkOrderDataKey('bomAddCostConfiguration', woBOMCostList);
    }
  };

  const onRowNameUpdated = (rowData: any) => {
    let woBOMCostList = [...activeTabWorkOrder?.bomAddCostConfiguration];
    let listIndex = woBOMCostList.findIndex(
      (woBomData: any) =>
        woBomData.rowSequenceNumber === rowData.rowSequenceNumber
    );
    if (listIndex !== -1) {
      woBOMCostList[listIndex] = { ...rowData, label: rowData.name };
      updateActiveWorkOrderDataKey('bomAddCostConfiguration', woBOMCostList);
    }
  };

  const addNewRowForAdditionalCharge = () => {
    let woBOMCostList = [...activeTabWorkOrder?.bomAddCostConfiguration];
    let newRow = {
      additionalCostType: 'BOM',
      chargeValue: 0,
      name: '',
      isNewRow: true,
      rowSequenceNumber: getRandomNumber(999999),
      invalidFields: [
        WO_ADDITIONAL_CHARGES_KEYS.NAME,
        WO_ADDITIONAL_CHARGES_KEYS.CHARGE_VALUE
      ]
    };
    woBOMCostList.push(newRow);
    updateActiveWorkOrderDataKey('bomAddCostConfiguration', woBOMCostList);
  };

  const getAdditionalChargesGrid = () => {
    return (
      <WorkOrderAdditionalCharges
        currentTabWO={activeTabWorkOrder}
        isEditMode={isEditMode}
        jobCardsDetails={jobCardListResponse ?? []}
        isReadOnlyMode={isReadOnlyMode}
        totalCost={totalCostCalculation(
          activeTabWorkOrder,
          operationIdToJobMappingData,
          jobCardListResponse
        )}
        onChargesUpdated={(updatedCharges: any[]) => {
          let updateAdditionalCharges =
            additionalBOMAdditionalCostCalculate(updatedCharges);
          setAdditionalCharges(updateAdditionalCharges);
        }}
        onClickNewAdditionalCharge={addNewRowForAdditionalCharge}
        onNameUpdated={onRowNameUpdated}
        onBOMDeleteRow={onBOMDeleteRow}
      />
    );
  };

  const getAttachmentSection = () => {
    return (
      <WOAttachments
        key={`${activeTabWorkOrder?.product?.id}-${activeTabIndex}`}
        workOrderData={activeTabWorkOrder}
        isReadOnlyMode={isReadOnlyMode}
        isEditMode={isEditMode}
        isCopyMode={isCopyMode}
        onAttachmentChange={(ids: any) => {
          updateActiveWorkOrderDataKey('attachments', ids);
        }}
      />
    );
  };

  const getCustomFieldSection = () => {
    return (
      <WOCustomField
        isEditMode={isEditMode}
        isReadOnlyMode={isReadOnlyMode}
        onCFUpdates={(cfList: any) => {
          if (
            JSON.stringify(activeTabWorkOrder?.customField) !==
            JSON.stringify(cfList)
          ) {
            updateActiveWorkOrderDataKey('customField', cfList);
          }
        }}
        customFields={activeTabWorkOrder?.customField ?? []}
        currentActiveTabIndex={activeTabIndex}
      />
    );
  };

  const renderActivePanel = () => {
    return (
      <div
        className="column parent-width p-s position-relative align-items-start hide-scroll-bar overflow-x-scroll gap-2 flex-1"
        style={{
          opacity: Utility.isEmptyObject(activeTabWorkOrder) ? 0.7 : 1,
          pointerEvents: Utility.isEmptyObject(activeTabWorkOrder)
            ? 'none'
            : 'auto'
        }}
      >
        <CostingHeader
          workOrder={activeTabWorkOrder}
          jwoDetails={jwoDetails || []}
          jobCardsDetails={jobCardListResponse ?? []}
          operationIdToJobMappingData={operationIdToJobMappingData}
          selectedOperators={
            selectedOperatorsByProductId[
              activeTabWorkOrder?.product?.productId
            ] || []
          }
        />
        {isEditMode ? (
          <div
            className="row align-items-stretch justify-content-around gap-2 overflow-visible flex-wrap"
            style={{ columnGap: '0.5%' }}
          >
            {getWorkOrderDetails()}
            {getJobCardListSection()}
            {getRawMaterialGrid()}
            {getOperationsGrid()}
            {getConsumptionProductionGrid()}
            {getAdditionalChargesGrid()}
            {isWasteManagementGridVisible(activeTabWorkOrder?.workOrderItems) &&
              getWasteManagementGrid()}
            {jwoDetails.length > 0 && getJobWorkoutGrid()}
            {getAttachmentSection()}
            {getCustomFieldSection()}
          </div>
        ) : (
          <div
            className="row align-items-stretch justify-content-around gap-2 overflow-visible"
            style={{ columnGap: '0.5%' }}
          >
            {getWorkOrderDetails()}
            <div
              className="column gap-2"
              style={{ width: '59%', flexShrink: 0 }}
            >
              {getRawMaterialGrid()}
              {isWasteManagementGridVisible(
                activeTabWorkOrder?.workOrderItems
              ) && getWasteManagementGrid()}
              {getOperationsGrid()}
              {getAdditionalChargesGrid()}
              {getAttachmentSection()}
              {getCustomFieldSection()}
            </div>
          </div>
        )}
      </div>
    );
  };

  const tabRenderer = (tab: IWorkOrder, index: any): ReactNode => {
    const docSequenceCode = tab.product?.documentSequenceCode || '-';
    return (
      <>
        <div
          key={`tab-${index}`}
          style={{
            height: 40
          }}
          className={`dk-tab d-flex align-items-center justify-content-between p-h-l text-align-center cursor-hand white-space-nowrap parent-height ${
            activeTabIndex === index
              ? 'fw-m dk-tab-panel-active-tab text-app-color'
              : 'text-dark-gray'
          } ${GOOGLE_NO_TRANSLATE_CLASS}`}
          onClick={() => setActiveTabIndex(index)}
        >
          {`${tab?.productName} (${docSequenceCode})`}
          {workOrders?.length > 1 &&
            !isEditMode &&
            noLinkedSalesOrder &&
            noLinkedSalesInvoice && (
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  showProductRemoveWarning(tab);
                }}
              >
                <DKIcon
                  className="ic-s close-icon ml-r cursor-hand"
                  src={DKIcons.ic_close}
                />
              </div>
            )}
        </div>
      </>
    );
  };

  const renderTabPanel = () => {
    const allowAdditionalBoms =
      !isEditMode && noLinkedSalesOrder && noLinkedSalesInvoice;

    return (
      !Utility.isEmpty(workOrders) && (
        <div className="column parent-width flex-1">
          <DKTabPanel
            tabs={workOrders}
            activeTabIndex={activeTabIndex}
            onTabSelect={(tab: any, index: number) => setActiveTabIndex(index)}
            refreshDataOnTabSelect={() =>
              loadBatchTrackingData(activeTabWorkOrder?.product)
            }
            getActivePanel={renderActivePanel}
            tabRenderer={tabRenderer}
            allowAdditionalBoms={allowAdditionalBoms}
            onAddProduct={() => {
              setShowBOMListPicker(true);
            }}
          />
        </div>
      )
    );
  };

  return (
    <DynamicPopupWrapper>
      <div className="transparent-background">
        <div
          className="popup-window web-width-70"
          key={workOrderInstanceKey?.current}
          style={{
            maxWidth: '95%',
            width: '95%',
            maxHeight: '100%',
            height: '100%',
            padding: 0,
            backgroundColor: 'rgb(247, 247, 247)',
            overflow: 'hidden',
            borderRadius: 0
          }}
        >
          {getHeader()}
          <div className="column parent-size overflow-y-scroll hide-scroll-bar">
            <WorkOrderBomSelectionView
              loading={loading}
              isEditMode={isEditMode}
              workOrders={workOrders}
              salesOrder={salesOrder}
              salesInvoice={salesInvoice}
              bomProductDetailsList={bomProductDetailsList}
              needBomSelectionView={needBomSelection}
              preSelectedBOM={props.preSelectedBOM}
              showBOMListPicker={showBOMListPicker}
              onCloseBomListPicker={() => setShowBOMListPicker(false)}
              onMaterialSelected={(materialData) => {
                onBomSelection({
                  state,
                  newBomProductList: materialData,
                  dispatch: woDispatch
                });
              }}
              onSelectSalesOrder={onChangeSalesOrder}
              onSelectSalesInvoice={onChangeSalesInvoice}
              canValidate={canValidate}
            />
            {Utility.isNotEmpty(workOrders) && renderTabPanel()}
          </div>
        </div>
        {getWarehouseInventoryPopup()}
        {showCompleteWorkOrderPopup && getWorkOrderCompletePopup()}
        {showBOMExplosionPopup && getExplosionPopupView()}
      </div>
    </DynamicPopupWrapper>
  );
};

export default AddNewWorkOrder;
