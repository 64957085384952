import {
  DKButton,
  DKCheckMark,
  DKInput,
  DKLabel,
  DKRadioButton,
  DKSpinner,
  INPUT_TYPE,
  Toggle,
  removeLoader,
  showAlert,
  showLoader
} from 'deskera-ui-library';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  BANK_FILE_GENERATION_TYPE,
  BANK_FILE_GENERATION_TYPE_OPTIONS,
  DOCUMENT_TYPE,
  DOC_TYPE,
  INPUT_VIEW_DIRECTION,
  MRP_APP_NAME,
  POPUP_CALLBACKS_TYPE,
  POPUP_CLICK_TYPE,
  POPUP_TYPE
} from '../../../Constants/Constant';
import { SETTING_TYPE } from '../../../Constants/Enum';
import { FEATURE_PERMISSIONS } from '../../../Constants/Permission';
import {
  COLUMN_CODE,
  REMOTE_CONFIG_TABLES
} from '../../../Constants/TableConstants';
import { TableManager } from '../../../Managers/TableManager';
import {
  BtnType,
  CallBackPayloadType,
  PopupClickActionType,
  UpdateCallBacksRefType
} from '../../../Models/Interfaces';
import { useAppDispatch, useAppSelector } from '../../../Redux/Hooks';
import {
  activeTenantInfo,
  appCustomizationInfo,
  featurePermissions,
  fetchAppCustomizationInfo,
  fetchCurrentTenantInfo,
  subscriptionInfo
} from '../../../Redux/Slices/AuthSlice';
import { fetchapprovalConditionList } from '../../../Redux/Slices/AutomationSlice';
import { updateShowArchivedFlag } from '../../../Redux/Slices/MRP/WorkOrderSlice';
import { Store } from '../../../Redux/Store';
import AuthService from '../../../Services/Auth';
import AutomationService from '../../../Services/Automation';
import CustomFieldService from '../../../Services/CustomField';
import WorkOrderService from '../../../Services/MRP/WorkOrder';
import NumberFormatService from '../../../Services/NumberFormat';
import PickPackShipService from '../../../Services/PickPackShip';
import TenantService from '../../../Services/Tenant';
import { ADDITIONAL_CHARGE_METHODS } from '../../../SharedComponents/AdditionalCharges/AdditionalCharges';
import PopupWrapper from '../../../SharedComponents/PopupWrapper';
import Utility, { deepClone } from '../../../Utility/Utility';
import {
  AUTOMATION_ACTIONS,
  AUTOMATION_TRIGGERS,
  Templates
} from '../../Automation/AutomationConstants';
import FTPConfigurationPopup from './FTPConfiguration';
import RFQSettings from './RFQ/RFQSettings';
import RowRackBinGridPopup from './RowRackBinGridPopup';
import WOAdhocBOMSetting from './WOAdhocBOMSetting';
import SettingsDetailCard from '../SettingsDetailCard';
import LabelWithToggle from './LabelWithToggle';
import { AdvancedInventoryPopup } from '../AdvancedInventory/AdvancedInventoryPopup';
import CascadingPopup from './CascadingPopup';
import AdvancedSettingsConfirmationPopup from './AdvancedSettingsConfirmationPopup';
import { STATUS_OPTIONS } from '../BackDatedSettings/BackDateConstants';
let globalIsBillEditOn = false;
export const enum ADDITIONAL_CHARGE_SETTING {
  INDIVIDUAL_CHARGES = 'INDIVIDUAL_CHARGES',
  APPORTION_BY_LINE_ITEM_LEVEL = 'APPORTION_BY_LINE_ITEM_LEVEL',
  APPORTION_BY_VALUE = 'APPORTION_BY_VALUE',
  APPORTION_BY_QTY = 'APPORTION_BY_QTY',
  MANUAL = 'MANUAL'
}

export const enum WORK_ORDER_PR_PO {
  PURCHASE_ORDER = 'PURCHASE_ORDER',
  PURCHASE_REQUISITION = 'PURCHASE_REQUISITION',
  PURCHASE_REQUEST = 'PURCHASE_REQUEST',
  WORK_ORDER = 'WORK_ORDER'
}

export const enum REV_REC_CREATION_SETTING {
  AUTOMATIC = 'AUTOMATIC',
  MANUAL = 'MANUAL'
}

export const enum MACHINE_MATERIAL_PR_PO {
  PURCHASE_ORDER = 'PURCHASE_ORDER',
  PURCHASE_REQUISITION = 'PURCHASE_REQUISITION'
}

export const enum FORECAST_METHOD_SETTING {
  DEFAULT = 'DEFAULT',
  OUTGOING_QTY_DRIVEN = 'OUTGOING_QTY_DRIVEN'
}

export const enum COMPONENT_LIST_DETAILS_FOR_FG {
  COMPONENT_LIST_ENABLE = 'COMPONENT_LIST_ENABLE',
  COMPONENT_LIST_TABLE_NAME = 'COMPONENT_LIST_TABLE_NAME',
  COMPONENT_LIST_OPERATIONS_ENABLE = 'COMPONENT_LIST_OPERATIONS_ENABLE',
  COMPONENT_LIST_OPERATIONS_TABLE_NAME = 'COMPONENT_LIST_OPERATIONS_TABLE_NAME',
  COMPONENT_LIST_ADD_CHARGES_ENABLE = 'COMPONENT_LIST_ADD_CHARGES_ENABLE',
  COMPONENT_LIST_ADD_CHARGES_TABLE_NAME = 'COMPONENT_LIST_ADD_CHARGES_TABLE_NAME'
}

interface ModulesState {
  multipleWarehousesCheck: boolean;
  priceList: boolean;
  ewayBill: boolean;
  pps: boolean;
  salesOrder: boolean;
  machineMaintenance: boolean;
  fixedAsset: boolean;
  revenueRecognition: boolean;
  amortization: boolean;
  expenseAllocation: boolean;
  rateAnalysis: boolean;
  labelPrint: boolean;
  trackingDetailsInSO: boolean;
}

const AdvancedSettings = (props: any) => {
  const initialState: ModulesState = {
    multipleWarehousesCheck: true,
    priceList: true,
    ewayBill: true,
    pps: true,
    salesOrder: false,
    machineMaintenance: false,
    fixedAsset: false,
    revenueRecognition: false,
    amortization: false,
    expenseAllocation: false,
    rateAnalysis: false,
    labelPrint: false,
    trackingDetailsInSO: false
  };

  const initialStateAdvInvTracking = { showPopup: false, toggleEnabled: false };

  const initialAdditionalChargeState = {
    individualCharge: true,
    apportionLineItemLevel: false,
    apportionLineItemLevelSetting: {
      APPORTION_ON_VALUE: false,
      APPORTION_ON_QTY: false,
      APPORTION_MANUAL: false
    }
  };

  const refInitialState: UpdateCallBacksRefType = {
    pushDataToParent: { type: POPUP_CALLBACKS_TYPE.NONE },
    storeCallbacksRef: {
      createProduct: 'click'
    }
  };

  //state for modules
  const [module, setModule] = useState<ModulesState>(initialState);

  //state for advanced inventory tracking
  const [advancedInventoryState, setAdvancedInventoryState] = useState(
    initialStateAdvInvTracking
  );

  // state for additional charges
  const [additionalChargeState, setAdditionalChargeState] = useState(
    initialAdditionalChargeState
  );

  // state for additional charges for WO short shortfall
  const [globalWorkOrderForPoPr, setGlobalWorkOrderForPoPr] = useState(
    WORK_ORDER_PR_PO.PURCHASE_ORDER
  );

  const [globalMachineMaterialForPoPr, setGlobalMachineMaterialForPoPr] =
    useState(MACHINE_MATERIAL_PR_PO.PURCHASE_ORDER);

  //state for negative inventory setting
  const [isNegativeInventoryEnabled, setIsNegativeInventoryEnabled] =
    useState(false);
  const [showConfirmBox, setShowConfirmBox] = useState(false);
  const [isDesktop, setIsDesktop] = useState(false);

  //state for negative inventory setting
  const [languageSelectorVisible, setLanguageSelectorVisible] = useState(false);
  const [isMultipleGlobalDiscountEnabled, setIsMultipleGlobalDiscountEnabled] =
    useState(false);
  const [includeWoQty, setIncludeWoQty] = useState(false);
  const [displayAdditionalDetails, setDisplayAdditionalDetails] =
    useState(false);
  const [isCascadingDiscountEnabled, setIsCascadingDiscountEnabled] =
    useState(false);
  const [isPriceBookEnabled, setIsPriceBookEnabled] = useState(false);
  const [isProductGroupingEnabled, setIsProductGroupingEnabled] =
    useState(false);
  const [isBlockedWOEnabled, setIsBlockedWOEnabled] = useState(false);
  const [isReserveStockWOforSOEnabled, setIsReserveStockWOforSOEnabled] =
    useState(false);
  const [isReserveStockWOforPOEnabled, setIsReserveStockWOforPOEnabled] =
    useState(false);
  const [
    isWorkOrderWarehouseTaggingEnabled,
    setIsWorkOrderWarehouseTaggingEnabled
  ] = useState(false);
  const [isWorkOrderRRBTaggingEnabled, setIsWorkOrderRRBTaggingEnabled] =
    useState(false);
  const [
    isWorkOrderProcessManufacturingEnabled,
    setIsWorkOrderProcessManufacturingEnabled
  ] = useState(false);
  const [isWOAutoAllocationEnabled, setIsWOAutoAllocationEnabled] =
    useState(false);
  const [
    checkIncomingStockForReorderLevel,
    setCheckIncomingStockForReorderLevel
  ] = useState(false);
  const [checkDeniedParties, setCheckDeniedParties] = useState(false);
  const [
    showWorkOrderWarehouseTaggingConfirmation,
    setShowWorkOrderWarehouseTaggingConfirmation
  ] = useState(false);
  const [
    showWorkOrderRRBTaggingConfirmation,
    setShowWorkOrderRRBTaggingConfirmation
  ] = useState(false);
  const [
    showWorkOrderProcessManufacturingConfirmation,
    setShowWorkOrderProcessManufacturingConfirmation
  ] = useState(false);
  const [
    showMultipleGlobalDiscountConfirmBox,
    setShowMultipleGlobalDiscountConfirmBox
  ] = useState(false);
  const [showCascadingDiscountConfirmBox, setShowCascadingDiscountConfirmBox] =
    useState(false);
  const [showPriceBookConfirmation, setShowPriceBookConfirmation] =
    useState(false);
  const [showBlockedWOConfirmBox, setShowBlockedWOConfirmBox] = useState(false);
  const [showProductGroupingConfirmation, setShowProductGroupingConfirmation] =
    useState(false);
  const [componentDetailsForFG, setComponentDetailsForFG] = useState({
    componentDetailsEnable: false,
    componentDetailsGroupName: 'Component List',
    operationDetailsEnable: false,
    operationDetailsGroupName: 'Operations',
    additionalCostDetailsEnable: false,
    additionalCostGroupName: 'Additional Charges'
  });
  const [parentTenantInfo, setParentTenantInfo] = useState<any>();

  //state for filter accounts settings
  const [isShowCashBankCard, setIsShowCashBankCard] = useState(false);
  const [isBillEditOn, setIsBillEditOn] = useState(false);
  const [isInvoiceEditOn, setIsInvoiceEditOn] = useState(false);
  const [isSOEditOn, setIsSOEditOn] = useState(false);
  const [isPOEditOn, setIsPOEditOn] = useState(false);
  const [
    isJWOReceiveDispatchAdditionalQty,
    setIsJWOReceiveDispatchAdditionalQty
  ] = useState(false);
  const [isSOQtyEditOn, setIsSOQtyEditOn] = useState(false);
  const [isConvertedDocEditingOn, setIsConvertedDocEditingOn] = useState(false);
  const [isPOShowReceivedBilledQtyOn, setIsPOShowReceivedBilledQtyOn] =
    useState(false);

  //const [isBillEditOn, setIsBillEditOn] = useState(false);
  const [showConfirmBoxAccSetting, setShowConfirmBoxAccSetting] =
    useState(false);

  //state for row rack bin
  const [showRowRackBinPopup, setShowRowRackBinPopup] = useState(false);
  const [showCascadingPopup, setShowCascadingPopup] = useState(false);

  // state for advanced setting  for Revenue Arrangement Creation
  const [revenueRecognitionOption, setRevenueRecognitionOption] = useState(
    REV_REC_CREATION_SETTING.AUTOMATIC
  );
  const [isToleranceToggleEnabled, setIsToleranceToggleEnabled] =
    useState<boolean>(false);
  const [toleranceSettingsData, setToleranceSettingsData] = useState<any>({
    isPercent: true,
    quantityValue: 0,
    percentValue: 0
  });
  const [isToleranceFocused, setIsToleranceFocused] = useState<boolean>();
  const [toleranceSaveInprogess, setToleranceSaveInProgress] =
    useState<boolean>(false);
  // set setting type being updated to show the loader on specific section.
  const [updatingSetting, setUpdatingSetting] = useState<SETTING_TYPE>(
    SETTING_TYPE.NONE
  );
  const [isBankFileGenerationEnabled, setIsBankFileGenerationEnabled] =
    useState<boolean>(false);
  const [bankFileGenerationTypeOptions, setBankFileGenerationTypeOptions] =
    useState<any>(BANK_FILE_GENERATION_TYPE_OPTIONS);
  const [bankFileGenerationType, setBankFileGenerationType] = useState<any>(
    BANK_FILE_GENERATION_TYPE.CHASE
  );
  //hooks
  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation();
  const data = useAppSelector(appCustomizationInfo);
  const tenantDetails = useAppSelector(activeTenantInfo);
  const fifoEnabled = tenantDetails.implementFIFOForAdvTracking;
  const subscriptionDetails = useAppSelector(subscriptionInfo);

  //refs
  const advancedInventoryRef = useRef<UpdateCallBacksRefType>(refInitialState);
  const featurePermissionsInfo = useAppSelector(featurePermissions);
  const [valuationMethod, setValuationMethod] = useState<any>();
  const [productValuationMethodOption, setProductValuationMethodOption] =
    useState([
      { label: 'FIFO', value: 'FIFO' },
      { label: 'AVERAGE', value: 'AVERAGE' }
    ]);
  const [forecastMethod, setForecastMethod] = useState<any>(
    FORECAST_METHOD_SETTING.DEFAULT
  );
  const [availabilitySelectedIndexes, setAvailabilitySelectedIndexes] =
    useState<any>([]);
  const [
    revRecAvailabilitySelectedIndexes,
    setRevRecAvailabilitySelectedIndexes
  ] = useState<any>([]);
  const [
    poConversionAvailabilitySelectedIndexes,
    setPoConversionAvailabilitySelectedIndexes
  ] = useState<any>([]);
  const [productCustomFieldList, setProductCustomFieldList] = useState<any[]>(
    []
  );
  const [productCustomFieldData, setProductCustomFieldData] = useState<any[]>(
    []
  );
  const [woTaggingThreshold, setWOTaggingThreshold] = useState<any>();
  const [isBinTaggingMandatory, setIsBinTaggingMandatory] = useState(false);
  const [isJEAmountViewSettingOn, setIsJEAmountViewSettingOn] = useState(false);
  const [isJECredtiDebitMethodSettingOn, setIsJECredtiDebitMethodSettingOn] =
    useState(false);
  const [isEntryGateTransactionEnabled, toggleEntryGateTransaction] =
    useState<boolean>(false);

  const [
    isSalesModuleShortfallAlertEnabled,
    setisSalesModuleShortfallAlertEnabled
  ] = useState(false);
  const [showFTPConfigurationPopup, setFTPConfigurationPopup] = useState(false);

  const [
    isBillBasedStockValuationEnabled,
    setIsBillBasedStockValuationEnabled
  ] = useState<boolean>(false);
  const [isBillBasedStockValuationHide, setIsBillBasedStockValuationHide] =
    useState<boolean>(false);
  const [
    isWOSufficientInsufficientQtyEnabled,
    setIsWOSufficientInsufficientQtyEnabled
  ] = useState(false);

  const [isWOArchiveEnabled, setIsWOArchiveEnabled] = useState(false);
  const [isPreferredVendorDetailsEnabled, setIsPreferredVendorDetailsEnabled] =
    useState<boolean>(false);

  //effects
  useEffect(() => {
    //modules
    const currState = module;
    if (data && data.hiddenApps) {
      var str = data.hiddenApps;
      var str_array = str.split(',');
      for (var i = 0; i < str_array.length; i++) {
        str_array[i] = str_array[i].replace(/^\s*/, '').replace(/\s*$/, '');
        if (str_array[i] === 'WAREHOUSE') {
          currState.multipleWarehousesCheck = false;
        }
        if (str_array[i] === 'PRICE_LIST') {
          currState.priceList = false;
        }
        if (str_array[i] === 'E_WAY') {
          currState.ewayBill = false;
        }
        if (str_array[i] === 'PPS') {
          currState.pps = false;
        }
      }
    }
    //advanced inventory tracking
    if (fifoEnabled) {
      const updatedState = advancedInventoryState;
      updatedState.toggleEnabled = true;
      setAdvancedInventoryState({ ...updatedState });
    }

    if (tenantDetails?.additionalSettings?.ENABLE_SO) {
      currState.salesOrder = true;
    }

    if (tenantDetails?.additionalSettings?.ENABLE_MACHINE_MAINTENANCE) {
      currState.machineMaintenance = true;
    }

    if (tenantDetails?.additionalSettings?.ENABLE_FA) {
      currState.fixedAsset = true;
    }

    if (
      tenantDetails?.additionalSettings
        ?.DISPLAY_ADDITIONAL_SETTINGS_WITH_FORECAST
    ) {
      setDisplayAdditionalDetails(
        tenantDetails?.additionalSettings
          ?.DISPLAY_ADDITIONAL_SETTINGS_WITH_FORECAST
      );
    }
    if (tenantDetails?.additionalSettings?.INCLUDE_WO_QTY) {
      setIncludeWoQty(tenantDetails?.additionalSettings?.INCLUDE_WO_QTY);
    }

    if (
      tenantDetails?.additionalSettings
        ?.DISPLAY_ADDITIONAL_SETTINGS_WITH_FORECAST
    ) {
      setDisplayAdditionalDetails(
        tenantDetails?.additionalSettings
          ?.DISPLAY_ADDITIONAL_SETTINGS_WITH_FORECAST
      );
    }
    if (tenantDetails?.additionalSettings?.INCLUDE_WO_QTY) {
      setIncludeWoQty(tenantDetails?.additionalSettings?.INCLUDE_WO_QTY);
    }

    if (tenantDetails?.additionalSettings?.AMORTIZATION) {
      currState.amortization = true;
    }

    if (tenantDetails?.additionalSettings?.REV_REC) {
      currState.revenueRecognition = true;
    }

    if (tenantDetails?.additionalSettings?.ENABLE_RATE_ANALYSIS) {
      currState.rateAnalysis = true;
    }
    if (tenantDetails?.additionalSettings?.TRACKING_DETAILS_IN_SO) {
      currState.trackingDetailsInSO = true;
    }

    if (tenantDetails?.additionalSettings?.ENABLE_EXPENSE_ALLOCATION) {
      currState.expenseAllocation = true;
    }

    if (tenantDetails?.additionalSettings?.LABEL_PRINT) {
      currState.labelPrint = true;
    }

    let valuationMethod =
      tenantDetails?.additionalSettings?.PRODUCT_VALUATION_METHOD;
    let filtered = productValuationMethodOption.filter((item) => {
      return item.value === valuationMethod;
    });
    if (filtered.length > 0) {
      let first = filtered[0];
      setValuationMethod([productValuationMethodOption.indexOf(first)]);
    } else {
      let first = productValuationMethodOption[0];
      setValuationMethod([productValuationMethodOption.indexOf(first)]);
      // return -1;
    }
    if (tenantDetails?.additionalSettings?.AMORTIZATION) {
      currState.amortization = true;
    }

    if (
      tenantDetails?.additionalSettings?.FORECAST_SHORTFALL_METHOD &&
      tenantDetails?.additionalSettings?.FORECAST_SHORTFALL_METHOD ===
        'OUTGOING_QTY_DRIVEN'
    ) {
      setForecastMethod(FORECAST_METHOD_SETTING.OUTGOING_QTY_DRIVEN);
    } else {
      setForecastMethod(FORECAST_METHOD_SETTING.DEFAULT);
    }
    if (
      tenantDetails?.additionalSettings?.BANK_FILE_GENERATION?.enabled &&
      tenantDetails?.additionalSettings?.BANK_FILE_GENERATION
        ?.bankFileGenerationType
    ) {
      setBankFileGenerationType(
        tenantDetails?.additionalSettings?.BANK_FILE_GENERATION
          ?.bankFileGenerationType
      );
    }

    setModule({ ...currState });
    fetchProductCustomFields();
    dispatch(fetchapprovalConditionList(true));
  }, []);

  useEffect(() => {
    let additionalSettings = tenantDetails.additionalSettings;
    let additionalChargeSettiinng =
      tenantDetails?.additionalSettings?.ADDITIONAL_CHARGE;
    let accountSetting = additionalSettings?.ACCOUNT;
    let showCardCashBankAccountsOnly =
      accountSetting?.showCardCashBankAccountsOnly;
    if (!Utility.isEmpty(additionalChargeSettiinng)) {
      let newAdditionalChargeState = { ...additionalChargeState };
      newAdditionalChargeState.individualCharge =
        additionalChargeSettiinng?.independentCharge;
      newAdditionalChargeState.apportionLineItemLevel =
        additionalChargeSettiinng?.apportionFlag;
      newAdditionalChargeState.apportionLineItemLevelSetting = {
        APPORTION_MANUAL:
          additionalChargeSettiinng?.apportionValue ===
          ADDITIONAL_CHARGE_METHODS?.APPORTION_MANUAL
            ? true
            : false,
        APPORTION_ON_QTY:
          additionalChargeSettiinng?.apportionValue ===
          ADDITIONAL_CHARGE_METHODS?.APPORTION_ON_QTY
            ? true
            : false,
        APPORTION_ON_VALUE:
          additionalChargeSettiinng?.apportionValue ===
          ADDITIONAL_CHARGE_METHODS?.APPORTION_ON_VALUE
            ? true
            : false
      };
      setAdditionalChargeState({ ...newAdditionalChargeState });
    }
    setCheckDeniedParties(
      tenantDetails?.additionalSettings?.DENIED_PARTIES_SETTING ?? false
    );
    if (
      !Utility.isEmptyObject(additionalSettings?.WO_STOCK_SHORTFALL_SETTING)
    ) {
      setGlobalWorkOrderForPoPr(additionalSettings?.WO_STOCK_SHORTFALL_SETTING);
    }
    if (
      !Utility.isEmptyObject(
        additionalSettings?.MACHINE_MATERIAL_SHORTFALL_SETTING
      )
    ) {
      setGlobalMachineMaterialForPoPr(
        additionalSettings?.MACHINE_MATERIAL_SHORTFALL_SETTING
      );
    }
    if (!Utility.isEmptyObject(additionalSettings?.REV_REC_CREATION_SETTING)) {
      // @ts-ignore
      setRevenueRecognitionOption(additionalSettings?.REV_REC_CREATION_SETTING);
    }

    if (tenantDetails?.additionalSettings?.INVOICE_EDIT_SETTING) {
      setIsInvoiceEditOn(
        tenantDetails?.additionalSettings?.INVOICE_EDIT_SETTING
      );
    }
    if (tenantDetails?.additionalSettings?.BILL_EDIT_SETTING) {
      setIsBillEditOn(tenantDetails?.additionalSettings?.BILL_EDIT_SETTING);
    }
    if (tenantDetails?.additionalSettings?.PO_EDIT_SETTING) {
      setIsPOEditOn(tenantDetails?.additionalSettings?.PO_EDIT_SETTING);
    }
    if (
      tenantDetails?.additionalSettings?.PO_SHOW_RECEIVED_BILLED_QTY_SETTING
    ) {
      setIsPOShowReceivedBilledQtyOn(
        tenantDetails?.additionalSettings?.PO_SHOW_RECEIVED_BILLED_QTY_SETTING
      );
    }
    if (tenantDetails?.additionalSettings?.SO_EDIT_SETTING) {
      setIsSOEditOn(tenantDetails?.additionalSettings?.SO_EDIT_SETTING);
    }
    if (
      tenantDetails?.additionalSettings?.JE_AMOUNT_SETTING !== null ||
      tenantDetails?.additionalSettings?.JE_AMOUNT_SETTING !== undefined
    ) {
      setIsJEAmountViewSettingOn(
        tenantDetails?.additionalSettings?.JE_AMOUNT_SETTING
      );
    }
    if (
      tenantDetails?.additionalSettings?.JE_AMOUNT_SETTING !== null ||
      tenantDetails?.additionalSettings?.JE_AMOUNT_SETTING !== undefined
    ) {
      setIsJECredtiDebitMethodSettingOn(
        tenantDetails?.additionalSettings?.JE_CREDIT_DEBIT_COLUMN_SETTING
      );
    }
    if (tenantDetails?.additionalSettings?.EDIT_CONVERTED_DOC) {
      setIsConvertedDocEditingOn(
        tenantDetails?.additionalSettings?.EDIT_CONVERTED_DOC
      );
    }
    setIsShowCashBankCard(showCardCashBankAccountsOnly);
    setIsNegativeInventoryEnabled(tenantDetails.allowNegativeInventory);

    if (tenantDetails?.additionalSettings?.MULTIPLE_GLOBAL_DISCOUNT) {
      setIsMultipleGlobalDiscountEnabled(
        tenantDetails?.additionalSettings?.MULTIPLE_GLOBAL_DISCOUNT
      );
    }
    if (tenantDetails?.additionalSettings?.CASCADING_DISCOUNTS) {
      setIsCascadingDiscountEnabled(
        tenantDetails?.additionalSettings?.CASCADING_DISCOUNTS.enable
      );
    }
    if (tenantDetails?.isAdvancePriceListEnabled) {
      setIsPriceBookEnabled(tenantDetails?.isAdvancePriceListEnabled);
    }
    if (tenantDetails?.additionalSettings?.RESERVE_WO_CHILD_QUANTITY) {
      setIsBlockedWOEnabled(
        tenantDetails?.additionalSettings?.RESERVE_WO_CHILD_QUANTITY
      );
    }
    if (tenantDetails?.additionalSettings?.RESERVE_SO_LINKED_WO_QUANTITY) {
      setIsReserveStockWOforSOEnabled(
        tenantDetails?.additionalSettings?.RESERVE_SO_LINKED_WO_QUANTITY
      );
    }
    if (tenantDetails?.additionalSettings?.RESERVE_WO_LINKED_PO_QUANTITY) {
      setIsReserveStockWOforPOEnabled(
        tenantDetails?.additionalSettings?.RESERVE_WO_LINKED_PO_QUANTITY
      );
    }
    // +Add
    if (tenantDetails?.additionalSettings?.ENABLE_WO_WAREHOUSE_TAGGING) {
      setIsWorkOrderWarehouseTaggingEnabled(
        tenantDetails?.additionalSettings?.ENABLE_WO_WAREHOUSE_TAGGING
      );
    }
    if (tenantDetails?.additionalSettings?.ENABLE_WO_WAREHOUSE_BIN_TAGGING) {
      setIsWorkOrderRRBTaggingEnabled(
        tenantDetails?.additionalSettings?.ENABLE_WO_WAREHOUSE_BIN_TAGGING
      );
      setWOTaggingThreshold(
        tenantDetails?.additionalSettings?.BIN_TAGGED_QUANTITY_THRESHOLD ?? 1
      );
      setIsBinTaggingMandatory(
        tenantDetails?.additionalSettings
          ?.ENABLE_COMPONENT_AVAILABILITY_IN_TAGGED_BIN
      );
    }
    if (tenantDetails?.additionalSettings?.LINK_INVENTORY_JOB_CARDS) {
      setIsWorkOrderProcessManufacturingEnabled(
        tenantDetails?.additionalSettings?.LINK_INVENTORY_JOB_CARDS
      );
    }
    if (tenantDetails?.additionalSettings?.WO_AUTO_ALLOCATE_ON_START) {
      setIsWOAutoAllocationEnabled(
        tenantDetails?.additionalSettings?.WO_AUTO_ALLOCATE_ON_START
      );
    }
    if (
      tenantDetails?.additionalSettings
        ?.CHECK_INCOMING_STOCK_TO_ENABLE_REORDER_LEVEL_ALERT
    ) {
      setCheckIncomingStockForReorderLevel(
        tenantDetails?.additionalSettings
          ?.CHECK_INCOMING_STOCK_TO_ENABLE_REORDER_LEVEL_ALERT
      );
    }
    if (!Utility.isEmptyObject(tenantDetails)) {
      setIsProductGroupingEnabled(
        tenantDetails?.additionalSettings?.PRODUCT_GROUP_ENABLED
      );
    }
    if (tenantDetails?.additionalSettings?.TOLERANCE_ENABLED) {
      const toleranceData: any = tenantDetails?.additionalSettings?.TOLERANCE;
      setIsToleranceToggleEnabled(
        tenantDetails?.additionalSettings?.TOLERANCE_ENABLED
      );
      setToleranceSettingsData({
        isPercent: toleranceData?.isPercent,
        quantityValue: toleranceData?.isPercent
          ? 0
          : toleranceData?.toleranceValue,
        percentValue: toleranceData?.isPercent
          ? toleranceData?.toleranceValue
          : 0
      });
    }
    if (
      tenantDetails?.additionalSettings?.COMPONENT_DETAILS_FOR_FG
        ?.componentDetailsEnable
    ) {
      setComponentDetailsForFG({
        componentDetailsEnable:
          tenantDetails?.additionalSettings?.COMPONENT_DETAILS_FOR_FG
            ?.componentDetailsEnable || false,
        componentDetailsGroupName:
          tenantDetails?.additionalSettings?.COMPONENT_DETAILS_FOR_FG
            ?.componentDetailsGroupName || 'Component List',
        operationDetailsEnable:
          tenantDetails?.additionalSettings?.COMPONENT_DETAILS_FOR_FG
            ?.operationDetailsEnable || false,
        operationDetailsGroupName:
          tenantDetails?.additionalSettings?.COMPONENT_DETAILS_FOR_FG
            ?.operationDetailsGroupName || 'Operations',
        additionalCostDetailsEnable:
          tenantDetails?.additionalSettings?.COMPONENT_DETAILS_FOR_FG
            ?.additionalCostDetailsEnable || false,
        additionalCostGroupName:
          tenantDetails?.additionalSettings?.COMPONENT_DETAILS_FOR_FG
            ?.additionalCostGroupName || 'Additional Charges'
      });
    }

    if (tenantDetails?.additionalSettings?.LANGUAGE_SELECTOR_VISIBLE) {
      setLanguageSelectorVisible(
        tenantDetails.additionalSettings.LANGUAGE_SELECTOR_VISIBLE
      );
    }
    if (tenantDetails?.additionalSettings?.JWO_ADDITIONAL_QTY_ALLOWED) {
      setIsJWOReceiveDispatchAdditionalQty(
        tenantDetails?.additionalSettings?.JWO_ADDITIONAL_QTY_ALLOWED
      );
    }

    toggleEntryGateTransaction(!!additionalSettings?.SECURITY_GATE_ENTRY);

    setisSalesModuleShortfallAlertEnabled(
      !!tenantDetails?.additionalSettings?.ALERT_ON_SHORTFALL_IN_SALES_MODULES
    );
    if (tenantDetails?.additionalSettings?.BANK_FILE_GENERATION) {
      setIsBankFileGenerationEnabled(
        tenantDetails.additionalSettings.BANK_FILE_GENERATION.enabled
      );
    }

    setIsBillBasedStockValuationHide(
      !!additionalSettings?.ENABLE_BILL_BASED_STOCK_VALUATION_FOR_TENANT
    );
    setIsBillBasedStockValuationEnabled(
      !!additionalSettings?.ENABLE_BILL_BASED_STOCK_VALUATION
    );
    setIsWOSufficientInsufficientQtyEnabled(
      !!tenantDetails?.additionalSettings?.WO_INSUFFICIENT_COMPONENTS_QTY_CHECK
    );
    setIsWOArchiveEnabled(
      !!tenantDetails?.additionalSettings?.ENABLE_ARCHIVE_WORK_ORDER
    );
    setIsPreferredVendorDetailsEnabled(
      !!additionalSettings?.ENABLE_PREFERRED_VENDOR_DETAILS_PRODUCT
    );
  }, [tenantDetails]);

  const toggleToleranceSettings = (
    toggleValue: boolean,
    toleranceDetails: any,
    isUpdateAutomation: boolean = false
  ) => {
    if (toleranceDetails?.isPercent && toleranceDetails?.percentValue > 100) {
      return;
    }
    const updatedStatus = toggleValue;
    const advancedSettingPayload = {
      additionalSettings: {
        TOLERANCE_ENABLED: updatedStatus,
        TOLERANCE: {
          isPercent: toleranceDetails?.isPercent,
          toleranceValue: toleranceDetails?.isPercent
            ? toleranceDetails?.percentValue
            : toleranceDetails?.quantityValue
        }
      }
    };
    setToleranceSaveInProgress(true);
    TenantService.updateOrgSetting(advancedSettingPayload)
      .then((res) => {
        if (updatedStatus && isUpdateAutomation) {
          toleranceAutomationUpdate(updatedStatus);
        }
        dispatch(fetchCurrentTenantInfo());
        setIsToleranceToggleEnabled(updatedStatus);
        setToleranceSaveInProgress(false);
      })
      .catch((err) => {
        console.error('Error updating tolerance settings', err);
        setToleranceSaveInProgress(false);
      });
  };

  const getColumnId = (columnCode: string) => {
    let id = TableManager.getColumnId(
      REMOTE_CONFIG_TABLES.AUTOMATION,
      columnCode
    );
    return id != undefined ? id : columnCode;
  };

  const toleranceAutomationUpdate = (updatedStatus: any) => {
    let { template, configuration } = getUpdatedTemplate();
    if (template?.steps && Array.isArray(template.steps)) {
      const toleranceStep = template.steps.find(
        (step: any) => step.type === AUTOMATION_ACTIONS.BOOKS_TOLERANCE_APPROVAL
      );
      if (toleranceStep) {
        toleranceStep['configuration'] = configuration;
      }
    }

    template = Utility.encodeJSON(template);
    const data: any = {};
    data[getColumnId(COLUMN_CODE.AUTOMATION.NAME)] = 'New Tolerance Automation';
    data[getColumnId(COLUMN_CODE.AUTOMATION.JSON_DATA)] = template;
    data[getColumnId(COLUMN_CODE.AUTOMATION.STATUS)] = STATUS_OPTIONS.ACTIVE;
    data[getColumnId(COLUMN_CODE.AUTOMATION.CONNECTION_ID)] =
      AutomationService.connectionId;
    if (AutomationService.connectionId) {
      checkAndSaveAutomation(data);
    } else {
      AutomationService.getAllConnections().then(
        (res) => {
          data[getColumnId(COLUMN_CODE.AUTOMATION.CONNECTION_ID)] =
            AutomationService.connectionId;
          checkAndSaveAutomation(data);
        },
        (err) => {
          removeLoader();
          console.error('Error loading existing connections: ', err);
        }
      );
    }
  };

  const checkAndSaveAutomation = (data: any) => {
    AutomationService.getAutomationWorkflowDetail(
      TableManager.getTableId(REMOTE_CONFIG_TABLES.AUTOMATION),
      {
        triggerTypes: [AUTOMATION_TRIGGERS.BOOKS_GR_FF_TOLERANCE_CREATE_TRIGGER]
      }
    ).then(
      (res: any) => {
        if (res && res.workflowData) {
          const toleranceTrigger = res.workflowData.find(
            (w: any) =>
              w.eventType ===
              AUTOMATION_TRIGGERS.BOOKS_GR_FF_TOLERANCE_CREATE_TRIGGER
          );
          if (
            !(
              toleranceTrigger &&
              toleranceTrigger.ids &&
              Array.isArray(toleranceTrigger.ids) &&
              toleranceTrigger.ids.length > 0
            )
          ) {
            AutomationService.save(data);
          }
        } else {
          AutomationService.save(data);
        }
      },
      (error: any) => {
        console.log('Error loading Workflow automation: ', error);
        removeLoader();
      }
    );
  };

  const getUpdatedTemplate = () => {
    const defaultToleranceTrigger = Templates.toleranceApprovalTrigger;
    let template = Utility.decodeJSON(defaultToleranceTrigger);
    const userDetails = AuthService.getUserDetails();
    const configuration = {
      approvalDetails: {
        approvers: [
          {
            firstName: userDetails?.name?.split(' ').slice(0, 1).join() || '',
            lastName: userDetails?.name?.split(' ').slice(1).join() || '',
            emailId: userDetails.email,
            id: userDetails.id,
            iamUserId: userDetails.imID
          }
        ],
        condition: 'ANY'
      },
      sendEmailToApprover: true,
      documentType: 'FF_GR_TOLERANCE_APPROVAL',
      approvalCondition: [
        {
          sourceField: 'qnty',
          destField: 'toleranceLimit',
          opr: 'gt'
        }
      ]
    };

    return { template, configuration };
  };

  //////////////////////////////////  module visibility code - start   //////////////////////////////
  const ontoggleChange = (checked: boolean, name: string) => {
    const state = module;

    if (name === 'PRICE_LIST' && checked) {
      state.priceList = true;
    }
    if (name === 'PRICE_LIST' && !checked) {
      state.priceList = false;
    }
    if (name === 'WAREHOUSE' && checked) {
      state.multipleWarehousesCheck = true;
    }
    if (name === 'WAREHOUSE' && !checked) {
      state.multipleWarehousesCheck = false;
    }

    if (name === 'E_WAY' && checked) {
      state.ewayBill = true;
    }
    if (name === 'E_WAY' && !checked) {
      state.ewayBill = false;
    }

    if (name === 'PPS' && checked) {
      state.pps = true;
    }
    if (name === 'PPS' && !checked) {
      state.pps = false;
    }

    if (name === 'SALES_ORDER' && checked) {
      state.salesOrder = true;
    }
    if (name === 'SALES_ORDER' && !checked) {
      state.salesOrder = false;
    }

    if (name === 'ENABLE_MACHINE_MAINTENANCE' && checked) {
      state.machineMaintenance = true;
    }

    if (name === 'ENABLE_MACHINE_MAINTENANCE' && !checked) {
      state.machineMaintenance = false;
    }

    if (name === 'FIXED_ASSET' && checked) {
      state.fixedAsset = true;
    }
    if (name === 'FIXED_ASSET' && !checked) {
      state.fixedAsset = false;
    }

    if (name === 'AMORTIZATION' && checked) {
      state.amortization = true;
    }
    if (name === 'AMORTIZATION' && !checked) {
      state.amortization = false;
    }
    if (name === 'RATE_ANALYSIS' && checked) {
      state.rateAnalysis = true;
    }
    if (name === 'LABEL_PRINT' && checked) {
      state.labelPrint = true;
    }
    if (name === 'RATE_ANALYSIS' && !checked) {
      state.rateAnalysis = false;
    }
    if (name == 'TRACKING_DETAILS_IN_SO' && !checked) {
      state.trackingDetailsInSO = false;
    }
    if (name == 'TRACKING_DETAILS_IN_SO' && checked) {
      state.trackingDetailsInSO = true;
    }
    if (name === 'REV_REC' && checked) {
      state.revenueRecognition = true;
    }
    if (name === 'REV_REC' && !checked) {
      state.revenueRecognition = false;
    }

    if (name === 'ALLOCATION_ADV' && checked) {
      state.expenseAllocation = true;
    }
    if (name === 'ALLOCATION_ADV' && !checked) {
      state.expenseAllocation = false;
    }

    let finalString = '';
    if (!state.multipleWarehousesCheck) {
      finalString = finalString + ',WAREHOUSE';
    }
    if (!state.priceList) {
      finalString = finalString + ',PRICE_LIST';
    }
    if (!state.ewayBill) {
      finalString = finalString + ',E_WAY';
    }
    if (!state.pps) {
      finalString = finalString + ',PPS';
    }

    if (finalString.charAt(0) == ',') {
      finalString = finalString.substring(1);
    }

    const payload = {
      application: 'ERP',
      hiddenApps: finalString,
      isResetHiddenApps: 'true'
    };
    TenantService.postAppCustomization(payload)
      .then((response) => {
        dispatch(fetchAppCustomizationInfo());
      })
      .catch((error) => {
        console.error('Error', error);
      });

    let advancedSettingPayload = {
      additionalSettings: {
        ENABLE_SO: state.salesOrder,
        ENABLE_FA: state.fixedAsset,
        REV_REC: state.revenueRecognition,
        AMORTIZATION: state.amortization,
        REV_REC_CREATION_SETTING: revenueRecognitionOption,
        ENABLE_EXPENSE_ALLOCATION: state.expenseAllocation,
        ENABLE_RATE_ANALYSIS: state.rateAnalysis,
        LABEL_PRINT: state.labelPrint,
        TRACKING_DETAILS_IN_SO: state.trackingDetailsInSO,
        ENABLE_MACHINE_MAINTENANCE: state.machineMaintenance
      }
    };

    TenantService.updateOrgSetting(advancedSettingPayload)
      .then(async (res) => {
        await dispatch(fetchCurrentTenantInfo());
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const checkppsDocPresent = (checked: any, module: any) => {
    if (checked) {
      ontoggleChange(checked, 'PPS');
    } else {
      PickPackShipService.checkPPSDocumentPresent()
        .then((res: any) => {
          if (res) {
            showAlert(
              'Warning',
              'Documents with PPS Fulfilment are present in the organization, cannot disable the feature.'
            );
          } else {
            ontoggleChange(checked, 'PPS');
          }
          console.log(res);
        })
        .catch((err: any) => {
          removeLoader();
        });
    }
  };

  const moduleVisiblityView = () => {
    return (
      <SettingsDetailCard className="mb-xl">
        <div className="column parent-width">
          <DKLabel text="Module Visibility" className="fw-m" />
          <LabelWithToggle
            title={t(`SETTINGS.MODULES.MULTIPLE_WAREHOUSES`)}
            toggleState={module.multipleWarehousesCheck}
            toggleCallback={() =>
              ontoggleChange(!module.multipleWarehousesCheck, 'WAREHOUSE')
            }
            className="p-v-xs"
          />
          <LabelWithToggle
            title={t(`SETTINGS.MODULES.PRICE_LIST`)}
            toggleState={module.priceList}
            toggleCallback={() =>
              ontoggleChange(!module.priceList, 'PRICE_LIST')
            }
            className="p-v-xs"
          />
          <LabelWithToggle
            title={'Pick Pack Ship'}
            toggleState={module.pps}
            toggleCallback={() => checkppsDocPresent(!module.pps, 'PPS')}
            className="p-v-xs"
          />
          <LabelWithToggle
            title={'Sales Orders'}
            toggleState={module.salesOrder}
            toggleCallback={() => {
              if (!module.salesOrder) {
                showAlert(
                  'Warning!',
                  'After enabling sales order module, you will not be able to disable it.',
                  [
                    {
                      title: 'Cancel',
                      className: 'bg-white border-m mr-s',
                      onClick: () => {}
                    },
                    {
                      title: 'Proceed',
                      className: 'bg-button text-white',
                      onClick: () => {
                        ontoggleChange(!module.salesOrder, 'SALES_ORDER');
                      }
                    }
                  ]
                );
              } else {
                showAlert('', 'This setting cannot be disabled.');
              }
            }}
            className="p-v-xs"
          />

          {Utility.isMRPWithURLCheck() && (
            <LabelWithToggle
              title={'Machine Maintenance'}
              toggleState={module.machineMaintenance}
              toggleCallback={() => {
                ontoggleChange(
                  !module.machineMaintenance,
                  'ENABLE_MACHINE_MAINTENANCE'
                );
              }}
              className="p-v-xs"
            />
          )}

          {featurePermissionsInfo?.Supported?.includes(
            FEATURE_PERMISSIONS.FIXED_ASSET_ADV
          ) && (
            <LabelWithToggle
              title={'Fixed Assets'}
              toggleState={module.fixedAsset}
              toggleCallback={() => {
                if (!module.fixedAsset) {
                  showAlert(
                    'Warning!',
                    'After enabling fixed assets module, you will not be able to disable it.',
                    [
                      {
                        title: 'Cancel',
                        className: 'bg-white border-m mr-s',
                        onClick: () => {}
                      },
                      {
                        title: 'Proceed',
                        className: 'bg-button text-white',
                        onClick: () => {
                          ontoggleChange(!module.fixedAsset, 'FIXED_ASSET');
                        }
                      }
                    ]
                  );
                } else {
                  showAlert('', 'This setting cannot be disabled.');
                }
              }}
              className="p-v-xs"
            />
          )}
          {featurePermissionsInfo?.Supported?.includes(
            FEATURE_PERMISSIONS.AMORTIZATION
          ) && (
            <LabelWithToggle
              title={'Expense Amortization'}
              toggleState={module.amortization}
              toggleCallback={() => {
                if (!module.amortization) {
                  showAlert(
                    'Warning!',
                    'After enabling expense amortization module, you will not be able to disable it.',
                    [
                      {
                        title: 'Cancel',
                        className: 'bg-white border-m mr-s',
                        onClick: () => {}
                      },
                      {
                        title: 'Proceed',
                        className: 'bg-button text-white',
                        onClick: () => {
                          ontoggleChange(!module.amortization, 'AMORTIZATION');
                        }
                      }
                    ]
                  );
                } else {
                  showAlert('', 'This setting cannot be disabled.');
                }
              }}
              className="p-v-xs"
            />
          )}
          {featurePermissionsInfo?.Supported?.includes(
            FEATURE_PERMISSIONS.EXPENSE_ALLOCATION
          ) && (
            <LabelWithToggle
              title={'Expense Allocation'}
              toggleState={module.expenseAllocation}
              toggleCallback={() => {
                if (!module.expenseAllocation) {
                  showAlert(
                    'Warning!',
                    'After enabling expense allocation module, you will not be able to disable it.',
                    [
                      {
                        title: 'Cancel',
                        className: 'bg-white border-m mr-s',
                        onClick: () => {}
                      },
                      {
                        title: 'Proceed',
                        className: 'bg-button text-white',
                        onClick: () => {
                          ontoggleChange(
                            !module.expenseAllocation,
                            'ALLOCATION_ADV'
                          );
                        }
                      }
                    ]
                  );
                } else {
                  showAlert('', 'This setting cannot be disabled.');
                }
              }}
              className="p-v-xs"
            />
          )}
          <LabelWithToggle
            title={'Rate Analysis'}
            toggleState={module.rateAnalysis}
            toggleCallback={() => {
              if (!module.rateAnalysis) {
                showAlert(
                  'Warning!',
                  'After enabling rate analysis, you will not be able to disable it.',
                  [
                    {
                      title: 'Cancel',
                      className: 'bg-white border-m mr-s',
                      onClick: () => {}
                    },
                    {
                      title: 'Proceed',
                      className: 'bg-button text-white',
                      onClick: () => {
                        ontoggleChange(!module.rateAnalysis, 'RATE_ANALYSIS');
                      }
                    }
                  ]
                );
              } else {
                showAlert('', 'This setting cannot be disabled.');
              }
            }}
            className="p-v-xs"
          />
          {module.salesOrder && (
            <LabelWithToggle
              title={'Tracking details for each line items in Sales Order'}
              toggleState={module.trackingDetailsInSO}
              toggleCallback={() => {
                ontoggleChange(
                  !module.trackingDetailsInSO,
                  'TRACKING_DETAILS_IN_SO'
                );
              }}
              className="p-v-xs"
            />
          )}
          {featurePermissionsInfo?.Supported?.includes(
            FEATURE_PERMISSIONS.LABEL_PRINT
          ) && (
            <LabelWithToggle
              title={'Label Print'}
              toggleState={module.labelPrint}
              toggleCallback={() => {
                ontoggleChange(!module.labelPrint, 'LABEL_PRINT');
              }}
              className="p-v-xs"
            />
          )}
          {featurePermissionsInfo?.Supported?.includes(
            FEATURE_PERMISSIONS.REVENUE_RECOGNITION_ADV
          ) && (
            <div className="column parent-width">
              <LabelWithToggle
                title={'Revenue Recognition'}
                toggleState={module.revenueRecognition}
                toggleCallback={() => {
                  if (!module.revenueRecognition) {
                    showAlert(
                      'Warning!',
                      'After enabling revenue recognition module, you will not be able to disable it.',
                      [
                        {
                          title: 'Cancel',
                          className: 'bg-white border-m mr-s',
                          onClick: () => {}
                        },
                        {
                          title: 'Proceed',
                          className: 'bg-button text-white',
                          onClick: () => {
                            ontoggleChange(
                              !module.revenueRecognition,
                              'REV_REC'
                            );
                          }
                        }
                      ]
                    );
                  } else {
                    showAlert('', 'This setting cannot be disabled.');
                  }
                }}
                className="p-v-xs"
              />
            </div>
          )}
        </div>
      </SettingsDetailCard>
    );
  };
  //////////////////////////////////  module visibility code - end   //////////////////////////////
  const renderLanguageSelectorSetting = () => {
    const updateLanguageSelectorSetting = () => {
      const payload = {
        additionalSettings: {
          LANGUAGE_SELECTOR_VISIBLE: !languageSelectorVisible
        }
      };
      showLoader('Please wait...');
      TenantService.updateOrgSetting(payload)
        .then((res: any) => {
          removeLoader();
          const tenantDetailsResponse = res;
          setLanguageSelectorVisible(
            tenantDetailsResponse?.additionalSettings
              ?.LANGUAGE_SELECTOR_VISIBLE || false
          );
          dispatch(fetchCurrentTenantInfo());
        })
        .catch((err: any) => {
          removeLoader();
          console.error(
            'Error updating work order auto allocation setting: ',
            err
          );
        });
    };

    return (
      <div className="row parent-width border-m border-radius-m gap-4 p-r mb-xl">
        <div className="column parent-width">
          <DKLabel
            text="Display Language Selector In Top Menu"
            className="fw-m mb-xs"
          />
        </div>
        <div className="column" style={{ minWidth: 42 }}>
          <Toggle
            className="box-content"
            onChange={(checked: any) => {
              updateLanguageSelectorSetting();
            }}
            isOn={languageSelectorVisible}
          />
        </div>
      </div>
    );
  };
  //////////////////////////////////  advanced inventroy tracking code - start   //////////////////////////////

  const advancedInventorySettingView = () => {
    const popupBtnConfigForAdvancedInventorySetting: BtnType[] = [
      {
        title: 'Cancel',
        class: 'border-m mr-s',
        clickAction: POPUP_CLICK_TYPE.CLOSE_POPUP
      },
      {
        title: 'Save',
        class: 'bg-app text-white mr-ss',
        clickAction: POPUP_CLICK_TYPE.ADVANCED_INVENTORY_POPUP
      }
    ];

    const parentChildInteraction = (passingData: CallBackPayloadType) => {
      const updatedState = advancedInventoryState;
      switch (passingData.type) {
        case POPUP_CALLBACKS_TYPE.ADVANCED_INVENTORY_POPUP:
          advancedInventoryRef.current.storeCallbacksRef.updateProduct =
            passingData.data;
          break;
        case POPUP_CALLBACKS_TYPE.CLOSE_POPUP:
          updatedState.showPopup = false;
          updatedState.toggleEnabled = false;
          setAdvancedInventoryState({ ...updatedState });
          break;
        case POPUP_CALLBACKS_TYPE.FIFO_ENABLED_SUCCESSFULLY:
          updatedState.toggleEnabled = true;
          updatedState.showPopup = false;
          setAdvancedInventoryState({ ...updatedState });
          break;
      }
    };

    const catchClicks = (data: PopupClickActionType) => {
      const updatedState = advancedInventoryState;
      switch (data.type) {
        case POPUP_CLICK_TYPE.CLOSE_POPUP:
          updatedState.showPopup = false;
          updatedState.toggleEnabled = false;
          setAdvancedInventoryState({ ...updatedState });
          break;
        case POPUP_CLICK_TYPE.ADVANCED_INVENTORY_POPUP:
          advancedInventoryRef.current?.storeCallbacksRef.updateProduct();
          break;
      }
    };

    const showPopup = (value: boolean) => {
      const updatedState = advancedInventoryState;
      updatedState.showPopup = value;
      updatedState.toggleEnabled = value;
      setAdvancedInventoryState({ ...updatedState });
    };

    return (
      <div className="row parent-width border-m border-radius-m gap-4 p-r mb-xl">
        <div className="column parent-width">
          <DKLabel text="Advanced Inventory Setting" className="fw-m mb-xs" />
          <DKLabel
            className="text-gray"
            text="Apply specific identification costing for Serial Tracked products, apply FIFO costing within each batch for Batch Tracked products."
            style={{ minWidth: 100 }}
          />
        </div>
        <div className="column" style={{ minWidth: 42 }}>
          <Toggle
            className="box-content"
            onChange={(checked: any) => {
              if (!fifoEnabled) {
                showPopup(!advancedInventoryState.toggleEnabled);
              }
            }}
            isOn={advancedInventoryState.toggleEnabled}
          />
        </div>
        {advancedInventoryState.showPopup && (
          <PopupWrapper
            clickAction={catchClicks}
            type={POPUP_TYPE.POPUP}
            title={'Change Costing Method of Batch and SN Products'}
            btnList={popupBtnConfigForAdvancedInventorySetting}
            width={'35%'}
            height="60%"
          >
            <AdvancedInventoryPopup
              passingInteraction={(callback: CallBackPayloadType) => {
                parentChildInteraction(callback);
              }}
            />
          </PopupWrapper>
        )}
      </div>
    );
  };

  //////////////////////////////////  advanced inventroy tracking code - end   //////////////////////////////

  //////////////////////////////////  negative inventroy code - start   //////////////////////////////

  const negativeInventoryView = () => {
    const onConfirm = () => {
      TenantService.updateOrgSetting({ allowNegativeInventory: true })
        .then((res) => {
          dispatch(fetchCurrentTenantInfo());
          setShowConfirmBox(false);
        })
        .catch((err) => {
          console.log('Error updating negative inventory', err);
          setShowConfirmBox(false);
        });
    };
    const getConfirmView = () => {
      return (
        <div className="transparent-background">
          <div
            className="popup-window overflow-hidden"
            style={{
              maxWidth: 520,
              width: '90%',
              maxHeight: '95%',
              padding: 0,
              position: 'fixed'
            }}
          >
            <div
              className="row justify-content-between p-h-r p-v-s bg-gray1"
              style={{ height: 60 }}
            >
              <div className="row width-auto">
                <DKLabel
                  text="Enable negative inventory"
                  className="fw-m fs-l"
                />
              </div>
              <div className="row width-auto"></div>
            </div>
            {/* message */}
            <DKLabel
              text="Turning ON this setting will allow negative inventory in Books, also note that
    you will not be able to turn it OFF once enabled. "
              className="p-4"
            />
            {/* footer */}
            <div
              className="row justify-content-between border-t-2 px-3"
              style={{ height: 60 }}
            >
              <div className="row width-auto"></div>
              <div className="row width-auto">
                <DKButton
                  title={'Cancel'}
                  className="mr-r bg-gray-100"
                  onClick={() => {
                    setShowConfirmBox(false);
                  }}
                />
                <DKButton
                  title={'Confirm'}
                  className="text-white bg-button"
                  onClick={() => {
                    onConfirm();
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      );
    };
    return (
      <div className="row parent-width border-m border-radius-m gap-4 p-r mb-xl">
        <div className="column parent-width">
          <DKLabel text="Negative Inventory Setting" className="fw-m mb-xs" />
          <DKLabel
            className="text-gray"
            text="Turning ON this setting will allow negative inventory in Books, also note that you will not be able to turn it OFF once enabled."
            style={{ minWidth: 100 }}
          />
        </div>
        <div className="column" style={{ minWidth: 42 }}>
          <Toggle
            className="box-content"
            onChange={(checked: any) => {
              if (!isNegativeInventoryEnabled) {
                setShowConfirmBox(!showConfirmBox);
              }
            }}
            isOn={isNegativeInventoryEnabled}
          />
        </div>
        {showConfirmBox && getConfirmView()}
      </div>
    );
  };

  //////////////////////////////////  negative inventroy code - end   //////////////////////////////

  //////////////////////////////////  Price Book start  //////////////////////////////
  const getPriceBookSettingView = () => {
    const onConfirm = () => {
      let advancedSettingPayload = {
        isAdvancePriceListEnabled: !tenantDetails.isAdvancePriceListEnabled
      };
      TenantService.updateOrgSetting(advancedSettingPayload)
        .then((res) => {
          dispatch(fetchCurrentTenantInfo());
          setShowPriceBookConfirmation(false);
        })
        .catch((err) => {
          console.log('Error updating Price Book settings', err);
          setShowPriceBookConfirmation(false);
        });
    };
    const getConfirmView = () => {
      return (
        <div className="transparent-background">
          <div
            className="popup-window overflow-hidden"
            style={{
              maxWidth: 520,
              width: '90%',
              maxHeight: '95%',
              padding: 0,
              position: 'fixed'
            }}
          >
            <div
              className="row justify-content-between p-h-r p-v-s bg-gray1"
              style={{ height: 60 }}
            >
              <div className="row width-auto">
                <DKLabel text={`Enable Price Book`} className="fw-m fs-l" />
              </div>
              <div className="row width-auto"></div>
            </div>
            {/* message */}
            <DKLabel
              text={`Turning ON this setting will allow user to use Price Book. Also note that you will not be able to turn it OFF once enabled`}
              className="p-4"
            />
            {/* footer */}
            <div
              className="row justify-content-between border-t-2 px-3"
              style={{ height: 60 }}
            >
              <div className="row width-auto"></div>
              <div className="row width-auto">
                <DKButton
                  title={'Cancel'}
                  className="mr-r bg-gray-100"
                  onClick={() => {
                    setShowPriceBookConfirmation(!showPriceBookConfirmation);
                  }}
                />
                <DKButton
                  title={'Confirm'}
                  className="text-white bg-button"
                  onClick={() => {
                    onConfirm();
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      );
    };
    return (
      <div className="row parent-width border-m border-radius-m gap-4 p-r mb-xl">
        <div className="column parent-width">
          <DKLabel text="Price Book" className="fw-m mb-xs" />
          <DKLabel
            className="text-gray"
            text="Price books works like price list, but has dependency on multiple factors like contact attributes, product attributes, quantity etc."
            style={{ minWidth: 100 }}
          />
        </div>
        <div className="column" style={{ minWidth: 42 }}>
          <Toggle
            className="box-content"
            onChange={(checked: any) => {
              if (!isPriceBookEnabled) {
                setShowPriceBookConfirmation(!showPriceBookConfirmation);
              }
            }}
            isOn={isPriceBookEnabled}
          />
        </div>
        {showPriceBookConfirmation && getConfirmView()}
      </div>
    );
  };
  //////////////////////////////////  Price Book end    //////////////////////////////

  ////////////////////////////////// multiple discount setting code - start   //////////////////////////////

  const MultipleDiscountSettingView = () => {
    const onConfirm = () => {
      setIsMultipleGlobalDiscountEnabled(!isMultipleGlobalDiscountEnabled);
      let advancedSettingPayload = {
        additionalSettings: {
          MULTIPLE_GLOBAL_DISCOUNT: !isMultipleGlobalDiscountEnabled
        }
      };
      TenantService.updateOrgSetting(advancedSettingPayload)
        .then((res) => {
          dispatch(fetchCurrentTenantInfo());
          setShowMultipleGlobalDiscountConfirmBox(false);
        })
        .catch((err) => {
          console.log('Error updating global discount settings', err);
          setShowMultipleGlobalDiscountConfirmBox(false);
        });
    };
    const getConfirmView = () => {
      return (
        <div className="transparent-background">
          <div
            className="popup-window overflow-hidden"
            style={{
              maxWidth: 520,
              width: '90%',
              maxHeight: '95%',
              padding: 0,
              position: 'fixed'
            }}
          >
            <div
              className="row justify-content-between p-h-r p-v-s bg-gray1"
              style={{ height: 60 }}
            >
              <div className="row width-auto">
                <DKLabel
                  text={`${
                    isMultipleGlobalDiscountEnabled ? 'Disable' : 'Enable'
                  }  Multiple Discount`}
                  className="fw-m fs-l"
                />
              </div>
              <div className="row width-auto"></div>
            </div>
            {/* message */}
            <DKLabel
              text={`Turning ${
                isMultipleGlobalDiscountEnabled ? 'OFF' : 'ON'
              } this setting will ${
                isMultipleGlobalDiscountEnabled ? 'not allow' : 'allow'
              } multiple discount setting in Books. `}
              className="p-4"
            />
            {/* footer */}
            <div
              className="row justify-content-between border-t-2 px-3"
              style={{ height: 60 }}
            >
              <div className="row width-auto"></div>
              <div className="row width-auto">
                <DKButton
                  title={'Cancel'}
                  className="mr-r bg-gray-100"
                  onClick={() => {
                    setShowMultipleGlobalDiscountConfirmBox(
                      !showMultipleGlobalDiscountConfirmBox
                    );
                  }}
                />
                <DKButton
                  title={'Confirm'}
                  className="text-white bg-button"
                  onClick={() => {
                    onConfirm();
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      );
    };
    return (
      <div className="row parent-width border-m border-radius-m gap-4 p-r mb-xl">
        <div className="column parent-width">
          <DKLabel text="Multiple Discount Settings" className="fw-m mb-xs" />
          <DKLabel
            className="text-gray"
            text="Allow user to store and select global discount on Book."
            style={{ minWidth: 100 }}
          />
        </div>
        <div className="column" style={{ minWidth: 42 }}>
          <Toggle
            className="box-content"
            onChange={(checked: any) => {
              setShowMultipleGlobalDiscountConfirmBox(
                !showMultipleGlobalDiscountConfirmBox
              );
            }}
            isOn={isMultipleGlobalDiscountEnabled}
          />
        </div>
        {showMultipleGlobalDiscountConfirmBox && getConfirmView()}
      </div>
    );
  };

  //////////////////////////////////  multiple discount setting code - end   //////////////////////////////

  const CascadingDiscountSettingView = () => {
    const onConfirm = () => {
      let advancedSettingPayload = {
        additionalSettings: {
          CASCADING_DISCOUNTS: { enable: !isCascadingDiscountEnabled }
        }
      };
      TenantService.updateOrgSetting(advancedSettingPayload)
        .then((res) => {
          dispatch(fetchCurrentTenantInfo());
          setShowConfirmBox(false);
          setShowCascadingDiscountConfirmBox(false);
        })
        .catch((err) => {
          console.log('Error updating global discount settings', err);
          setShowConfirmBox(false);
        });
    };
    const getConfirmView = () => {
      return (
        <div className="transparent-background">
          <div
            className="popup-window overflow-hidden"
            style={{
              maxWidth: 520,
              width: '90%',
              maxHeight: '95%',
              padding: 0,
              position: 'fixed'
            }}
          >
            <div
              className="row justify-content-between p-h-r p-v-s bg-gray1"
              style={{ height: 60 }}
            >
              <div className="row width-auto">
                <DKLabel
                  text={`Enable Multiple Discount at Line Item Level.`}
                  className="fw-m fs-l"
                />
              </div>
              <div className="row width-auto"></div>
            </div>
            {/* message */}
            <DKLabel
              text={`Turning ON this setting will allow Multiple Discounts at Line Level in Books, also note that you will not be able to turn it OFF once enabled.`}
              className="p-4"
            />
            {/* footer */}
            <div
              className="row justify-content-between border-t-2 px-3"
              style={{ height: 60 }}
            >
              <div className="row width-auto"></div>
              <div className="row width-auto">
                <DKButton
                  title={'Cancel'}
                  className="mr-r bg-gray-100"
                  onClick={() => {
                    setShowCascadingDiscountConfirmBox(
                      !showCascadingDiscountConfirmBox
                    );
                  }}
                />
                <DKButton
                  title={'Confirm'}
                  className="text-white bg-button"
                  onClick={() => {
                    onConfirm();
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      );
    };
    return (
      <div className="row parent-width border-m border-radius-m gap-4 p-r mb-xl">
        <div className="column parent-width">
          <DKLabel
            text="Multiple Discount at Line Item Level"
            className="fw-m mb-xs"
          />
          <DKLabel
            className="text-gray"
            text="Allows users to define multiple discount at product level. These can be cascading discounts or multiple discount on the sub-total Amount."
            style={{ minWidth: 100 }}
          />
        </div>
        <div className="column align-items-end" style={{ minWidth: 42 }}>
          <Toggle
            className="box-content"
            onChange={(checked: any) => {
              if (!isCascadingDiscountEnabled) {
                setShowCascadingDiscountConfirmBox(
                  !showCascadingDiscountConfirmBox
                );
              }
            }}
            isOn={isCascadingDiscountEnabled}
          />
          {isCascadingDiscountEnabled && (
            <DKButton
              title="Configure"
              className="bg-white text-bg-button-color cursor-hand mt-s"
              style={{ padding: 0, borderRadius: 6 }}
              onClick={() => {
                setShowCascadingPopup(!showCascadingPopup);
              }}
            />
          )}
        </div>
        {showCascadingDiscountConfirmBox && getConfirmView()}

        {showCascadingPopup && (
          <CascadingPopup
            data={tenantDetails?.additionalSettings?.CASCADING_DISCOUNTS}
            onClose={() => {
              setShowCascadingPopup(false);
            }}
            onUpdate={() => {
              setShowCascadingPopup(false);
            }}
          />
        )}
      </div>
    );
  };

  //////////////////////////////////  filter account setting - start   //////////////////////////////

  const filterAccountsSetting = () => {
    const callApi = () => {
      TenantService.updateAccountSettingINOrgSetting({
        additionalSettings: {
          ACCOUNT: {
            showCardCashBankAccountsOnly: isShowCashBankCard
          }
        }
      })
        .then(
          (res: any) => {
            dispatch(fetchCurrentTenantInfo());
            setShowConfirmBoxAccSetting(false);
          },
          (err: any) => {
            setShowConfirmBoxAccSetting(false);
            console.log(err);
          }
        )
        .catch((err: any) => {
          setShowConfirmBoxAccSetting(false);
          console.log(err);
        });
    };
    const getMessage = () => {
      if (isShowCashBankCard) {
        return 'Turning ON this setting will only allow accounts of type Cash, Bank & Credit Cards in Expense, Deposit, and payments.';
      } else {
        return 'Turning OFF this setting will allow all accounts in Expense, Deposit, and payments.';
      }
    };

    const getConfirmView = () => {
      return (
        <div className="transparent-background">
          <div
            className="popup-window overflow-hidden"
            style={{
              maxWidth: 520,
              width: '90%',
              maxHeight: '95%',
              padding: 0,
              position: 'fixed'
            }}
          >
            <div
              className="row justify-content-between p-h-r p-v-s bg-gray1"
              style={{ height: 60 }}
            >
              <div className="row width-auto">
                <DKLabel
                  text="Enable filter accounts setting"
                  className="fw-m fs-l"
                />
              </div>
              <div className="row width-auto"></div>
            </div>
            {/* message */}
            <DKLabel text={getMessage()} className="p-4" />
            {/* footer */}
            <div
              className="row justify-content-between border-t-2 px-3"
              style={{ height: 60 }}
            >
              <div className="row width-auto"></div>
              <div className="row width-auto">
                <DKButton
                  title={'Cancel'}
                  className="mr-r bg-gray-100"
                  onClick={() => {
                    setShowConfirmBoxAccSetting(false);
                    setIsShowCashBankCard(!isShowCashBankCard);
                  }}
                />
                <DKButton
                  title={'Confirm'}
                  className="text-white bg-button"
                  onClick={() => {
                    callApi();
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      );
    };

    return (
      <div className="row parent-width border-m border-radius-m gap-4 p-r mb-xl">
        <div className="column parent-width">
          <DKLabel text="Filter Accounts Setting" className="fw-m mb-xs" />
          <DKLabel
            className="text-gray"
            text="Show Only Bank, Cash and Cards"
            style={{ minWidth: 100 }}
          />
        </div>
        <div className="column" style={{ minWidth: 42 }}>
          <Toggle
            className="box-content"
            onChange={(checked: any) => {
              setIsShowCashBankCard(!isShowCashBankCard);
              setShowConfirmBoxAccSetting(true);
            }}
            isOn={isShowCashBankCard}
          />
        </div>
        {showConfirmBoxAccSetting && getConfirmView()}
      </div>
    );
  };

  //////////////////////////////////  filter account setting - end   //////////////////////////////

  //////////////////////////////////  bill/invoice edit setting - start   //////////////////////////////

  const callDocumentEdit = (docType: any) => {
    let payload: any = {
      additionalSettings: {}
    };
    switch (docType) {
      case DOCUMENT_TYPE.BILL:
        payload = {
          additionalSettings: {
            BILL_EDIT_SETTING: !isBillEditOn
          }
        };
        break;
      case DOCUMENT_TYPE.INVOICE:
        payload = {
          additionalSettings: {
            INVOICE_EDIT_SETTING: !isInvoiceEditOn
          }
        };
        break;
      case DOCUMENT_TYPE.SALES_ORDER:
        payload = {
          additionalSettings: {
            SO_EDIT_SETTING: !isSOEditOn
          }
        };
        break;
      case DOCUMENT_TYPE.PURCHASE_ORDER:
        payload = {
          additionalSettings: {
            PO_EDIT_SETTING: !isPOEditOn
          }
        };
        break;
      case DOCUMENT_TYPE.JOB_WORK_OUT:
        payload = {
          additionalSettings: {
            JWO_ADDITIONAL_QTY_ALLOWED: !isJWOReceiveDispatchAdditionalQty
          }
        };
        break;
    }

    showLoader('Please wait...');
    TenantService.updateOrgSetting(payload)
      .then((res: any) => {
        removeLoader();
        const tenantDetailsResponse = res;

        setIsBillEditOn(
          tenantDetailsResponse.additionalSettings.BILL_EDIT_SETTING || false
        );
        setIsInvoiceEditOn(
          tenantDetailsResponse.additionalSettings.INVOICE_EDIT_SETTING || false
        );
        setIsPOEditOn(
          tenantDetailsResponse.additionalSettings.PO_EDIT_SETTING || false
        );
        setIsJWOReceiveDispatchAdditionalQty(
          tenantDetailsResponse.additionalSettings.JWO_ADDITIONAL_QTY_ALLOWED ||
            false
        );
        setIsSOEditOn(
          tenantDetailsResponse.additionalSettings.SO_EDIT_SETTING || false
        );
        dispatch(fetchCurrentTenantInfo());
      })
      .catch((err) => {
        console.log('Error updating additional charge settings', err);
        setShowConfirmBox(false);
      });
  };

  const updatePurchaseOrderSetting = (key: string) => {
    let payload: any = {
      additionalSettings: {}
    };
    switch (key) {
      case 'PO_SHOW_RECEIVED_BILLED_QTY_SETTING':
        payload.additionalSettings.PO_SHOW_RECEIVED_BILLED_QTY_SETTING =
          !isPOShowReceivedBilledQtyOn;
        break;
    }

    showLoader('Please wait...');
    TenantService.updateOrgSetting(payload)
      .then((res: any) => {
        removeLoader();
        const tenantDetailsResponse = res;
        setIsPOShowReceivedBilledQtyOn(
          tenantDetailsResponse.additionalSettings
            .PO_SHOW_RECEIVED_BILLED_QTY_SETTING || false
        );
        dispatch(fetchCurrentTenantInfo());
      })
      .catch((err) => {
        console.log('Error updating additional settings', err);
      });
  };

  const getBillEditSetting = () => {
    return (
      <div className="row parent-width border-m border-radius-m gap-4 p-r mb-xl">
        <div className="column parent-width">
          <DKLabel text="Bill Edit Setting" className="fw-m mb-xs" />
          <DKLabel
            className="text-gray"
            text="Allow editing of other details on Bill after Payment/GRN"
            style={{ minWidth: 100 }}
          />
        </div>
        <div className="column" style={{ minWidth: 42 }}>
          <Toggle
            className="box-content"
            onChange={(checked: any) => {
              callDocumentEdit(DOCUMENT_TYPE.BILL);
            }}
            isOn={isBillEditOn}
          />
        </div>
      </div>
    );
  };

  const getInvoiceEditSetting = () => {
    return (
      <div className="row parent-width border-m border-radius-m gap-4 p-r mb-xl">
        <div className="column parent-width">
          <DKLabel text="Invoice Edit Setting" className="fw-m mb-xs" />
          <DKLabel
            className="text-gray"
            text="Allow editing of other details on Invoice after Payment/Delivery"
            style={{ minWidth: 100 }}
          />
        </div>
        <div className="column" style={{ minWidth: 42 }}>
          <Toggle
            className="box-content"
            onChange={(checked: any) => {
              callDocumentEdit(DOCUMENT_TYPE.INVOICE);
            }}
            isOn={isInvoiceEditOn}
          />
        </div>
      </div>
    );
  };

  const getSOEditSetting = () => {
    return (
      <div className="row parent-width border-m border-radius-m gap-4 p-r mb-xl">
        <div className="column parent-width">
          <DKLabel text="Sales Order Edit Setting" className="fw-m mb-xs" />
          <DKLabel
            className="text-gray"
            text="Allow editing of other details on Sales Order after Payment/GRN"
            style={{ minWidth: 100 }}
          />
        </div>
        <div className="column" style={{ minWidth: 42 }}>
          <Toggle
            className="box-content"
            onChange={(checked: any) => {
              callDocumentEdit(DOCUMENT_TYPE.SALES_ORDER);
            }}
            isOn={isSOEditOn}
          />
        </div>
      </div>
    );
  };

  const getPOEditSetting = () => {
    return (
      <div className="row parent-width border-m border-radius-m p-r mb-xl">
        <div className="column parent-width">
          <DKLabel text="Purchase Order Setting" className="fw-m mb-xs" />
          <div className="row justify-content-between p-v-xs gap-4">
            <DKLabel
              className="text-gray"
              text="Allow editing of other details on Purchase Order after Payment/GRN"
              style={{ minWidth: 100 }}
            />
            <div className="column" style={{ minWidth: 42 }}>
              <Toggle
                className="box-content"
                onChange={(checked: any) => {
                  callDocumentEdit(DOCUMENT_TYPE.PURCHASE_ORDER);
                }}
                isOn={isPOEditOn}
              />
            </div>
          </div>
          <div className="row justify-content-between p-v-xs gap-4">
            <DKLabel
              className="text-gray"
              text="Show received and billed quantity in detail view"
              style={{ minWidth: 100 }}
            />
            <div className="column" style={{ minWidth: 42 }}>
              <Toggle
                className="box-content"
                onChange={(checked: any) => {
                  updatePurchaseOrderSetting(
                    'PO_SHOW_RECEIVED_BILLED_QTY_SETTING'
                  );
                }}
                isOn={isPOShowReceivedBilledQtyOn}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  //////////////////////////////////  bill/invoice edit setting - end   //////////////////////////////

  //////////////////////////////////  converted document edit setting - start //////////////////////////////
  const updateConvertedDocEditSetting = () => {
    const payload = {
      additionalSettings: {
        EDIT_CONVERTED_DOC: !isConvertedDocEditingOn
      }
    };
    showLoader('Please wait...');
    TenantService.updateOrgSetting(payload)
      .then((res: any) => {
        removeLoader();
        const tenantDetailsResponse = res;
        setIsConvertedDocEditingOn(
          tenantDetailsResponse?.additionalSettings?.EDIT_CONVERTED_DOC || false
        );
        dispatch(fetchCurrentTenantInfo());
      })
      .catch((err: any) => {
        removeLoader();
        console.error('Error updating Converted Doc Edit setting: ', err);
      });
  };

  const updateBlockedWOSetting = () => {
    const payload = {
      additionalSettings: {
        RESERVE_WO_CHILD_QUANTITY: !isBlockedWOEnabled
      }
    };
    showLoader('Please wait...');
    TenantService.updateOrgSetting(payload)
      .then((res: any) => {
        removeLoader();
        const tenantDetailsResponse = res;
        setIsBlockedWOEnabled(
          tenantDetailsResponse?.additionalSettings
            ?.RESERVE_WO_CHILD_QUANTITY || false
        );
        dispatch(fetchCurrentTenantInfo());
      })
      .catch((err: any) => {
        removeLoader();
        console.error('Error updating Sub WO setting: ', err);
      });
  };

  const getBlockedWOSetting = () => {
    return (
      <div className="row parent-width border-m border-radius-m gap-4 p-r mb-xl">
        <div className="column parent-width">
          <DKLabel
            text="Block Sub Work Order Quantity in Parent Work Order"
            className="fw-m mb-xs"
          />
          <DKLabel
            className="text-gray"
            text="Block quantity produced from Sub-Work Orders to be used in Parent Work
            Orders only"
            style={{ minWidth: 100 }}
          />
        </div>
        <div className="column" style={{ minWidth: 42 }}>
          <Toggle
            className="box-content"
            onChange={(checked: any) => {
              updateBlockedWOSetting();
            }}
            isOn={isBlockedWOEnabled}
          />
        </div>
      </div>
    );
  };

  const updateReserveStockWOforSOSetting = () => {
    const payload = {
      additionalSettings: {
        RESERVE_SO_LINKED_WO_QUANTITY: !isReserveStockWOforSOEnabled
      }
    };
    showLoader('Please wait...');
    TenantService.updateOrgSetting(payload)
      .then((res: any) => {
        removeLoader();
        const tenantDetailsResponse = res;
        setIsReserveStockWOforSOEnabled(
          tenantDetailsResponse?.additionalSettings
            ?.RESERVE_SO_LINKED_WO_QUANTITY || false
        );
        dispatch(fetchCurrentTenantInfo());
      })
      .catch((err: any) => {
        removeLoader();
        console.error('Error updating Sub WO setting: ', err);
      });
  };

  const getReserveStockWOSettingForSO = () => {
    return (
      <div className="row parent-width border-m border-radius-m gap-4 p-r mb-xl">
        <div className="column parent-width">
          <DKLabel
            text="Reserve Stock produced from Work Order for linked Sales Order"
            className="fw-m mb-xs"
          />
          <DKLabel
            className="text-gray"
            text="Stock produced in the work order (parent work order) after production gets reserved at the sales order against which the work order was made"
            style={{ minWidth: 100 }}
          />
        </div>
        <div className="column" style={{ minWidth: 42 }}>
          <Toggle
            className="box-content"
            onChange={(checked: any) => {
              updateReserveStockWOforSOSetting();
            }}
            isOn={isReserveStockWOforSOEnabled}
          />
        </div>
      </div>
    );
  };

  const updateComponentDetailsForFGOnDocumentsSetting = (type: any) => {
    let COMPONENT_DETAILS_FOR_FG = componentDetailsForFG;

    switch (type) {
      case COMPONENT_LIST_DETAILS_FOR_FG.COMPONENT_LIST_ENABLE:
        const mainComponentListEnabled =
          !componentDetailsForFG?.componentDetailsEnable;
        COMPONENT_DETAILS_FOR_FG = {
          ...COMPONENT_DETAILS_FOR_FG,
          componentDetailsEnable: mainComponentListEnabled,
          operationDetailsEnable: mainComponentListEnabled,
          additionalCostDetailsEnable: mainComponentListEnabled
        };
        break;

      case COMPONENT_LIST_DETAILS_FOR_FG.COMPONENT_LIST_TABLE_NAME:
        COMPONENT_DETAILS_FOR_FG = {
          ...COMPONENT_DETAILS_FOR_FG,
          componentDetailsGroupName:
            componentDetailsForFG?.componentDetailsGroupName || 'Component List'
        };
        break;

      case COMPONENT_LIST_DETAILS_FOR_FG.COMPONENT_LIST_OPERATIONS_ENABLE:
        COMPONENT_DETAILS_FOR_FG = {
          ...COMPONENT_DETAILS_FOR_FG,
          operationDetailsEnable: !componentDetailsForFG?.operationDetailsEnable
        };
        break;

      case COMPONENT_LIST_DETAILS_FOR_FG.COMPONENT_LIST_OPERATIONS_TABLE_NAME:
        COMPONENT_DETAILS_FOR_FG = {
          ...COMPONENT_DETAILS_FOR_FG,
          operationDetailsGroupName:
            componentDetailsForFG?.operationDetailsGroupName || 'Operations'
        };
        break;

      case COMPONENT_LIST_DETAILS_FOR_FG.COMPONENT_LIST_ADD_CHARGES_ENABLE:
        COMPONENT_DETAILS_FOR_FG = {
          ...COMPONENT_DETAILS_FOR_FG,
          additionalCostDetailsEnable:
            !componentDetailsForFG?.additionalCostDetailsEnable
        };
        break;

      case COMPONENT_LIST_DETAILS_FOR_FG.COMPONENT_LIST_ADD_CHARGES_TABLE_NAME:
        COMPONENT_DETAILS_FOR_FG = {
          ...COMPONENT_DETAILS_FOR_FG,
          additionalCostGroupName:
            componentDetailsForFG?.additionalCostGroupName ||
            'Additional Charges'
        };
        break;

      default:
        break;
    }

    const payload = {
      additionalSettings: {
        COMPONENT_DETAILS_FOR_FG: COMPONENT_DETAILS_FOR_FG
      }
    };
    showLoader('Please wait...');
    TenantService.updateOrgSetting(payload)
      .then((res: any) => {
        removeLoader();
        const tenantDetailsResponse = res;
        setComponentDetailsForFG({
          componentDetailsEnable:
            tenantDetailsResponse?.additionalSettings?.COMPONENT_DETAILS_FOR_FG
              ?.componentDetailsEnable || false,
          componentDetailsGroupName:
            tenantDetailsResponse?.additionalSettings?.COMPONENT_DETAILS_FOR_FG
              ?.componentDetailsGroupName || 'Component List',
          operationDetailsEnable:
            tenantDetailsResponse?.additionalSettings?.COMPONENT_DETAILS_FOR_FG
              ?.operationDetailsEnable || false,
          operationDetailsGroupName:
            tenantDetailsResponse?.additionalSettings?.COMPONENT_DETAILS_FOR_FG
              ?.operationDetailsGroupName || 'Operations',
          additionalCostDetailsEnable:
            tenantDetailsResponse?.additionalSettings?.COMPONENT_DETAILS_FOR_FG
              ?.additionalCostDetailsEnable || false,
          additionalCostGroupName:
            tenantDetailsResponse?.additionalSettings?.COMPONENT_DETAILS_FOR_FG
              ?.additionalCostGroupName || 'Additional Charges'
        });
        dispatch(fetchCurrentTenantInfo());
      })
      .catch((err: any) => {
        removeLoader();
        console.error(
          'Error updating component details for finished good on documents setting: ',
          err
        );
      });
  };

  const getComponentDetailsForFGOnDocuments = () => {
    return (
      <div className="column parent-width border-m border-radius-m gap-4 p-r mb-xl">
        {/* main feature toggle */}
        <div className="row parent-width">
          <div className="column parent-width">
            <DKLabel
              text="Add component details for FG to Quote/Invoice/SO"
              className="fw-m mb-xs"
            />
            <DKLabel
              className="text-gray"
              text="Once this setting is enabled, user can add the component details for a FG as used in any work order to the SO/Quote/Invoice"
              style={{ minWidth: 100 }}
            />
          </div>
          <div className="column" style={{ minWidth: 42 }}>
            <Toggle
              className="box-content"
              onChange={(checked: any) => {
                updateComponentDetailsForFGOnDocumentsSetting(
                  COMPONENT_LIST_DETAILS_FOR_FG.COMPONENT_LIST_ENABLE
                );
              }}
              isOn={componentDetailsForFG?.componentDetailsEnable}
            />
          </div>
        </div>
        {/* main group name textfield */}
        {componentDetailsForFG?.componentDetailsEnable && (
          <div
            style={{
              width: 300
            }}
          >
            <DKInput
              title="Component details FG table name"
              value={componentDetailsForFG?.componentDetailsGroupName ?? ''}
              canValidate={false}
              direction={INPUT_VIEW_DIRECTION.VERTICAL}
              type={INPUT_TYPE.TEXT}
              readOnly={false}
              onChange={(name: string) => {
                setComponentDetailsForFG({
                  ...componentDetailsForFG,
                  componentDetailsGroupName: name
                });
              }}
              onBlur={(event: any) => {
                const updatedName = event.target.value;
                if (
                  updatedName?.length === 0 ||
                  componentDetailsForFG?.componentDetailsGroupName?.trim() ===
                    tenantDetails?.additionalSettings?.COMPONENT_DETAILS_FOR_FG
                      ?.componentDetailsGroupName
                ) {
                  return;
                }
                updateComponentDetailsForFGOnDocumentsSetting(
                  COMPONENT_LIST_DETAILS_FOR_FG.COMPONENT_LIST_TABLE_NAME
                );
              }}
            />
          </div>
        )}
        {/* operations toggle */}
        {componentDetailsForFG?.componentDetailsEnable && (
          <div className="row parent-width">
            <div className="column parent-width">
              <DKLabel
                text="Add operations details in FG to Quote/Invoice/SO"
                className="fw-m mb-xs"
              />
              <DKLabel
                className="text-gray"
                text="Once this setting is enabled, user can add the operations details too for a FG as used in any work order to the SO/Quote/Invoice"
                style={{ minWidth: 100 }}
              />
            </div>
            <div className="column" style={{ minWidth: 42 }}>
              <Toggle
                className="box-content"
                onChange={(checked: any) => {
                  updateComponentDetailsForFGOnDocumentsSetting(
                    COMPONENT_LIST_DETAILS_FOR_FG.COMPONENT_LIST_OPERATIONS_ENABLE
                  );
                }}
                isOn={componentDetailsForFG?.operationDetailsEnable}
              />
            </div>
          </div>
        )}
        {/* operation name textfield */}
        {componentDetailsForFG?.componentDetailsEnable &&
          componentDetailsForFG?.operationDetailsEnable && (
            <div
              style={{
                width: 300
              }}
            >
              <DKInput
                title="Operations details for FG table name"
                value={componentDetailsForFG?.operationDetailsGroupName ?? ''}
                canValidate={false}
                direction={INPUT_VIEW_DIRECTION.VERTICAL}
                type={INPUT_TYPE.TEXT}
                readOnly={false}
                onChange={(name: string) => {
                  setComponentDetailsForFG({
                    ...componentDetailsForFG,
                    operationDetailsGroupName: name
                  });
                }}
                onBlur={(event: any) => {
                  const updatedName = event.target.value;
                  if (
                    updatedName?.length === 0 ||
                    componentDetailsForFG?.operationDetailsGroupName?.trim() ===
                      tenantDetails?.additionalSettings
                        ?.COMPONENT_DETAILS_FOR_FG?.operationDetailsGroupName
                  ) {
                    return;
                  }
                  updateComponentDetailsForFGOnDocumentsSetting(
                    COMPONENT_LIST_DETAILS_FOR_FG.COMPONENT_LIST_OPERATIONS_TABLE_NAME
                  );
                }}
              />
            </div>
          )}
        {/* additional charges toggle */}
        {componentDetailsForFG?.componentDetailsEnable && (
          <div className="row parent-width">
            <div className="column parent-width">
              <DKLabel
                text="Add additional charges details for FG to Quote/Invoice/SO"
                className="fw-m mb-xs"
              />
              <DKLabel
                className="text-gray"
                text="Once this setting is enabled, user can add the additional charges details for a FG as used in any work order to the SO/Quote/Invoice"
                style={{ minWidth: 100 }}
              />
            </div>
            <div className="column" style={{ minWidth: 42 }}>
              <Toggle
                className="box-content"
                onChange={(checked: any) => {
                  updateComponentDetailsForFGOnDocumentsSetting(
                    COMPONENT_LIST_DETAILS_FOR_FG.COMPONENT_LIST_ADD_CHARGES_ENABLE
                  );
                }}
                isOn={componentDetailsForFG?.additionalCostDetailsEnable}
              />
            </div>
          </div>
        )}
        {/* additional charges name textfield */}
        {componentDetailsForFG?.componentDetailsEnable &&
          componentDetailsForFG?.additionalCostDetailsEnable && (
            <div
              style={{
                width: 300
              }}
            >
              <DKInput
                title="Additional charges details FG table name"
                value={componentDetailsForFG?.additionalCostGroupName ?? ''}
                canValidate={false}
                direction={INPUT_VIEW_DIRECTION.VERTICAL}
                type={INPUT_TYPE.TEXT}
                readOnly={false}
                onChange={(name: string) => {
                  setComponentDetailsForFG({
                    ...componentDetailsForFG,
                    additionalCostGroupName: name
                  });
                }}
                onBlur={(event: any) => {
                  const updatedName = event.target.value;
                  if (
                    updatedName?.length === 0 ||
                    componentDetailsForFG?.additionalCostGroupName?.trim() ===
                      tenantDetails?.additionalSettings
                        ?.COMPONENT_DETAILS_FOR_FG?.additionalCostGroupName
                  ) {
                    return;
                  }
                  updateComponentDetailsForFGOnDocumentsSetting(
                    COMPONENT_LIST_DETAILS_FOR_FG.COMPONENT_LIST_ADD_CHARGES_TABLE_NAME
                  );
                }}
              />
            </div>
          )}
      </div>
    );
  };

  const updateReserveStockWOforPOSetting = () => {
    const payload = {
      additionalSettings: {
        RESERVE_WO_LINKED_PO_QUANTITY: !isReserveStockWOforPOEnabled
      }
    };
    showLoader('Please wait...');
    TenantService.updateOrgSetting(payload)
      .then((res: any) => {
        removeLoader();
        const tenantDetailsResponse = res;
        setIsReserveStockWOforPOEnabled(
          tenantDetailsResponse?.additionalSettings
            ?.RESERVE_WO_LINKED_PO_QUANTITY || false
        );
        dispatch(fetchCurrentTenantInfo());
      })
      .catch((err: any) => {
        removeLoader();
        console.error('Error updating Sub WO setting: ', err);
      });
  };

  const getReserveStockWOSettingForPO = () => {
    return (
      <div className="row parent-width border-m border-radius-m gap-4 p-r mb-xl">
        <div className="column parent-width">
          <DKLabel
            text="Reserve stock from Linked Purchase order / Purchase Requisition for associated Work Order"
            className="fw-m mb-xs"
          />
          <DKLabel
            className="text-gray"
            text="Upon enabling this option, the stock quantities that are procured by creating a Purchase Order or Purchase Requisition from a Work Order are reserved for that Work Order by default"
            style={{ minWidth: 100 }}
          />
        </div>
        <div className="column" style={{ minWidth: 42 }}>
          <Toggle
            className="box-content"
            onChange={(checked: any) => {
              updateReserveStockWOforPOSetting();
            }}
            isOn={isReserveStockWOforPOEnabled}
          />
        </div>
      </div>
    );
  };

  const getProcessManufacturingToggleView = () => {
    const toggleProcessManufacturingConfirmationPopup = () => {
      WorkOrderService.areAllWorkOrdersCompleted().then(
        (isCompleted: boolean) => {
          if (isCompleted) {
            setShowWorkOrderProcessManufacturingConfirmation(true);
          } else {
            showAlert(
              'Complete all Work Orders!',
              `Please complete all the existing Work Orders before you ${
                isWorkOrderProcessManufacturingEnabled ? 'disable' : 'enable'
              } this setting.`
            );
          }
        },
        (err: any) => {
          console.error('Error fetching all WO status: ', err);
        }
      );
    };
    const saveWorkOrderProcessManufacturingSetting = () => {
      const updatedStatus = !isWorkOrderProcessManufacturingEnabled;
      let advancedSettingPayload = {
        additionalSettings: {
          LINK_INVENTORY_JOB_CARDS: updatedStatus
        }
      };
      TenantService.updateOrgSetting(advancedSettingPayload)
        .then((res) => {
          setShowWorkOrderProcessManufacturingConfirmation(false);
          dispatch(fetchCurrentTenantInfo());
          setIsWorkOrderProcessManufacturingEnabled(updatedStatus);
        })
        .catch((err) => {
          console.log(
            'Error updating work order warehouse tagging settings',
            err
          );
          setShowWorkOrderProcessManufacturingConfirmation(false);
        });
    };
    return (
      <div className="row parent-width border-m border-radius-m gap-4 p-r mb-xl">
        <div className="column parent-width">
          <DKLabel text="Process Manufacturing" className="fw-m mb-xs" />
          <DKLabel
            className="text-gray"
            text="This allows you to link components, WIP & Finished Goods to Job Cards and track processing of these at Job Card level"
            style={{ minWidth: 100 }}
          />
        </div>
        <div className="column" style={{ minWidth: 42 }}>
          <Toggle
            className="box-content"
            onChange={(checked: any) => {
              toggleProcessManufacturingConfirmationPopup();
            }}
            isOn={isWorkOrderProcessManufacturingEnabled}
          />
        </div>
        {showWorkOrderProcessManufacturingConfirmation && (
          <AdvancedSettingsConfirmationPopup
            title="Enable Process Manufacturing"
            summary={
              !isWorkOrderProcessManufacturingEnabled
                ? 'By enabling this setting you can link inventory consumption, production, QC with Job Card.'
                : 'By disabling this setting you cannot link inventory consumption, production, QC with Job Card.'
            }
            onCancel={() =>
              setShowWorkOrderProcessManufacturingConfirmation(false)
            }
            onConfirm={() => saveWorkOrderProcessManufacturingSetting()}
          />
        )}
      </div>
    );
  };

  const getOnStartOrderAllocationToggleView = () => {
    const updateStartOrderAllocationSetting = () => {
      const payload = {
        additionalSettings: {
          WO_AUTO_ALLOCATE_ON_START: !isWOAutoAllocationEnabled
        }
      };
      showLoader('Please wait...');
      TenantService.updateOrgSetting(payload)
        .then((res: any) => {
          removeLoader();
          const tenantDetailsResponse = res;
          setIsWOAutoAllocationEnabled(
            tenantDetailsResponse?.additionalSettings
              ?.WO_AUTO_ALLOCATE_ON_START || false
          );
          dispatch(fetchCurrentTenantInfo());
        })
        .catch((err: any) => {
          removeLoader();
          console.error(
            'Error updating work order auto allocation setting: ',
            err
          );
        });
    };

    return (
      <div className="row parent-width border-m border-radius-m gap-4 p-r mb-xl">
        <div className="column parent-width">
          <DKLabel
            text="Auto allocation on start work order"
            className="fw-m mb-xs"
          />
          <DKLabel
            className="text-gray"
            text='Enable Auto Allocation of Components/WIP when user clicks on "Start Work Order"'
            style={{ minWidth: 100 }}
          />
        </div>
        <div className="column" style={{ minWidth: 42 }}>
          <Toggle
            className="box-content"
            onChange={(checked: any) => {
              updateStartOrderAllocationSetting();
            }}
            isOn={isWOAutoAllocationEnabled}
          />
        </div>
      </div>
    );
  };

  //////////////////////////////////  converted document edit setting - end   //////////////////////////////

  const getConvertedDocEditSetting = () => {
    return (
      <div className="row parent-width border-m border-radius-m gap-4 p-r mb-xl">
        <div className="column parent-width">
          <DKLabel
            text="Allow adding more items to converted documents"
            className="fw-m mb-xs"
          />
          <DKLabel
            className="text-gray"
            text="Allow user to add more items to converted documents"
            style={{ minWidth: 100 }}
          />
        </div>
        <div className="column" style={{ minWidth: 42 }}>
          <Toggle
            className="box-content"
            onChange={(checked: any) => {
              updateConvertedDocEditSetting();
            }}
            isOn={isConvertedDocEditingOn}
          />
        </div>
      </div>
    );
  };

  //////////////////////////////////  Additional Charge setting - start   //////////////////////////////

  const saveAdditionalCharges = (data: any) => {
    // find selected Apportion By Type
    let selectedApportionType = Object.keys(
      data?.apportionLineItemLevelSetting
    ).filter((key) => {
      return data?.apportionLineItemLevelSetting[key];
    });

    // Payload for update additional settings
    let Payload: any = {
      additionalSettings: {
        ADDITIONAL_CHARGE: {
          independentCharge: data?.individualCharge,
          apportionFlag: data.apportionLineItemLevel,
          apportionValue: selectedApportionType?.length
            ? selectedApportionType[0]
            : null
        }
      }
    };

    showLoader('Please wait...');

    TenantService.updateOrgSetting(Payload)
      .then((res) => {
        removeLoader();
        dispatch(fetchCurrentTenantInfo());
        setShowConfirmBox(false);
      })
      .catch((err) => {
        console.log('Error updating additional charge settings', err);
        setShowConfirmBox(false);
      });
  };

  const onAdditionalChargeChange = (fieldName: string) => {
    let newState = deepClone(additionalChargeState);
    switch (fieldName) {
      case ADDITIONAL_CHARGE_SETTING.INDIVIDUAL_CHARGES:
        newState.individualCharge = true;
        newState.apportionLineItemLevel = false;
        newState.apportionLineItemLevelSetting.APPORTION_ON_VALUE = false;
        newState.apportionLineItemLevelSetting.APPORTION_ON_QTY = false;
        newState.apportionLineItemLevelSetting.APPORTION_MANUAL = false;
        setAdditionalChargeState({ ...newState });
        saveAdditionalCharges({ ...newState });
        break;
      case ADDITIONAL_CHARGE_SETTING.APPORTION_BY_LINE_ITEM_LEVEL:

      const additionalSettingsBILL_BASED_STOCK_VALUATION =
        tenantDetails?.additionalSettings?.ENABLE_BILL_BASED_STOCK_VALUATION;
      if (additionalSettingsBILL_BASED_STOCK_VALUATION) {
        const buttons = [
          { title: 'No', className: 'bg-gray2 border-m', onClick: () => {} },
          {
            title: 'Ok, Proceed',
            className: 'bg-blue text-white ml-r',
            onClick: () => {
              newState.individualCharge = false;
              newState.apportionLineItemLevel = true;
              newState.apportionLineItemLevelSetting.APPORTION_ON_QTY = true;
              newState.apportionLineItemLevelSetting.APPORTION_ON_VALUE = false;
              newState.apportionLineItemLevelSetting.APPORTION_MANUAL = false;

              setAdditionalChargeState({ ...newState });

              let selectedApportionType = (
                Object.keys(newState.apportionLineItemLevelSetting) as Array<
                  keyof typeof newState.apportionLineItemLevelSetting
                >
              ).filter((key) => newState.apportionLineItemLevelSetting[key]);

              let Payload = {
                additionalSettings: {
                  ENABLE_BILL_BASED_STOCK_VALUATION: false,
                  ADDITIONAL_CHARGE: {
                    independentCharge: false,
                    apportionFlag: true,
                    apportionValue: selectedApportionType.length
                      ? selectedApportionType[0]
                      : null
                  }
                }
              };

              updateOrgSetting(Payload);
            }
          }
        ];

        const alertMsg = `If you enable this setting, the setting named "Bill based Stock Valuation" will be disabled.`;
        const alertTitle = 'Please confirm if you are okay to proceed?';
        showAlert(alertMsg, alertTitle, buttons);
      } else {
        newState.individualCharge = false;
        newState.apportionLineItemLevel = true;
        newState.apportionLineItemLevelSetting.APPORTION_ON_QTY = true;
        newState.apportionLineItemLevelSetting.APPORTION_ON_VALUE = false;
        newState.apportionLineItemLevelSetting.APPORTION_MANUAL = false;
        setAdditionalChargeState({ ...newState });
        saveAdditionalCharges({ ...newState });
      }
        break;
      case ADDITIONAL_CHARGE_SETTING.APPORTION_BY_VALUE:
        newState.individualCharge = false;
        newState.apportionLineItemLevel = true;
        newState.apportionLineItemLevelSetting.APPORTION_ON_VALUE = true;
        newState.apportionLineItemLevelSetting.APPORTION_ON_QTY = false;
        newState.apportionLineItemLevelSetting.APPORTION_MANUAL = false;
        setAdditionalChargeState({ ...newState });
        saveAdditionalCharges({ ...newState });
        break;
      case ADDITIONAL_CHARGE_SETTING.APPORTION_BY_QTY:
        newState.individualCharge = false;
        newState.apportionLineItemLevel = true;
        newState.apportionLineItemLevelSetting.APPORTION_ON_QTY = true;
        newState.apportionLineItemLevelSetting.APPORTION_ON_VALUE = false;
        newState.apportionLineItemLevelSetting.APPORTION_MANUAL = false;
        setAdditionalChargeState({ ...newState });
        saveAdditionalCharges({ ...newState });
        break;
      case ADDITIONAL_CHARGE_SETTING.MANUAL:
        newState.individualCharge = false;
        newState.apportionLineItemLevel = true;
        newState.apportionLineItemLevelSetting.APPORTION_MANUAL = true;
        newState.apportionLineItemLevelSetting.APPORTION_ON_QTY = false;
        newState.apportionLineItemLevelSetting.APPORTION_ON_VALUE = false;
        setAdditionalChargeState({ ...newState });
        saveAdditionalCharges({ ...newState });
        break;
    }
  };

  const additionalChargeSetting = () => {
    return (
      <SettingsDetailCard className="mb-xl">
        <div className="column parent-width">
          <DKLabel
            text="Treatment of Additional Charges"
            className="fw-m mb-r"
          />
          <div className="column mb-r">
            <div className="row">
              <DKRadioButton
                title="Individual charges"
                isSelected={additionalChargeState.individualCharge}
                onClick={() =>
                  onAdditionalChargeChange(
                    ADDITIONAL_CHARGE_SETTING.INDIVIDUAL_CHARGES
                  )
                }
                className="mr-s"
                color={'bg-button'}
              />
            </div>
            <div className="row mt-s">
              <DKRadioButton
                title="Apportion on Line Item Level"
                isSelected={additionalChargeState.apportionLineItemLevel}
                onClick={() =>
                  onAdditionalChargeChange(
                    ADDITIONAL_CHARGE_SETTING.APPORTION_BY_LINE_ITEM_LEVEL
                  )
                }
                className="mr-s"
                color={'bg-button'}
              />
            </div>
          </div>

          <div className="column ml-xl">
            <div className="row width-auto mb-r">
              <div
                className={`circle mr-s bg-black`}
                style={{ width: 8, height: 8 }}
              />
              <DKLabel text="Apportion by Qty" />
            </div>
            <div className="row width-auto mb-r">
              <div
                className={`circle mr-s bg-black`}
                style={{ width: 8, height: 8 }}
              />
              <DKLabel text="Apportion by Value" />
            </div>
            <div className="row width-auto">
              <div
                className={`circle mr-s bg-black`}
                style={{ width: 8, height: 8 }}
              />
              <DKLabel text="Manual" />
            </div>
          </div>
        </div>
      </SettingsDetailCard>
    );
  };
  //////////////////////////////////  Additional Charge - start   //////////////////////////////

  //////////////////////////////////  row-rack-bin setting - start   //////////////////////////////

  const rowRackBinSettings = () => {
    return (
      <div className="row parent-width border-m border-radius-m gap-4 p-r mb-xl">
        <div className="column parent-width">
          <DKLabel text="Row Rack Bin" className="fw-m mb-xs" />
          <DKLabel
            className="text-gray"
            text="Row, rack, bin are the exact physical location of a product within a particular warehouse"
            style={{ minWidth: 100 }}
          />
        </div>
        <div className="column" style={{ minWidth: 64 }}>
          <DKButton
            title="Configure"
            className="bg-white text-bg-button-color cursor-hand"
            style={{ padding: 0, borderRadius: 6 }}
            onClick={() => {
              setShowRowRackBinPopup(!showRowRackBinPopup);
            }}
          />
        </div>
        {showRowRackBinPopup && (
          <RowRackBinGridPopup
            onClose={() => {
              setShowRowRackBinPopup(false);
            }}
            onUpdate={() => {
              setShowRowRackBinPopup(false);
            }}
          />
        )}
      </div>
    );
  };
  //////////////////////////////////  row-rack-bin setting - end   //////////////////////////////

  //////////////////////////////////  product valuation method - start   //////////////////////////////

  const saveValuationMethod = (data: any) => {
    // Payload for update additional settings
    let Payload: any = {
      additionalSettings: {
        PRODUCT_VALUATION_METHOD: productValuationMethodOption[data?.[0]]?.value
      }
    };

    showLoader('Please wait...');

    TenantService.updateOrgSetting(Payload)
      .then((res) => {
        removeLoader();
        dispatch(fetchCurrentTenantInfo());
      })
      .catch((err) => {});
  };

  const valutionMethodSetting = () => {
    return (
      <div className="row parent-width border-m border-radius-m gap-4 p-r mb-xl">
        <div className="column parent-width">
          <DKLabel text="Valuation Method Setting" className="fw-m" />
        </div>
        <div className="column" style={{ minWidth: 150 }}>
          <DKInput
            required={false}
            canValidate={false}
            style={{ width: 150 }}
            readOnly={false}
            title={''}
            value={valuationMethod}
            options={
              productValuationMethodOption
                ? productValuationMethodOption.map((item: any) => item.label)
                : []
            }
            onChange={(typeObj: any) => {
              setValuationMethod(typeObj);
              saveValuationMethod(typeObj);
            }}
            type={INPUT_TYPE.SELECT}
          />
        </div>
      </div>
    );
  };

  //////////////////////////////////  product valuation method - end   //////////////////////////////

  //////////////////////////////////  forecast method - start   //////////////////////////////

  const saveForecastMethod = (method: any) => {
    // Payload for update additional settings
    setForecastMethod(method);
    let Payload: any = {
      additionalSettings: {
        FORECAST_SHORTFALL_METHOD: method
      }
    };

    showLoader('Please wait...');

    TenantService.updateOrgSetting(Payload)
      .then((res) => {
        removeLoader();
        dispatch(fetchCurrentTenantInfo());
      })
      .catch((err) => {});
  };

  const saveincludeWoQty = (value: any) => {
    let Payload: any = {
      additionalSettings: {
        INCLUDE_WO_QTY: value
      }
    };

    showLoader('Please wait...');

    TenantService.updateOrgSetting(Payload)
      .then((res) => {
        removeLoader();
        setIncludeWoQty(value);
        dispatch(fetchCurrentTenantInfo());
      })
      .catch((err) => {});
  };

  const saveDisplayAdditionalDetails = (value: any) => {
    let Payload: any = {
      additionalSettings: {
        DISPLAY_ADDITIONAL_SETTINGS_WITH_FORECAST: value
      }
    };

    showLoader('Please wait...');

    TenantService.updateOrgSetting(Payload)
      .then((res) => {
        removeLoader();
        setDisplayAdditionalDetails(value);
        dispatch(fetchCurrentTenantInfo());
      })
      .catch((err) => {});
  };

  const forecastMethodSetting = () => {
    return (
      <SettingsDetailCard className="mb-xl">
        <div className="column parent-width">
          <DKLabel text="Forecasting Method" className="fw-m mb-s" />
          <div className="column">
            <div className="row">
              <DKRadioButton
                isSelected={forecastMethod === FORECAST_METHOD_SETTING.DEFAULT}
                onClick={() => {
                  saveForecastMethod(FORECAST_METHOD_SETTING.DEFAULT);
                }}
                className="mr-s mt-xs"
                color={'bg-button'}
              />
              <div className="column">
                <DKLabel text="Default Method" />
                <DKLabel
                  text="Uses Forecasted qty to calculate shortfall"
                  className="text-gray"
                />
              </div>
            </div>
            <div className="row mt-m">
              <DKRadioButton
                isSelected={
                  forecastMethod === FORECAST_METHOD_SETTING.OUTGOING_QTY_DRIVEN
                }
                onClick={() =>
                  saveForecastMethod(
                    FORECAST_METHOD_SETTING.OUTGOING_QTY_DRIVEN
                  )
                }
                className="mr-s"
                color={'bg-button'}
              />
              <div className="column">
                <DKLabel text="Outgoing Qty Driven Method" />
                <DKLabel
                  text="Picks higher one between forecasting and outgoing qty"
                  className="text-gray"
                />
              </div>
            </div>
            {forecastMethod === FORECAST_METHOD_SETTING.OUTGOING_QTY_DRIVEN && (
              <>
                <div className="column mt-2">
                  <DKCheckMark
                    title="Include WO Qty in Incoming Qty"
                    isSelected={includeWoQty}
                    onClick={() => {
                      saveincludeWoQty(!includeWoQty);
                    }}
                    className="mr-s"
                  />
                </div>
                <div className="column mt-2">
                  <DKCheckMark
                    title="Display Additional Details in Forecasting Module"
                    isSelected={displayAdditionalDetails}
                    onClick={() => {
                      saveDisplayAdditionalDetails(!displayAdditionalDetails);
                    }}
                    className="mr-s"
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </SettingsDetailCard>
    );
  };
  //////////////////////////////////  forecast method - end   //////////////////////////////

  //////////////////////////////////  Amortization method - start   //////////////////////////////

  const saveAmortizationScheduleCustomFieldSetting = () => {
    // Payload for update additional settings
    const tempSelectedCustomField = productCustomFieldData.filter(
      (row, index) => availabilitySelectedIndexes.includes(index)
    );
    const tempSelectedCustomFieldIds = tempSelectedCustomField.map(
      (field) => field.id
    );
    let Payload: any = {
      additionalSettings: {
        AMORTIZATION_SCHEDULE_CUSTOM_FIELD_SETTING: tempSelectedCustomFieldIds
      }
    };

    showLoader('Please wait...');

    TenantService.updateOrgSetting(Payload)
      .then((res) => {
        removeLoader();
        dispatch(fetchCurrentTenantInfo());
      })
      .catch((err) => {});
  };
  const fetchProductCustomFields = async () => {
    const moduleName = 'PRODUCT';
    if (!moduleName) {
      return;
    }
    CustomFieldService.getCustomFields(
      {
        // status: 'ACTIVE',
        limit: '1000',
        module: moduleName
      },
      true
    ).then(
      (res: any) => {
        let tempProductCustomFieldList: any[] = [];
        let tempProductCustomFieldData: any[] = [];

        let response = res?.content;
        if (!Utility.isClassRowType()) {
          response = res?.content?.filter(
            (field: any) => field.label !== 'CLASS'
          );
        }
        response?.forEach((customFieldRow: any) => {
          let row = {
            id: customFieldRow?.id,
            label: customFieldRow.label
          };
          tempProductCustomFieldData.push(row);
          tempProductCustomFieldList.push(customFieldRow.label);
        });
        setProductCustomFieldData(tempProductCustomFieldData);
        setProductCustomFieldList(tempProductCustomFieldList);
        let tempAvailabilitySelectedIndexes: any[] = [];
        let tempRevRecAvailabilitySelectedIndexes: any[] = [];
        let tempPoConversionAvailabilitySelectedIndexes: any[] = [];
        tempProductCustomFieldData.forEach((field, index) => {
          if (
            tenantDetails?.additionalSettings
              ?.AMORTIZATION_SCHEDULE_CUSTOM_FIELD_SETTING &&
            tenantDetails?.additionalSettings?.AMORTIZATION_SCHEDULE_CUSTOM_FIELD_SETTING.includes(
              field.id
            )
          ) {
            tempAvailabilitySelectedIndexes.push(index);
          }

          if (
            tenantDetails?.additionalSettings?.REV_REC_CUSTOM_FIELD_SETTING &&
            tenantDetails?.additionalSettings?.REV_REC_CUSTOM_FIELD_SETTING.includes(
              field.id
            )
          ) {
            tempRevRecAvailabilitySelectedIndexes.push(index);
          }
          if (
            tenantDetails?.additionalSettings
              ?.REQUISITION_TO_PO_PRODUCT_CUSTOM_FIELD_SETTING &&
            tenantDetails?.additionalSettings?.REQUISITION_TO_PO_PRODUCT_CUSTOM_FIELD_SETTING.includes(
              field.id
            )
          ) {
            tempPoConversionAvailabilitySelectedIndexes.push(index);
          }
        });
        setAvailabilitySelectedIndexes(tempAvailabilitySelectedIndexes);
        setRevRecAvailabilitySelectedIndexes(
          tempRevRecAvailabilitySelectedIndexes
        );
        setPoConversionAvailabilitySelectedIndexes(
          tempPoConversionAvailabilitySelectedIndexes
        );
      },
      (err) => {
        console.log(err);
      }
    );
  };
  const AmortizationScheduleCustomFieldSetting = () => {
    return (
      <div className="row parent-width border-m border-radius-m gap-4 p-r mb-xl">
        <div className="column parent-width">
          <DKLabel
            text="Select Custom fields to be visible in Amortization Schedule Details Screen"
            className="fw-m mb-s"
          />
          <div
            className="row row-responsive align-items-start  w-12/12"
            style={{ width: '80%' }}
          >
            <DKInput
              className={{}}
              icon={{}}
              title=""
              value={availabilitySelectedIndexes}
              canValidate={{}}
              type={INPUT_TYPE.MULTI_SELECT}
              options={productCustomFieldList || []}
              onChange={(value: any) => {
                setAvailabilitySelectedIndexes(value);
              }}
              direction={{}}
              required={false}
            />
          </div>
        </div>
        <div className="column mt-xl" style={{ minWidth: 42 }}>
          <DKButton
            title={'Save'}
            className="text-white bg-button"
            width={'30px'}
            onClick={saveAmortizationScheduleCustomFieldSetting}
          />
        </div>
      </div>
    );
  };

  //////////////////////////////////  Amortization method - end   //////////////////////////////

  //////////////////////////////////  Po Conversion Screen method - start   //////////////////////

  const savePoConversionScreenCustomFieldSetting = () => {
    // Payload for update additional settings
    const tempSelectedCustomField = productCustomFieldData.filter(
      (row, index) => poConversionAvailabilitySelectedIndexes.includes(index)
    );
    const tempSelectedCustomFieldIds = tempSelectedCustomField.map(
      (field) => field.id
    );
    let Payload: any = {
      additionalSettings: {
        REQUISITION_TO_PO_PRODUCT_CUSTOM_FIELD_SETTING:
          tempSelectedCustomFieldIds
      }
    };

    showLoader('Please wait...');

    TenantService.updateOrgSetting(Payload)
      .then((res) => {
        removeLoader();
        dispatch(fetchCurrentTenantInfo());
      })
      .catch((err) => {});
  };
  const PoConversionScreenCustomFieldSetting = () => {
    return (
      <div className="row parent-width border-m border-radius-m gap-4 p-r mb-xl">
        <div className="column parent-width">
          <DKLabel
            text="Select Product Custom fields to be visible in PO Conversion Screen"
            className="fw-m mb-s"
          />
          <div
            className="row row-responsive align-items-start  w-12/12"
            style={{ width: '80%' }}
          >
            <DKInput
              className={{}}
              icon={{}}
              title=""
              value={poConversionAvailabilitySelectedIndexes}
              canValidate={{}}
              type={INPUT_TYPE.MULTI_SELECT}
              options={productCustomFieldList || []}
              onChange={(value: any) => {
                setPoConversionAvailabilitySelectedIndexes(value);
              }}
              direction={{}}
              required={false}
            />
          </div>
        </div>
        <div className="column mt-xl" style={{ minWidth: 42 }}>
          <DKButton
            title={'Save'}
            className="text-white bg-button"
            width={'30px'}
            onClick={savePoConversionScreenCustomFieldSetting}
          />
        </div>
      </div>
    );
  };

  //////////////////////////////////  Po Conversion Screen method - end   //////////////////////
  //////////////////////////////////  Rev Rec method - start   //////////////////////////////

  const saveRevRecCustomFieldSetting = () => {
    // Payload for update additional settings
    const tempSelectedCustomField = productCustomFieldData.filter(
      (row, index) => revRecAvailabilitySelectedIndexes.includes(index)
    );
    const tempSelectedCustomFieldIds = tempSelectedCustomField.map(
      (field) => field.id
    );
    let Payload: any = {
      additionalSettings: {
        REV_REC_CUSTOM_FIELD_SETTING: tempSelectedCustomFieldIds
      }
    };

    showLoader('Please wait...');

    TenantService.updateOrgSetting(Payload)
      .then((res) => {
        removeLoader();
        dispatch(fetchCurrentTenantInfo());
      })
      .catch((err) => {});
  };

  const RevRecCustomFieldSetting = () => {
    return (
      <div className="row parent-width border-m border-radius-m gap-4 p-r mb-xl">
        <div className="column parent-width">
          <DKLabel
            text="Select Custom fields to be visible in Revenue Recognition Plan Details Screen"
            className="fw-m mb-s"
          />
          <div
            className="row row-responsive align-items-start  w-12/12"
            style={{ width: '80%' }}
          >
            <DKInput
              className={{}}
              icon={{}}
              title=""
              value={revRecAvailabilitySelectedIndexes}
              canValidate={{}}
              type={INPUT_TYPE.MULTI_SELECT}
              options={productCustomFieldList || []}
              onChange={(value: any) => {
                setRevRecAvailabilitySelectedIndexes(value);
              }}
              direction={{}}
              required={false}
            />
          </div>
        </div>
        <div className="column mt-xl" style={{ minWidth: 42 }}>
          <DKButton
            title={'Save'}
            className="text-white bg-button"
            width={'30px'}
            onClick={saveRevRecCustomFieldSetting}
          />
        </div>
      </div>
    );
  };

  //////////////////////////////////  Rev Rec method - end  //////////////////////////////
  //////////////////////////////////  JE Credit Debit Type method - start  //////////////////////////////
  const saveJECreditDebitMethodSetting = () => {
    let payload: any = {
      additionalSettings: {
        JE_CREDIT_DEBIT_COLUMN_SETTING: !isJECredtiDebitMethodSettingOn
      }
    };
    showLoader('Please wait...');
    TenantService.updateOrgSetting(payload)
      .then((res: any) => {
        removeLoader();
        dispatch(fetchCurrentTenantInfo());
      })
      .catch((err) => {
        removeLoader();
        console.log('Error updating additional settings', err);
      });
  };
  const JECreditDebitMethodSetting = () => {
    return (
      <div className="row parent-width border-m border-radius-m gap-4 p-r mb-xl">
        <div className="column parent-width">
          <DKLabel
            text="JE Credit Debit Column Setting "
            className="fw-m mb-xs"
          />
          <DKLabel
            className="text-gray"
            text="Allow User To Add Amount Separately in Credit And Debit Column"
            style={{ minWidth: 100 }}
          />
        </div>
        <div className="column" style={{ minWidth: 42 }}>
          <Toggle
            className="box-content"
            onChange={saveJECreditDebitMethodSetting}
            isOn={isJECredtiDebitMethodSettingOn}
          />
        </div>
      </div>
    );
  };

  //////////////////////////////////   JE Credit Debit Type method - start  //////////////////////////////

  //////////////////////////////////  JE Amount view method - start  //////////////////////////////

  const saveJEAmountViewSetting = () => {
    let payload: any = {
      additionalSettings: {
        JE_AMOUNT_SETTING: !isJEAmountViewSettingOn
      }
    };
    showLoader('Please wait...');
    TenantService.updateOrgSetting(payload)
      .then((res: any) => {
        removeLoader();
        dispatch(fetchCurrentTenantInfo());
      })
      .catch((err) => {
        removeLoader();
        console.log('Error updating additional settings', err);
      });
  };
  const JEAmountViewSetting = () => {
    return (
      tenantDetails.currency !== 'INR' && (
        <div className="row parent-width border-m border-radius-m gap-4 p-r mb-xl">
          <div className="column parent-width">
            <DKLabel text="JE Amount Setting " className="fw-m mb-xs" />
            <DKLabel
              className="text-gray"
              text="Display rounded amounts for Journal Entries in View Mode"
              style={{ minWidth: 100 }}
            />
          </div>
          <div className="column" style={{ minWidth: 42 }}>
            <Toggle
              className="box-content"
              onChange={saveJEAmountViewSetting}
              isOn={isJEAmountViewSettingOn}
            />
          </div>
        </div>
      )
    );
  };
  //////////////////////////////////  JE Amount view method - end  //////////////////////////////

  //////////////////////////////////  Purchase Requisition Purchase Order setting - start   //////////////////////////////

  const onPRPOChange = (fieldName: string) => {
    callPRSetting(fieldName);
    return;
    if (fieldName === WORK_ORDER_PR_PO.PURCHASE_REQUISITION) {
      showAlert(
        'Warning!',
        'After enabling Purchase Requisition, you will not be able to change it.',
        [
          {
            title: 'Cancel',
            className: 'bg-white border-m mr-s',
            onClick: () => {}
          },
          {
            title: 'Proceed',
            className: 'bg-button text-white',
            onClick: () => {
              callPRSetting(fieldName);
            }
          }
        ]
      );
    } else {
      showAlert('', 'This setting cannot be changed.');
    }
  };

  const callPRSetting = (fieldName: string) => {
    switch (fieldName) {
      case WORK_ORDER_PR_PO.PURCHASE_ORDER:
        setGlobalWorkOrderForPoPr(WORK_ORDER_PR_PO.PURCHASE_ORDER);
        break;
      case WORK_ORDER_PR_PO.PURCHASE_REQUISITION:
        setGlobalWorkOrderForPoPr(WORK_ORDER_PR_PO.PURCHASE_REQUISITION);
        break;
    }

    let payload: any = {
      additionalSettings: {
        WO_STOCK_SHORTFALL_SETTING: fieldName
      }
    };

    TenantService.updateOrgSetting(payload)
      .then(async (res) => {
        await dispatch(fetchCurrentTenantInfo());
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const callMachineMaterialPRSetting = (fieldName: MACHINE_MATERIAL_PR_PO) => {
    switch (fieldName) {
      case MACHINE_MATERIAL_PR_PO.PURCHASE_ORDER:
        setGlobalMachineMaterialForPoPr(MACHINE_MATERIAL_PR_PO.PURCHASE_ORDER);
        break;
      case MACHINE_MATERIAL_PR_PO.PURCHASE_REQUISITION:
        setGlobalMachineMaterialForPoPr(
          MACHINE_MATERIAL_PR_PO.PURCHASE_REQUISITION
        );
        break;
    }

    let payload: any = {
      additionalSettings: {
        MACHINE_MATERIAL_SHORTFALL_SETTING: fieldName
      }
    };

    TenantService.updateOrgSetting(payload)
      .then(async (res) => {
        await dispatch(fetchCurrentTenantInfo());
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const woStockShortfallSetting = () => {
    return (
      <SettingsDetailCard className="mb-xl">
        <div className="column parent-width">
          <DKLabel text="Work Order Shortfall Setting" className="fw-m mb-s" />
          <div className="column">
            <div className="row">
              <DKRadioButton
                isSelected={
                  globalWorkOrderForPoPr === WORK_ORDER_PR_PO.PURCHASE_ORDER
                }
                onClick={() => onPRPOChange(WORK_ORDER_PR_PO.PURCHASE_ORDER)}
                className="mr-s"
                color={'bg-button'}
              />
              <DKLabel text="Purchase Order" />
            </div>
            <div className="row mt-s">
              <DKRadioButton
                isSelected={
                  globalWorkOrderForPoPr ===
                  WORK_ORDER_PR_PO.PURCHASE_REQUISITION
                }
                onClick={() =>
                  onPRPOChange(WORK_ORDER_PR_PO.PURCHASE_REQUISITION)
                }
                className="mr-s"
                color={'bg-button'}
              />
              <DKLabel text="Purchase Requisition" />
            </div>
          </div>
        </div>
      </SettingsDetailCard>
    );
  };

  const maintenanceShortfallSetting = () => {
    return (
      <SettingsDetailCard className="mb-xl">
        <div className="column parent-width">
          <DKLabel text="Maintenance Shortfall Setting" className="fw-m mb-s" />
          <div className="column">
            <div className="row">
              <DKRadioButton
                isSelected={
                  globalMachineMaterialForPoPr ===
                  MACHINE_MATERIAL_PR_PO.PURCHASE_ORDER
                }
                onClick={() =>
                  callMachineMaterialPRSetting(
                    MACHINE_MATERIAL_PR_PO.PURCHASE_ORDER
                  )
                }
                className="mr-s"
                color={'bg-button'}
              />
              <DKLabel text="Purchase Order" />
            </div>
            <div className="row mt-s">
              <DKRadioButton
                isSelected={
                  globalMachineMaterialForPoPr ===
                  MACHINE_MATERIAL_PR_PO.PURCHASE_REQUISITION
                }
                onClick={() =>
                  callMachineMaterialPRSetting(
                    MACHINE_MATERIAL_PR_PO.PURCHASE_REQUISITION
                  )
                }
                className="mr-s"
                color={'bg-button'}
              />
              <DKLabel text="Purchase Requisition" />
            </div>
          </div>
        </div>
      </SettingsDetailCard>
    );
  };

  //////////////////////////////////  Revenue Arrangement Creation setting - start   //////////////////////////////

  const revenueRecognitionSetting = () => {
    return (
      <SettingsDetailCard className="mb-xl">
        <div className="column parent-width">
          <DKLabel text="Revenue Arrangement Creation" className="fw-m mb-xs" />
          <div className="column">
            <div className="row">
              <DKRadioButton
                title="Automatic"
                isSelected={
                  revenueRecognitionOption ===
                  REV_REC_CREATION_SETTING.AUTOMATIC
                }
                onClick={() =>
                  callRevenueRecognitionOSetting(
                    REV_REC_CREATION_SETTING.AUTOMATIC
                  )
                }
                className="mr-s"
                color={'bg-button'}
              />
              <DKRadioButton
                title="Manual"
                isSelected={
                  revenueRecognitionOption === REV_REC_CREATION_SETTING.MANUAL
                }
                onClick={() =>
                  callRevenueRecognitionOSetting(
                    REV_REC_CREATION_SETTING.MANUAL
                  )
                }
                className="mr-s ml-l"
                color={'bg-button'}
              />
            </div>
          </div>
        </div>
      </SettingsDetailCard>
    );
  };

  const callRevenueRecognitionOSetting = (fieldName: string) => {
    switch (fieldName) {
      case REV_REC_CREATION_SETTING.AUTOMATIC:
        setRevenueRecognitionOption(REV_REC_CREATION_SETTING.AUTOMATIC);
        break;
      case REV_REC_CREATION_SETTING.MANUAL:
        setRevenueRecognitionOption(REV_REC_CREATION_SETTING.MANUAL);
        break;
    }

    let payload: any = {
      additionalSettings: {
        REV_REC_CREATION_SETTING: fieldName
      }
    };

    TenantService.updateOrgSetting(payload)
      .then(async (res) => {
        await dispatch(fetchCurrentTenantInfo());
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const mrpProduct = subscriptionDetails?.ProductsEnabled?.find(
    (product: any) => {
      return product === MRP_APP_NAME;
    }
  );
  //////////////////////////////////  Purchase Requisition Purchase Order setting - end    //////////////////////////////

  //////////////////////////////////  WO WH tagging setting - start  //////////////////////////////
  const getWorkOrderWahrehouseTaggingSetting = () => {
    const saveWorkOrderWahrehouseTaggingSetting = () => {
      const updatedStatus = !isWorkOrderWarehouseTaggingEnabled;
      let advancedSettingPayload = {
        additionalSettings: {
          ENABLE_WO_WAREHOUSE_TAGGING: updatedStatus
        }
      };
      TenantService.updateOrgSetting(advancedSettingPayload)
        .then((res) => {
          setShowWorkOrderWarehouseTaggingConfirmation(false);
          dispatch(fetchCurrentTenantInfo());
          setIsWorkOrderWarehouseTaggingEnabled(updatedStatus);
        })
        .catch((err) => {
          console.log(
            'Error updating work order warehouse tagging settings',
            err
          );
          setShowWorkOrderWarehouseTaggingConfirmation(false);
        });
    };
    const toggleWorkOrderWarehouseTagging = () => {
      WorkOrderService.areAllWorkOrdersCompleted().then(
        (isCompleted: boolean) => {
          if (isCompleted) {
            setShowWorkOrderWarehouseTaggingConfirmation(true);
          } else {
            showAlert(
              'Complete all Work Orders!',
              `Please complete all the existing Work Orders before you ${
                isWorkOrderWarehouseTaggingEnabled ? 'disable' : 'enable'
              } this setting`
            );
          }
        },
        (err: any) => {
          console.error('Error fetching all WO status: ', err);
        }
      );
    };
    return (
      <div className="row parent-width border-m border-radius-m gap-4 p-r mb-xl">
        <div className="column parent-width">
          <DKLabel
            text="Warehouse Tagging in Work Order"
            className="fw-m mb-xs"
          />
          <DKLabel
            className="text-gray"
            text="Once you enable this, all components needs to be present in the tagged warehouse, before you can allocate them to the Work Order"
            style={{ minWidth: 100 }}
          />
        </div>
        <div className="column" style={{ minWidth: 42 }}>
          <Toggle
            className="box-content"
            onChange={(checked: any) => {
              toggleWorkOrderWarehouseTagging();
            }}
            isOn={isWorkOrderWarehouseTaggingEnabled}
          />
        </div>
        {showWorkOrderWarehouseTaggingConfirmation && (
          <AdvancedSettingsConfirmationPopup
            title="Warehouse Tagging in Work Order"
            summary={
              !isWorkOrderWarehouseTaggingEnabled
                ? 'Once you enable this, all components needs to be present in the tagged warehouse, before you can allocate them to the Work Order.'
                : 'Once you disable this, all components can be allocated from any warehouse, before you can allocate them to the Work Order'
            }
            onCancel={() => setShowWorkOrderWarehouseTaggingConfirmation(false)}
            onConfirm={() => saveWorkOrderWahrehouseTaggingSetting()}
          />
        )}
      </div>
    );
  };
  //////////////////////////////////  WO WH tagging setting - end    //////////////////////////////

  //////////////////////////////////  WO RRB tagging setting - start  //////////////////////////////
  const getWorkOrderRRBTaggingSetting = () => {
    const saveWorkOrderRRBTaggingSetting = () => {
      const updatedStatus = !isWorkOrderRRBTaggingEnabled;
      let advancedSettingPayload = {
        additionalSettings: {
          ENABLE_WO_WAREHOUSE_BIN_TAGGING: updatedStatus,
          BIN_TAGGED_QUANTITY_THRESHOLD: 1,
          ENABLE_COMPONENT_AVAILABILITY_IN_TAGGED_BIN: false
        }
      };
      TenantService.updateOrgSetting(advancedSettingPayload)
        .then((res) => {
          dispatch(fetchCurrentTenantInfo());
          setIsWorkOrderRRBTaggingEnabled(updatedStatus);
        })
        .catch((err) => {
          console.log(
            'Error updating work order warehouse tagging settings',
            err
          );
        });
    };
    const saveBinMandatorySetting = () => {
      const updatedStatus = !isBinTaggingMandatory;
      let advancedSettingPayload = {
        additionalSettings: {
          ENABLE_COMPONENT_AVAILABILITY_IN_TAGGED_BIN: updatedStatus
        }
      };
      TenantService.updateOrgSetting(advancedSettingPayload)
        .then((res) => {
          setShowWorkOrderRRBTaggingConfirmation(false);
          dispatch(fetchCurrentTenantInfo());
          setIsBinTaggingMandatory(updatedStatus);
        })
        .catch((err) => {
          console.log(
            'Error updating work order warehouse tagging settings',
            err
          );
          setShowWorkOrderRRBTaggingConfirmation(false);
        });
    };
    const toggleWorkOrderRRBTagging = () => {
      WorkOrderService.areAllWorkOrdersCompleted().then(
        (isCompleted: boolean) => {
          if (!isWorkOrderRRBTaggingEnabled) {
            let isBinEnable = Utility.isEmpty(
              tenantDetails.additionalSettings.ROW_RACK_BIN?.filter(
                (ele: any) => ele.name === 'BIN' && ele.enable
              )
            );
            if (
              Utility.isEmpty(
                tenantDetails?.additionalSettings?.ROW_RACK_BIN
              ) ||
              isBinEnable
            ) {
              showAlert(
                'Enable Row Rack Bin!',
                `Please enable row rack bin setting before you ${
                  isWorkOrderRRBTaggingEnabled ? 'disable' : 'enable'
                } this setting`
              );
              return;
            }
          }
          if (isCompleted) {
            // setShowWorkOrderRRBTaggingConfirmation(true);
            saveWorkOrderRRBTaggingSetting();
          } else {
            showAlert(
              'Complete all Work Orders!',
              `Please complete all the existing Work Orders before you ${
                isWorkOrderRRBTaggingEnabled ? 'disable' : 'enable'
              } this setting`
            );
          }
        },
        (err: any) => {
          console.error('Error fetching all WO status: ', err);
        }
      );
    };
    const toggleBinMandatorySetting = () => {
      WorkOrderService.areAllWorkOrdersCompleted().then(
        (isCompleted: boolean) => {
          if (isCompleted) {
            setShowWorkOrderRRBTaggingConfirmation(true);
          } else {
            showAlert(
              'Complete all Work Orders!',
              `Please complete all the existing Work Orders before you ${
                isBinTaggingMandatory ? 'disable' : 'enable'
              } this setting`
            );
          }
        },
        (err: any) => {
          console.error('Error fetching all WO status: ', err);
        }
      );
    };
    return (
      <div className="column parent-width border-m border-radius-m gap-4 p-r mb-xl">
        <div className="row parent-width ">
          <div className="column parent-width">
            <DKLabel
              text="Row Rack Bin Tagging in Work Order"
              className="fw-m mb-xs"
            />
            <DKLabel
              className="text-gray"
              text="Enable this functionality to tag Bins to work orders. Generate barcodes for Bin numbers and search work orders/job cards by the BIN code to track production status"
              style={{ minWidth: 100 }}
            />
          </div>
          <div className="column" style={{ minWidth: 42 }}>
            <Toggle
              className="box-content"
              onChange={(checked: any) => {
                toggleWorkOrderRRBTagging();
              }}
              isOn={isWorkOrderRRBTaggingEnabled}
            />
          </div>
        </div>
        {showWorkOrderRRBTaggingConfirmation && (
          <AdvancedSettingsConfirmationPopup
            title="Enable component availability in tagged Bin to be mandatory"
            summary={
              !isBinTaggingMandatory
                ? 'Once you enable this, all components needs to be present in the tagged row rack bin, before you can allocate them to the Work Order.'
                : 'Once you disable this, all components can be allocated from any warehouse, before you can allocate them to the Work Order'
            }
            onCancel={() => setShowWorkOrderRRBTaggingConfirmation(false)}
            onConfirm={() => saveBinMandatorySetting()}
          />
        )}
        {tenantDetails?.additionalSettings?.ENABLE_WO_WAREHOUSE_BIN_TAGGING && (
          <div className="column parent-width  gap-3">
            <div className="row parent-width  gap-2">
              <div className="column parent-width">
                <DKLabel
                  text="Number Of Work Orders That Can Be Tagged Per Bin"
                  className="fw-m mb-s"
                />
                <div
                  className="row row-responsive align-items-start  w-12/12"
                  style={{ width: '50%' }}
                >
                  <DKInput
                    required={false}
                    canValidate={false}
                    style={{ width: 150 }}
                    readOnly={false}
                    title={''}
                    value={woTaggingThreshold}
                    onChange={(value: any) => {
                      if (!Number.isInteger(Number(value))) {
                        setWOTaggingThreshold('');
                      } else {
                        setWOTaggingThreshold(value);
                      }
                    }}
                    type={INPUT_TYPE.NUMBER}
                  />
                </div>
              </div>
              <div className="column mt-xl" style={{ minWidth: 42 }}>
                <DKButton
                  title={'Save'}
                  className="text-white bg-button"
                  width={'30px'}
                  onClick={() => saveWOTaggingThreshold(woTaggingThreshold)}
                />
              </div>
            </div>
            <div className="row parent-width gap-2">
              <div className="column parent-width">
                <DKLabel
                  text="Enable component availability in tagged Bin to be mandatory"
                  className="fw-m mb-xs"
                />
              </div>
              <div className="column" style={{ minWidth: 42 }}>
                <Toggle
                  className="box-content"
                  onChange={(checked: any) => {
                    toggleBinMandatorySetting();
                  }}
                  isOn={isBinTaggingMandatory}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    );
  };

  const saveWOTaggingThreshold = (data: any) => {
    // Payload for update additional settings
    let Payload: any = {
      additionalSettings: {
        BIN_TAGGED_QUANTITY_THRESHOLD: parseFloat(
          Number(data).toFixed(tenantDetails.decimalScale)
        )
      }
    };
    showLoader('Please wait...');

    TenantService.updateOrgSetting(Payload)
      .then((res) => {
        removeLoader();
        dispatch(fetchCurrentTenantInfo());
      })
      .catch((err) => {});
  };

  const BinTaggingThresholdSetting = () => {
    return (
      <div className="row parent-width border-m border-radius-m gap-4 p-r mb-xl">
        <div className="column parent-width">
          <DKLabel
            text="Number Of Work Orders That Can Be Tagged Per Bin"
            className="fw-m mb-s"
          />
          <div
            className="row row-responsive align-items-start  w-12/12"
            style={{ width: '50%' }}
          >
            <DKInput
              required={false}
              canValidate={false}
              style={{ width: 150 }}
              readOnly={false}
              title={''}
              value={woTaggingThreshold}
              onChange={(value: any) => {
                setWOTaggingThreshold(value);
              }}
              type={INPUT_TYPE.NUMBER}
            />
          </div>
        </div>
        <div className="column mt-xl" style={{ minWidth: 42 }}>
          <DKButton
            title={'Save'}
            className="text-white bg-button"
            width={'30px'}
            onClick={() => saveWOTaggingThreshold(woTaggingThreshold)}
          />
        </div>
      </div>
    );
  };
  //////////////////////////////////  WO RRB tagging setting - end    //////////////////////////////

  //////////////////////////////////  Product grouing setting - start  //////////////////////////////
  const getProductGroupingSetting = () => {
    const toggleProductGroupingSetting = () => {
      const updatedStatus = !isProductGroupingEnabled;
      let advancedSettingPayload = {
        additionalSettings: {
          PRODUCT_GROUP_ENABLED: updatedStatus
        }
      };
      TenantService.updateOrgSetting(advancedSettingPayload)
        .then((res) => {
          dispatch(fetchCurrentTenantInfo());
          setIsProductGroupingEnabled(updatedStatus);
        })
        .catch((err) => {
          console.error('Error updating product grouping settings', err);
        });
    };
    return (
      <div className="row parent-width border-m border-radius-m gap-4 p-r mb-xl">
        <div className="column parent-width">
          <DKLabel text="Enable Product Grouping" className="fw-m mb-xs" />
          <DKLabel
            className="text-gray"
            text="This will allow you to create sub-section at product level and can be used while selecting product in quotation, sales order, invoice, bill and purchase order."
            style={{ minWidth: 100 }}
          />
        </div>
        <div className="column" style={{ minWidth: 42 }}>
          <Toggle
            className="box-content"
            onChange={(checked: any) => {
              toggleProductGroupingSetting();
            }}
            isOn={isProductGroupingEnabled}
          />
        </div>
      </div>
    );
  };
  //////////////////////////////////  Product grouing setting - end    //////////////////////////////

  //////////////////////////////////  Tolerance level settings - start  //////////////////////////////

  const getTolereanceToggleSettings = () => {
    const getCheckMark = (title: string, key: string, cssClasses: string) => {
      const selected = Utility.isEmpty(toleranceSettingsData)
        ? false
        : toleranceSettingsData[key];
      return (
        <DKCheckMark
          title={title}
          isSelected={selected}
          onClick={() => {
            const checked = Utility.isEmpty(toleranceSettingsData)
              ? false
              : toleranceSettingsData[key];
            const updatedFormData = { ...toleranceSettingsData };
            if (
              key === 'isPercent' &&
              Utility.isEmptyValue(updatedFormData['percentValue'])
            ) {
              updatedFormData['percentValue'] =
                updatedFormData['quantityValue'];
            }
            setToleranceSettingsData({ ...updatedFormData, [key]: !checked });
            toggleToleranceSettings(isToleranceToggleEnabled, {
              ...updatedFormData,
              [key]: !checked
            });
          }}
          className={cssClasses}
        />
      );
    };

    return (
      <>
        <div className="row row-responsive gap-4">
          <div className={`column parent-width`}>
            <div
              className={`row gap-2 align-items-end ${
                toleranceSettingsData?.isPercent ? 'justify-content-evenly' : ''
              }`}
              style={{ width: '70%' }}
            >
              <div style={{ minWidth: 200 }}>
                <DKInput
                  title={`Enter Tolerance Value${
                    toleranceSettingsData?.isPercent ? '(%)' : ''
                  }`}
                  direction={INPUT_VIEW_DIRECTION.VERTICAL}
                  value={
                    toleranceSettingsData?.isPercent
                      ? isToleranceFocused
                        ? toleranceSettingsData?.percentValue
                        : NumberFormatService.getNumber(
                            toleranceSettingsData?.percentValue
                          )
                      : isToleranceFocused
                      ? toleranceSettingsData?.quantityValue
                      : NumberFormatService.getNumber(
                          toleranceSettingsData?.quantityValue
                        )
                  }
                  type={INPUT_TYPE.NUMBER}
                  canValidate={true}
                  required={true}
                  errorMessage={' '}
                  validator={(value: string) => {
                    if (toleranceSettingsData?.isPercent) {
                      if (
                        toleranceSettingsData?.percentValue <= 100 &&
                        toleranceSettingsData?.quantityValue >= 0
                      ) {
                        return true;
                      }
                      return false;
                    } else {
                      if (
                        toleranceSettingsData?.quantityValue === null ||
                        toleranceSettingsData?.quantityValue === undefined ||
                        value === ''
                      ) {
                        return false;
                      }
                    }
                    return true;
                  }}
                  onChange={(text: number) => {
                    if (toleranceSettingsData?.isPercent) {
                      setToleranceSettingsData({
                        ...toleranceSettingsData,
                        percentValue: text
                      });
                    } else {
                      setToleranceSettingsData({
                        ...toleranceSettingsData,
                        quantityValue: text
                      });
                    }
                  }}
                  onFocus={() => setIsToleranceFocused(true)}
                  onBlur={() => {
                    setIsToleranceFocused(false);
                    let toleranceData = {};
                    if (toleranceSettingsData?.isPercent) {
                      toleranceData = {
                        ...toleranceSettingsData,
                        percentValue: Utility.roundingOff(
                          toleranceSettingsData?.percentValue,
                          tenantDetails?.decimalScale
                            ? tenantDetails.decimalScale
                            : 2
                        )
                      };
                      setToleranceSettingsData(toleranceData);
                    } else {
                      toleranceData = {
                        ...toleranceSettingsData,
                        quantityValue: Utility.roundingOff(
                          toleranceSettingsData?.quantityValue,
                          tenantDetails?.decimalScale
                            ? tenantDetails.decimalScale
                            : 2
                        )
                      };
                      setToleranceSettingsData(toleranceData);
                    }
                    toggleToleranceSettings(
                      isToleranceToggleEnabled,
                      toleranceData
                    );
                  }}
                />
              </div>
              <div className="parent-width">
                <DKLabel
                  className="mb-2"
                  text={`${
                    toleranceSettingsData?.isPercent
                      ? 'of Total quantity'
                      : 'in addition to Total quantity'
                  }`}
                />
              </div>
            </div>
            {getCheckMark('Is percentage', 'isPercent', 'mt-2')}
          </div>
        </div>
      </>
    );
  };

  //////////////////////////////////  Tolerance level settings - end  //////////////////////////////

  //////////////////////////////////  Tolerance Toggle settings - start  //////////////////////////////

  const getToleranceToggle = () => {
    return (
      <>
        <div className="column parent-width border-m border-radius-m gap-4 p-r mb-xl">
          <div className="row">
            <div className="column parent-width">
              <div className="row">
                <DKLabel
                  text="Enable Tolerance Setting"
                  className="fw-m mb-xs"
                />
                {toleranceSaveInprogess && (
                  <DKSpinner iconClassName="ic-s-2" className="ml-s" />
                )}
              </div>
              <DKLabel
                className="text-gray"
                text="Allow to add tolerance level to a product quantity in sales and purchase transactions"
                style={{ minWidth: 100 }}
              />
            </div>
            <div className="column" style={{ minWidth: 42 }}>
              <Toggle
                className="box-content"
                onChange={(checked: any) => {
                  setIsToleranceToggleEnabled(!isToleranceToggleEnabled);
                  toggleToleranceSettings(
                    !isToleranceToggleEnabled,
                    toleranceSettingsData,
                    true
                  );
                }}
                isOn={isToleranceToggleEnabled}
              />
            </div>
          </div>
          {isToleranceToggleEnabled && getTolereanceToggleSettings()}
        </div>
      </>
    );
  };

  //////////////////////////////////  Forward Attachment - start  //////////////////////////////

  const updateFormwardAttachmentSetting = (docType: DOC_TYPE) => {
    if (updatingSetting !== SETTING_TYPE.NONE) {
      return;
    }
    setUpdatingSetting(SETTING_TYPE.FORWARD_ATTACHMENTS_SETTING);
    let FORWARD_ATTACHMENTS_SETTING = {
      forwardToQCDocument:
        tenantDetails?.additionalSettings?.FORWARD_ATTACHMENTS_SETTING
          ?.forwardToQCDocument,
      forwardToPurchaseOrder:
        tenantDetails?.additionalSettings?.FORWARD_ATTACHMENTS_SETTING
          ?.forwardToPurchaseOrder,
      forwardToPurchaseBill:
        tenantDetails?.additionalSettings?.FORWARD_ATTACHMENTS_SETTING
          ?.forwardToPurchaseBill,
      forwardToWorkOrder:
        tenantDetails?.additionalSettings?.FORWARD_ATTACHMENTS_SETTING
          ?.forwardToWorkOrder
    };
    switch (docType) {
      case DOC_TYPE.QC_DOCUMENT:
        FORWARD_ATTACHMENTS_SETTING.forwardToQCDocument =
          !tenantDetails?.additionalSettings?.FORWARD_ATTACHMENTS_SETTING
            ?.forwardToQCDocument;
        break;
      case DOC_TYPE.ORDER:
        FORWARD_ATTACHMENTS_SETTING.forwardToPurchaseOrder =
          !tenantDetails?.additionalSettings?.FORWARD_ATTACHMENTS_SETTING
            ?.forwardToPurchaseOrder;
        break;
      case DOC_TYPE.BILL:
        FORWARD_ATTACHMENTS_SETTING.forwardToPurchaseBill =
          !tenantDetails?.additionalSettings?.FORWARD_ATTACHMENTS_SETTING
            ?.forwardToPurchaseBill;
        break;
      case DOC_TYPE.WORK_ORDER:
        FORWARD_ATTACHMENTS_SETTING.forwardToWorkOrder =
          !tenantDetails?.additionalSettings?.FORWARD_ATTACHMENTS_SETTING
            ?.forwardToWorkOrder;
        break;
    }
    // FORWARD_ATTACHMENTS_SETTING
    const advancedSettingPayload = {
      additionalSettings: {
        FORWARD_ATTACHMENTS_SETTING: FORWARD_ATTACHMENTS_SETTING
      }
    };
    TenantService.updateOrgSetting(advancedSettingPayload)
      .then(async (res) => {
        await dispatch(fetchCurrentTenantInfo());
        setUpdatingSetting(SETTING_TYPE.NONE);
      })
      .catch((err) => {
        console.error('Error updating tolerance settings', err);
        setUpdatingSetting(SETTING_TYPE.NONE);
      });
  };

  const checkedDeniedParties = () => {
    const payload = {
      additionalSettings: {
        DENIED_PARTIES_SETTING: !checkDeniedParties
      }
    };
    showLoader('Please wait...');
    TenantService.updateOrgSetting(payload)
      .then((res: any) => {
        removeLoader();
        const tenantDetailsResponse = res;
        setCheckDeniedParties(
          tenantDetailsResponse?.additionalSettings.DENIED_PARTIES_SETTING ??
            false
        );
        dispatch(fetchCurrentTenantInfo());
      })
      .catch((err: any) => {
        removeLoader();
        console.error('Error updating denied parties setting: ', err);
      });
  };

  ///////////////////////////////////////////////////////////
  const getReorderLevelToggleView = () => {
    const updateReorderLevelSetting = () => {
      const payload = {
        additionalSettings: {
          CHECK_INCOMING_STOCK_TO_ENABLE_REORDER_LEVEL_ALERT:
            !checkIncomingStockForReorderLevel
        }
      };
      showLoader('Please wait...');
      TenantService.updateOrgSetting(payload)
        .then((res: any) => {
          removeLoader();
          const tenantDetailsResponse = res;
          setCheckIncomingStockForReorderLevel(
            tenantDetailsResponse?.additionalSettings
              ?.CHECK_INCOMING_STOCK_TO_ENABLE_REORDER_LEVEL_ALERT || false
          );
          dispatch(fetchCurrentTenantInfo());
        })
        .catch((err: any) => {
          removeLoader();
          console.error(
            'Error updating work order auto allocation setting: ',
            err
          );
        });
    };

    return (
      <div className="row parent-width border-m border-radius-m gap-4 p-r mb-xl">
        <div className="column parent-width">
          <DKLabel
            text="Check Incoming Stock To Enable Reorder Level Alert"
            className="fw-m mb-xs"
          />
          {/* <DKLabel
            className="text-gray"
            text='Enable Auto Allocation of Components/WIP when user clicks on "Start Work Order"'
            style={{ minWidth: 100 }}
          /> */}
        </div>
        <div className="column" style={{ minWidth: 42 }}>
          <Toggle
            className="box-content"
            onChange={(checked: any) => {
              updateReorderLevelSetting();
            }}
            isOn={checkIncomingStockForReorderLevel}
          />
        </div>
      </div>
    );
  };
  ///////////////////////////////////////////////////////////

  const getForwardAttachmentSetting = () => {
    return (
      <div className="column parent-width border-m border-radius-m gap-4 p-r mb-xl">
        <div className="column parent-width">
          <div className="row">
            <DKLabel
              text="Forward Product Attachments"
              className="fw-m mb-xs"
            />
            {updatingSetting === SETTING_TYPE.FORWARD_ATTACHMENTS_SETTING && (
              <DKSpinner iconClassName="ic-s-2" className="ml-s" />
            )}
          </div>
          <div className="row justify-content-between p-v-xs gap-4">
            <DKLabel
              className="text-gray"
              text="Include Product Attachments in Quality Check"
              style={{ minWidth: 100 }}
            />
            <div className="column" style={{ minWidth: 42 }}>
              <Toggle
                className="box-content"
                onChange={(checked: any) => {
                  updateFormwardAttachmentSetting(DOC_TYPE.QC_DOCUMENT);
                }}
                isOn={
                  tenantDetails?.additionalSettings?.FORWARD_ATTACHMENTS_SETTING
                    ?.forwardToQCDocument
                }
              />
            </div>
          </div>
          <div className="row justify-content-between p-v-xs gap-4">
            <DKLabel
              className="text-gray"
              text="Include Product Attachments in Purchase Order"
              style={{ minWidth: 100 }}
            />
            <div className="column" style={{ minWidth: 42 }}>
              <Toggle
                className="box-content"
                onChange={(checked: any) => {
                  updateFormwardAttachmentSetting(DOC_TYPE.ORDER);
                }}
                isOn={
                  tenantDetails?.additionalSettings?.FORWARD_ATTACHMENTS_SETTING
                    ?.forwardToPurchaseOrder
                }
              />
            </div>
          </div>{' '}
          <div className="row justify-content-between p-v-xs gap-4">
            <DKLabel
              className="text-gray"
              text="Include Product Attachments in Purchase Bill"
              style={{ minWidth: 100 }}
            />
            <div className="column" style={{ minWidth: 42 }}>
              <Toggle
                className="box-content"
                onChange={(checked: any) => {
                  updateFormwardAttachmentSetting(DOC_TYPE.BILL);
                }}
                isOn={
                  tenantDetails?.additionalSettings?.FORWARD_ATTACHMENTS_SETTING
                    ?.forwardToPurchaseBill
                }
              />
            </div>
          </div>{' '}
          {Utility.isMRPWithURLCheck() && (
            <div className="row justify-content-between p-v-xs gap-4">
              <DKLabel
                className="text-gray"
                text="Include Product Attachments in Work Order"
                style={{ minWidth: 100 }}
              />
              <div className="column" style={{ minWidth: 42 }}>
                <Toggle
                  className="box-content"
                  onChange={(checked: any) => {
                    updateFormwardAttachmentSetting(DOC_TYPE.WORK_ORDER);
                  }}
                  isOn={
                    tenantDetails?.additionalSettings
                      ?.FORWARD_ATTACHMENTS_SETTING?.forwardToWorkOrder
                  }
                />
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };

  //////////////////////////////////  Forward Attachment - end  //////////////////////////////

  const getBankFileGenerationToggle = () => {
    const onSaveBankFileGeneration = (toggleValue: boolean, ftpData?: any) => {
      let bankFileGeneration = {
        enabled: toggleValue
      };

      if (ftpData) {
        bankFileGeneration = {
          ...ftpData,
          enabled: toggleValue,
          bankFileGenerationType: bankFileGenerationType
        };
      }

      const advancedSettingPayload = {
        additionalSettings: {
          BANK_FILE_GENERATION: bankFileGeneration
        }
      };

      showLoader('Please wait...');
      TenantService.updateOrgSetting(advancedSettingPayload).then(
        () => {
          removeLoader();
          setFTPConfigurationPopup(false);
          dispatch(fetchCurrentTenantInfo());
        },
        (err) => {
          removeLoader();
          console.log('Error updating bank file generation', err);
        }
      );
    };

    return (
      <>
        <div className="column parent-width border-m border-radius-m p-r mb-xl">
          <div className="row">
            <div className="column parent-width">
              <div className="row">
                <DKLabel
                  text="Enable Bank File Generation"
                  className="fw-m mb-xs"
                />
              </div>
              <DKLabel
                className="text-gray"
                text="Allow to generate bank files against paid invoices and upload to configured FTP server"
                style={{ minWidth: 100 }}
              />
            </div>
            <div className="column" style={{ minWidth: 42 }}>
              <Toggle
                className="box-content"
                isOn={isBankFileGenerationEnabled}
                onChange={() => {
                  const toggleValue = !isBankFileGenerationEnabled;
                  setIsBankFileGenerationEnabled(toggleValue);
                  if (!toggleValue) {
                    onSaveBankFileGeneration(toggleValue);
                  }
                }}
              />
            </div>
          </div>
          {isBankFileGenerationEnabled && (
            <div className="row mt-r justify-content-between">
              <div className="column" style={{ width: '50%' }}>
                <DKInput
                  title="Bank File Configuration Type"
                  readOnly={false}
                  onChange={(data: any) => {
                    setBankFileGenerationType(data.value);
                  }}
                  value={
                    bankFileGenerationTypeOptions?.find(
                      (obj: any) => obj.value === bankFileGenerationType
                    ) || null
                  }
                  direction={INPUT_VIEW_DIRECTION.VERTICAL}
                  type={INPUT_TYPE.DROPDOWN}
                  formatter={(obj: any) => {
                    return obj.label;
                  }}
                  canValidate={false}
                  className="mt-m"
                  dropdownConfig={{
                    title: '',
                    allowSearch: false,
                    searchableKey: '',
                    style: { minWidth: 150 },
                    className: 'shadow-m width-auto',
                    searchApiConfig: null,
                    data: bankFileGenerationTypeOptions || [],
                    renderer: (index: any, obj: any) => {
                      return <DKLabel text={`${obj.label}`} />;
                    }
                  }}
                />
              </div>
              {bankFileGenerationType === BANK_FILE_GENERATION_TYPE.CHASE && (
                <DKButton
                  title="FTP Configuration"
                  className="bg-white text-bg-button-color cursor-hand mt-xxl"
                  style={{ padding: 0, borderRadius: 6 }}
                  onClick={() => {
                    setFTPConfigurationPopup(true);
                  }}
                />
              )}
            </div>
          )}
          {showFTPConfigurationPopup && (
            <FTPConfigurationPopup
              onClose={() => {
                setFTPConfigurationPopup(false);
              }}
              bankFileData={
                tenantDetails?.additionalSettings?.BANK_FILE_GENERATION
              }
              onUpdate={(data: any) => {
                onSaveBankFileGeneration(true, data);
              }}
            />
          )}
        </div>
      </>
    );
  };

  //////////////////////////////////  Tolerance Toggle settings - end  //////////////////////////////

  /**
   *
   * @returns - HTML template to toggle between Security Gate Entry Transaction
   * @description- If enabled, user can add Entry Gate Transaction from left menu
   */
  const renderEntryGateSecuritySettingToggle = () => {
    return (
      <div className="row parent-width border-m border-radius-m gap-4 p-r mb-xl">
        <div className="column parent-width">
          <DKLabel
            text="Security Gate Entry Transaction"
            className="fw-m mb-xs"
          />
          <DKLabel
            className="text-gray"
            text="Allow adding security gate entry transaction"
            style={{ minWidth: 100 }}
          />
        </div>
        <div className="column" style={{ minWidth: 42 }}>
          <Toggle
            className="box-content"
            onChange={(checked: any) => {
              updateSecurityEntryGateSettings(!isEntryGateTransactionEnabled);
              toggleEntryGateTransaction(!isEntryGateTransactionEnabled);
            }}
            isOn={isEntryGateTransactionEnabled}
          />
        </div>
      </div>
    );
  };

  const updateSecurityEntryGateSettings = (flag: boolean) => {
    let advancedSettingPayload = {
      additionalSettings: {
        SECURITY_GATE_ENTRY: flag
      }
    };
    updateOrganizationSettings(advancedSettingPayload);
  };

  /**
   * @description - This function updates organization settings
   * @param {payload} - pass partial object that needs to be updated
   */
  const updateOrganizationSettings = (payload: any) => {
    showLoader('Please wait...');
    return TenantService.updateOrgSetting(payload)
      .then((res) => {
        removeLoader();
        dispatch(fetchCurrentTenantInfo());
        return Promise.resolve(res);
      })
      .catch((err) => {
        removeLoader();
      });
  };

  const getDeniedPartyTransactionSetting = () => {
    return (
      <div className="row parent-width border-m border-radius-m gap-4 p-r mb-xl">
        <div className="column parent-width">
          <DKLabel
            text="Enable Check For Denied Party List"
            className="fw-m mb-xs"
          />
        </div>
        <div className="column" style={{ minWidth: 42 }}>
          <Toggle
            className="box-content"
            onChange={(checked: any) => {
              checkedDeniedParties();
            }}
            isOn={checkDeniedParties}
          />
        </div>
      </div>
    );
  };

  //////////////////////////Approval Inventory Transations  start//////////////////////////
  const getInventoryTransactionApprovalSetting = () => {
    return (
      <div className="column parent-width border-m border-radius-m gap-4 p-r mb-xl">
        <div className="column parent-width">
          <div className="row">
            <DKLabel
              text="Approval for Inventory Transactions"
              className="fw-m mb-xs"
            />
            {updatingSetting === SETTING_TYPE.INVENTORY_APPROVAL_SETTING && (
              <DKSpinner iconClassName="ic-s-2" className="ml-s" />
            )}
          </div>
          <div className="row justify-content-between p-v-xs gap-4">
            <DKLabel
              className="text-gray"
              text="Stock Adjustment"
              style={{ minWidth: 100 }}
            />
            <div className="column" style={{ minWidth: 42 }}>
              <Toggle
                className="box-content"
                onChange={(checked: any) => {
                  updateInventoryTransactionApprovalSetting(
                    DOC_TYPE.STOCK_ADJUSTMENT,
                    checked
                  );
                }}
                isOn={
                  tenantDetails?.additionalSettings?.INVENTORY_APPROVAL_SETTING?.find(
                    (value: any) => value.module === DOC_TYPE.STOCK_ADJUSTMENT
                  )?.enable
                }
              />
            </div>
          </div>
          <div className="row justify-content-between p-v-xs gap-4">
            <DKLabel
              className="text-gray"
              text="Stock Transfer"
              style={{ minWidth: 100 }}
            />
            <div className="column" style={{ minWidth: 42 }}>
              <Toggle
                className="box-content"
                onChange={(checked: any) => {
                  updateInventoryTransactionApprovalSetting(
                    DOC_TYPE.STOCK_TRANSFER,
                    checked
                  );
                }}
                isOn={
                  tenantDetails?.additionalSettings?.INVENTORY_APPROVAL_SETTING?.find(
                    (value: any) => value.module === DOC_TYPE.STOCK_TRANSFER
                  )?.enable
                }
              />
            </div>
          </div>
          {Utility.isMRPWithURLCheck() && (
            <div className="row justify-content-between p-v-xs gap-4">
              <DKLabel
                className="text-gray"
                text="Stock Request"
                style={{ minWidth: 100 }}
              />
              <div className="column" style={{ minWidth: 42 }}>
                <Toggle
                  className="box-content"
                  onChange={(checked: any) => {
                    updateInventoryTransactionApprovalSetting(
                      DOC_TYPE.STOCK_REQUEST,
                      checked
                    );
                  }}
                  isOn={
                    tenantDetails?.additionalSettings?.INVENTORY_APPROVAL_SETTING?.find(
                      (value: any) => value.module === DOC_TYPE.STOCK_REQUEST
                    )?.enable
                  }
                />
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };

  const updateInventoryTransactionApprovalSetting = (
    docType: DOC_TYPE,
    checked: any
  ) => {
    if (updatingSetting !== SETTING_TYPE.NONE) {
      return;
    }
    setUpdatingSetting(SETTING_TYPE.INVENTORY_APPROVAL_SETTING);
    let settingArray =
      tenantDetails?.additionalSettings?.INVENTORY_APPROVAL_SETTING;
    let payload: any;
    switch (docType) {
      case DOC_TYPE.STOCK_ADJUSTMENT:
        let val =
          tenantDetails?.additionalSettings?.INVENTORY_APPROVAL_SETTING?.find(
            (value: any) => value.module === DOC_TYPE.STOCK_ADJUSTMENT
          )?.enable;
        let obj = {
          module: DOC_TYPE.STOCK_ADJUSTMENT,
          enable: !val
        };
        payload = Utility.isEmpty(settingArray)
          ? [obj]
          : toggleInventoryApproval(
              settingArray,
              DOC_TYPE.STOCK_ADJUSTMENT,
              obj
            );
        break;
      case DOC_TYPE.STOCK_REQUEST:
        let req_val =
          tenantDetails?.additionalSettings?.INVENTORY_APPROVAL_SETTING?.find(
            (value: any) => value.module === DOC_TYPE.STOCK_REQUEST
          )?.enable;
        let req_obj = {
          module: DOC_TYPE.STOCK_REQUEST,
          enable: !req_val
        };
        payload = Utility.isEmpty(settingArray)
          ? [req_obj]
          : toggleInventoryApproval(
              settingArray,
              DOC_TYPE.STOCK_REQUEST,
              req_obj
            );

        break;
      case DOC_TYPE.STOCK_ISSUE:
        let issue_val =
          tenantDetails?.additionalSettings?.INVENTORY_APPROVAL_SETTING?.find(
            (value: any) => value.module === DOC_TYPE.STOCK_ISSUE
          )?.enable;
        let issue_obj = {
          module: DOC_TYPE.STOCK_ISSUE,
          enable: issue_val
        };
        payload = Utility.isEmpty(settingArray)
          ? [issue_obj]
          : toggleInventoryApproval(
              settingArray,
              DOC_TYPE.STOCK_ISSUE,
              issue_obj
            );

        break;
      case DOC_TYPE.STOCK_TRANSFER:
        let transfer_val =
          tenantDetails?.additionalSettings?.INVENTORY_APPROVAL_SETTING?.find(
            (value: any) => value.module === DOC_TYPE.STOCK_TRANSFER
          )?.enable;
        let transfer_obj = {
          module: DOC_TYPE.STOCK_TRANSFER,
          enable: transfer_val
        };
        payload = Utility.isEmpty(settingArray)
          ? [transfer_obj]
          : toggleInventoryApproval(
              settingArray,
              DOC_TYPE.STOCK_TRANSFER,
              transfer_obj
            );

        break;
    }
    const advancedSettingPayload = {
      additionalSettings: {
        INVENTORY_APPROVAL_SETTING: payload
      }
    };
    TenantService.updateOrgSetting(advancedSettingPayload)
      .then(async (res: any) => {
        let val = res?.additionalSettings?.INVENTORY_APPROVAL_SETTING?.find(
          (value: any) => value.module === docType
        )?.enable;
        if (val) {
          dispatch(fetchapprovalConditionList(true));
        }
        await dispatch(fetchCurrentTenantInfo());
        if (val) {
          inventoryApprovalAutomationUpdate(docType);
        }
        setUpdatingSetting(SETTING_TYPE.NONE);
      })
      .catch((err) => {
        console.error('Error updating Inventory approval settings', err);
        setUpdatingSetting(SETTING_TYPE.NONE);
      });
  };
  const toggleInventoryApproval = (
    array: any,
    docType: any,
    newSetting: any
  ) => {
    if (!array.find((obj: any) => obj.module === docType)) {
      return [...array, newSetting];
    } else {
      return array.map((obj: any) => {
        if (obj.module === docType) {
          return { ...obj, enable: !obj.enable };
        } else {
          return obj;
        }
      });
    }
  };

  const inventoryApprovalAutomationUpdate = (docType: any) => {
    let { template, configuration, title } =
      getApprovalUpdatedTemplate(docType);
    if (template?.steps && Array.isArray(template.steps)) {
      const approvalStep = template.steps.find(
        (step: any) => step.type === AUTOMATION_ACTIONS.BOOKS_APPROVAL
      );
      if (approvalStep) {
        approvalStep['configuration'] = [configuration];
      }
    }

    template = Utility.encodeJSON(template);
    const data: any = {};
    data[getColumnId(COLUMN_CODE.AUTOMATION.NAME)] = title;
    data[getColumnId(COLUMN_CODE.AUTOMATION.JSON_DATA)] = template;
    data[getColumnId(COLUMN_CODE.AUTOMATION.STATUS)] = STATUS_OPTIONS.ACTIVE;
    data[getColumnId(COLUMN_CODE.AUTOMATION.CONNECTION_ID)] =
      AutomationService.connectionId;

    if (AutomationService.connectionId) {
      checkAndSaveApprovalAutomation(data, docType);
    } else {
      AutomationService.getAllConnections().then(
        (res) => {
          data[getColumnId(COLUMN_CODE.AUTOMATION.CONNECTION_ID)] =
            AutomationService.connectionId;
          checkAndSaveApprovalAutomation(data, docType);
        },
        (err) => {
          removeLoader();
          console.error('Error loading existing connections: ', err);
        }
      );
    }
  };

  const checkAndSaveApprovalAutomation = async (data: any, docType: any) => {
    let booksApprovalList = Store.getState().automation.data;
    let { template, configuration, title } =
      getApprovalUpdatedTemplate(docType);
    if (template?.steps && Array.isArray(template.steps)) {
      const approvalStep = template.steps.find(
        (step: any) => step.type === AUTOMATION_ACTIONS.BOOKS_APPROVAL
      );
      if (approvalStep) {
        approvalStep['configuration'] = [configuration];
      }

      if (Utility.isNotEmpty(approvalStep)) {
        let doc_type = approvalStep['configuration']?.[0].documentType;
        if (doc_type === docType) {
          let isInventoryApprovalAutomationPresent = booksApprovalList?.filter(
            (ele: any) => ele.fields[0].field_type === docType
          );
          if (isInventoryApprovalAutomationPresent?.length > 0) {
            setUpdatingSetting(SETTING_TYPE.NONE);
            return;
          } else {
            setUpdatingSetting(SETTING_TYPE.INVENTORY_APPROVAL_SETTING);
            AutomationService.save(
              data,
              async (data: any) => {
                await dispatch(fetchapprovalConditionList(true));
                setUpdatingSetting(SETTING_TYPE.NONE);
              },
              (error: any) => {
                setUpdatingSetting(SETTING_TYPE.NONE);
              }
            );
            // AutomationService.save(data);
          }
        }
      }
    }
  };

  const getApprovalUpdatedTemplate = (docType: any) => {
    let defaultApprovalTrigger: any = '';
    let title: any = '';
    switch (docType) {
      case DOC_TYPE.STOCK_ADJUSTMENT:
        defaultApprovalTrigger = Templates.stockAdjustmentApprovalTrigger;
        title = 'New Stock Adjustment Approval Automation';
        break;
      case DOC_TYPE.STOCK_REQUEST:
        defaultApprovalTrigger = Templates.stockRequestApprovalTrigger;
        title = 'New Stock Request Approval Automation';
        break;
      case DOC_TYPE.STOCK_ISSUE:
        defaultApprovalTrigger = Templates.stockIssueApprovalTrigger;
        title = 'New Stock Issue Approval Automation';
        break;
      case DOC_TYPE.STOCK_TRANSFER:
        defaultApprovalTrigger = Templates.stockTransferApprovalTrigger;
        title = 'New Stock Transfer Approval Automation';
        break;
    }
    let template = Utility.decodeJSON(defaultApprovalTrigger);
    let userDetails = AuthService.getUserDetails();
    const configuration = {
      documentType: docType,
      approvalFor: '',
      approver: '',
      multiApprovalDetails: [
        {
          level: 1,
          levelLabel: 'Level 1',
          condition: 'ANY',
          approver: [userDetails.email]
        }
      ],
      reminderDays: '7',
      fields: [],
      logicalOperator: 'and'
    };

    return { template, configuration, title };
  };
  //////////////////////////Approval Inventory Transations end//////////////////////////

  const getDraftDocumentNumberAllocationSetting = () => {
    return (
      <div className="column parent-width border-m border-radius-m gap-4 p-r mb-xl">
        <div className="column parent-width">
          <div className="row">
            <DKLabel text="Draft Settings" className="fw-m mb-xs" />
          </div>
          <div className="row justify-content-between p-v-xs gap-4">
            <DKLabel
              className="text-gray"
              text="Document Number Allocation"
              style={{ minWidth: 100 }}
            />
            <div className="column" style={{ minWidth: 42 }}>
              <Toggle
                className="box-content"
                onChange={() => updateDraftDocumentNumberAllocationSetting()}
                isOn={
                  !!tenantDetails?.additionalSettings
                    ?.DRAFT_DOCUMENT_NUMBER_ALLOCATION
                }
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  const updateDraftDocumentNumberAllocationSetting = () => {
    const payload = {
      additionalSettings: {
        DRAFT_DOCUMENT_NUMBER_ALLOCATION:
          !tenantDetails?.additionalSettings?.DRAFT_DOCUMENT_NUMBER_ALLOCATION
      }
    };
    showLoader('Please wait...');
    TenantService.updateOrgSetting(payload)
      .then(async () => await dispatch(fetchCurrentTenantInfo()))
      .catch((err: any) => {
        console.error(
          'Error updating "Document number allocation" setting: ',
          err
        );
      })
      .finally(() => removeLoader());
  };


  function getAdvancedSettingPayloadForBillBasedStockValuation(
    updatedAdditionalSettings = {},
    newValue: boolean
  ) {
    return {
      additionalSettings: {
        ENABLE_BILL_BASED_STOCK_VALUATION: newValue,
        ...updatedAdditionalSettings
      }
    };
  }

 
      
  const updateOrgSetting = (advancedSettingPayload: any) => {
    showLoader('Please wait...');
    TenantService.updateOrgSetting(advancedSettingPayload)
      .then(() => {
        removeLoader();
        dispatch(fetchCurrentTenantInfo());
      })
      .catch((err) => {
        removeLoader();
        console.error(
          'Error updating Bill Based Stock Valuation setting ',
          err
        );
      });
  };
    

  const getBillBasedStockValuationToggle = () => {
    if (!isBillBasedStockValuationHide) {
      return;
    }

    const onBillBasedStockValuation = () => {
      const value = !(!!isBillBasedStockValuationEnabled);
      const additionalChargeSetting = tenantDetails?.additionalSettings?.ADDITIONAL_CHARGE;
    
      const handleApportionFlagEnabled = () => {
        const alertMsg = 'If you enable this setting, the setting named "Apporting additional charges on line item" will be disabled.';
        const alertMsgDescription = 'Please confirm if you are okay to proceed?';
        const buttons = [
          { title: 'No', className: 'bg-gray2 border-m', onClick: () => {} },
          {
            title: 'Ok, Proceed',
            className: 'bg-blue text-white ml-r',
            onClick: () => {
              const updatedSettings = {
                ADDITIONAL_CHARGE: {
                  ...additionalChargeSetting,
                  apportionFlag: false,
                  apportionValue: null,
                  independentCharge: true,
                }
              };
              updateOrgSetting(getAdvancedSettingPayloadForBillBasedStockValuation(updatedSettings, !isBillBasedStockValuationEnabled));
            }
          }
        ];
    
        showAlert(alertMsg, alertMsgDescription, buttons);
      };
    
      if (value) {
        if (additionalChargeSetting?.apportionFlag) {
          handleApportionFlagEnabled();
        } else {
          updateOrgSetting(
            getAdvancedSettingPayloadForBillBasedStockValuation({}, !isBillBasedStockValuationEnabled)
          );
        }
      } else {
        updateOrgSetting(
          getAdvancedSettingPayloadForBillBasedStockValuation({}, !isBillBasedStockValuationEnabled)
        );
      }
    };
    

    return (
      <>
        <div className="column parent-width border-m border-radius-m p-r mb-xl">
          <div className="row">
            <div className="column parent-width">
              <div className="row">
                <DKLabel
                  text={t(
                    'SETTINGS.ADVANCE_SETTINGS.BILL_BASED_STOCK_VALUATION'
                  )}
                  className="fw-m mb-xs"
                />
              </div>
              <DKLabel
                className="text-gray"
                text={t(
                  'SETTINGS.ADVANCE_SETTINGS.BILL_BASED_STOCK_VALUATION_TEXT'
                )}
                style={{ minWidth: 100 }}
              />
            </div>
            <div className="column" style={{ minWidth: 42 }}>
              <Toggle
                className="box-content"
                isOn={isBillBasedStockValuationEnabled}
                onChange={() => {
                  onBillBasedStockValuation();
                }}
              />
            </div>
          </div>
        </div>
      </>
    );
  };

  const enablePreferredVendorDetailsToggle = () => {
    const onToggleClick = () => {
      const advancedSettingPayload = {
        additionalSettings: {
          ENABLE_PREFERRED_VENDOR_DETAILS_PRODUCT:
            !isPreferredVendorDetailsEnabled
        }
      };

      showLoader('Please wait...');
      TenantService.updateOrgSetting(advancedSettingPayload).then(
        () => {
          removeLoader();
          dispatch(fetchCurrentTenantInfo());
        },
        (err) => {
          removeLoader();
          console.log('Error updating Preferred vendor details setting', err);
        }
      );
    };

    return (
      <>
        <div className="column parent-width border-m border-radius-m p-r mb-xl">
          <div className="row">
            <div className="column parent-width">
              <div className="row">
                <DKLabel
                  text={t(
                    'SETTINGS.ADVANCE_SETTINGS.ENABLE_PREFERRED_VENDOR_DETAILS_PRODUCT'
                  )}
                  className="fw-m mb-xs"
                />
              </div>
              <DKLabel
                className="text-gray"
                text={t(
                  'SETTINGS.ADVANCE_SETTINGS.ENABLE_PREFERRED_VENDOR_DETAILS_PRODUCT_TEXT'
                )}
                style={{ minWidth: 100 }}
              />
            </div>
            <div className="column" style={{ minWidth: 42 }}>
              <Toggle
                className="box-content"
                isOn={isPreferredVendorDetailsEnabled}
                onChange={() => {
                  onToggleClick();
                }}
              />
            </div>
          </div>
        </div>
      </>
    );
  };

  const getSalesModulesShortfallAlertSetting = () => {
    return (
      <div className="column parent-width border-m border-radius-m gap-4 p-r mb-xl">
        <div className="column parent-width">
          <div className="row">
            <DKLabel
              text="Shortfall alerts in Sales Documents"
              className="fw-m mb-xs"
            />
          </div>
          <div className="row justify-content-between p-v-xs gap-4">
            <DKLabel
              className="text-gray"
              text="This setting enables shortfall alerts in sales documents"
              style={{ minWidth: 100 }}
            />
            <div className="column" style={{ minWidth: 42 }}>
              <Toggle
                className="box-content"
                onChange={() => updateSalesModuleShorfallAlertSetting()}
                isOn={!!isSalesModuleShortfallAlertEnabled}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };
  const getPartialJobWorkout = () => {
    return (
      <div className="row parent-width border-m border-radius-m gap-4 p-r mb-xl">
        <div className="column parent-width">
          <DKLabel
            text={t('JOB_WORK_OUT.JOB_WORK_OUT_ADV_SETTING_TITLE')}
            className="fw-m mb-xs"
          />
          <DKLabel
            className="text-gray"
            text={t('JOB_WORK_OUT.JOB_WORK_OUT_ADV_SETTING_DESCRIPTION')}
            style={{ minWidth: 100 }}
          />
        </div>
        <div className="column" style={{ minWidth: 42 }}>
          <Toggle
            className="box-content"
            onChange={(checked: any) => {
              callDocumentEdit(DOCUMENT_TYPE.JOB_WORK_OUT);
            }}
            isOn={isJWOReceiveDispatchAdditionalQty}
          />
        </div>
      </div>
    );
  };

  const updateSalesModuleShorfallAlertSetting = () => {
    const payload = {
      additionalSettings: {
        ALERT_ON_SHORTFALL_IN_SALES_MODULES:
          !tenantDetails?.additionalSettings
            ?.ALERT_ON_SHORTFALL_IN_SALES_MODULES
      }
    };
    showLoader('Please wait...');
    TenantService.updateOrgSetting(payload)
      .then(async () => {
        await dispatch(fetchCurrentTenantInfo());
      })
      .catch((err: any) => {
        console.error(
          'Error updating Sales Module Shortfall Alert setting: ',
          err
        );
      })
      .finally(() => removeLoader());
  };

  const getWOSufficientInsuffocientQtySettings = () => {
    return (
      <div className="column parent-width border-m border-radius-m gap-4 p-r mb-xl">
        <div className="column parent-width">
          <div className="row">
            <DKLabel
              text="Show Sufficient/Insufficient Status for Components in Work Order Grid"
              className="fw-m mb-xs"
            />
          </div>
          <div className="row justify-content-between p-v-xs gap-4">
            <DKLabel
              className="text-gray"
              text="If you enable this setting, we would check component availability against each Work Order and display whether there is Sufficient Stock available to fulfill the work order or not."
              style={{ minWidth: 100 }}
            />
            <div className="column" style={{ minWidth: 42 }}>
              <Toggle
                className="box-content"
                onChange={() => updateWOSufficientInsuffocientQtySettings()}
                isOn={!!isWOSufficientInsufficientQtyEnabled}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  const updateWOSufficientInsuffocientQtySettings = () => {
    const payload = {
      additionalSettings: {
        WO_INSUFFICIENT_COMPONENTS_QTY_CHECK:
          !tenantDetails?.additionalSettings
            ?.WO_INSUFFICIENT_COMPONENTS_QTY_CHECK
      }
    };
    showLoader('Please wait...');
    TenantService.updateOrgSetting(payload)
      .then(async () => {
        await dispatch(fetchCurrentTenantInfo());
      })
      .catch((err: any) => {
        console.error(
          'Error updating sufficient insufficient qty setting: ',
          err
        );
      })
      .finally(() => removeLoader());
  };

  const getWOArchieveSetting = () => {
    return (
      <div className="column parent-width border-m border-radius-m gap-4 p-r mb-xl">
        <div className="column parent-width">
          <div className="row">
            <DKLabel text="Archive Work Order" className="fw-m mb-xs" />
          </div>
          <div className="row justify-content-between p-v-xs gap-4">
            <DKLabel
              className="text-gray"
              text="Enable Archiving of Completed Work Order."
              style={{ minWidth: 100 }}
            />
            <div className="column" style={{ minWidth: 42 }}>
              <Toggle
                className="box-content"
                onChange={() => updateWOArchiveSetting()}
                isOn={!!isWOArchiveEnabled}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  const updateWOArchiveSetting = () => {
    const payload = {
      additionalSettings: {
        ENABLE_ARCHIVE_WORK_ORDER:
          !tenantDetails?.additionalSettings?.ENABLE_ARCHIVE_WORK_ORDER
      }
    };
    let isUpdateShowArchiveFlag =
      tenantDetails?.additionalSettings?.ENABLE_ARCHIVE_WORK_ORDER;
    showLoader('Please wait...');
    TenantService.updateOrgSetting(payload)
      .then(async () => {
        // while disabling setting.
        await dispatch(fetchCurrentTenantInfo());
        if (isUpdateShowArchiveFlag) {
          dispatch(updateShowArchivedFlag(false));
        }
      })
      .catch((err: any) => {
        console.error(
          'Error updating sufficient insufficient qty setting: ',
          err
        );
      })
      .finally(() => removeLoader());
  };

  return (
    <div className="column parent-width pt-r pb-l overflow-y-auto hide-scroll-bar">
      {moduleVisiblityView()}
      {renderLanguageSelectorSetting()}
      {module.revenueRecognition && revenueRecognitionSetting()}
      {tenantDetails?.additionalSettings?.AMORTIZATION &&
        AmortizationScheduleCustomFieldSetting()}
      {module?.revenueRecognition && RevRecCustomFieldSetting()}
      {PoConversionScreenCustomFieldSetting()}
      {getPriceBookSettingView()}
      {getProductGroupingSetting()}
      {MultipleDiscountSettingView()}
      {CascadingDiscountSettingView()}
      {advancedInventorySettingView()}
      {negativeInventoryView()}
      {filterAccountsSetting()}
      {JECreditDebitMethodSetting()}
      {JEAmountViewSetting()}
      {getBillEditSetting()}
      {getInvoiceEditSetting()}
      {getPOEditSetting()}
      {getSOEditSetting()}
      {getConvertedDocEditSetting()}
      {Utility.isMRPWithURLCheck() &&
        Utility.isMRPWithURLCheck() &&
        getBlockedWOSetting()}
      {Utility.isMRPWithURLCheck() && getReserveStockWOSettingForSO()}
      {Utility.isMRPWithURLCheck() && getComponentDetailsForFGOnDocuments()}
      {Utility.isMRPWithURLCheck() && getReserveStockWOSettingForPO()}
      {Utility.isMRPWithURLCheck() && getProcessManufacturingToggleView()}
      {Utility.isMRPWithURLCheck() && getOnStartOrderAllocationToggleView()}
      {Utility.isMRPWithURLCheck() && getWorkOrderWahrehouseTaggingSetting()}
      {Utility.isMRPWithURLCheck() && getWorkOrderRRBTaggingSetting()}
      {/* {Utility.isMRPWithURLCheck() &&
        tenantDetails?.additionalSettings?.ENABLE_WO_WAREHOUSE_BIN_TAGGING &&
        BinTaggingThresholdSetting()} */}
      {Utility.isMRPWithURLCheck() && (
        <WOAdhocBOMSetting tenantDetails={tenantDetails} />
      )}
      {rowRackBinSettings()}
      {Utility.isUSorg() && additionalChargeSetting()}
      {Utility.isMRPWithURLCheck() && mrpProduct && woStockShortfallSetting()}
      {Utility.isMRPWithURLCheck() &&
        mrpProduct &&
        maintenanceShortfallSetting()}
      {valutionMethodSetting()}
      {Utility.isMRPWithURLCheck() && mrpProduct && forecastMethodSetting()}
      {getToleranceToggle()}
      {getForwardAttachmentSetting()}
      {getReorderLevelToggleView()}

      {renderEntryGateSecuritySettingToggle()}
      {getInventoryTransactionApprovalSetting()}
      {Utility.isUSorg() && getDeniedPartyTransactionSetting()}
      {getDraftDocumentNumberAllocationSetting()}
      {/* {getPartialJobWorkout()} */}
      {Utility.isMRPWithURLCheck() &&
        mrpProduct &&
        getSalesModulesShortfallAlertSetting()}
      {Utility.isUSorg() && getBankFileGenerationToggle()}
      {getBillBasedStockValuationToggle()}
      {Utility.isMRPWithURLCheck() &&
        mrpProduct &&
        getWOSufficientInsuffocientQtySettings()}
      {Utility.isMRPWithURLCheck() && mrpProduct && getWOArchieveSetting()}
      {enablePreferredVendorDetailsToggle()}
      <RFQSettings
        settings={tenantDetails.additionalSettings?.['RFQ_OPEN_FORM_CONFIG']}
        onSave={async (payload: any) => {
          const response = await updateOrganizationSettings(payload);
          return Promise.resolve(response);
        }}
      />
    </div>
  );
};

export default AdvancedSettings;
